import React from "react";
import { Button } from "@material-ui/core";
import { Footer, FooterDiv } from './Styles'
import { useTranslation } from 'react-i18next';



const FooterHome = () => {

    const { t, i18n } = useTranslation();

    const showInMapClicked = () => {
        window.open(
            'https://www.google.com/maps/place/Mestra+Boats/@-22.3323056,-48.7627983,1069m/data=!3m1!1e3!4m14!1m8!3m7!1s0x0:0x0!2zMjLCsDE5JzU2LjMiUyA0OMKwNDUnNDEuMSJX!3b1!7e2!8m2!3d-22.3323184!4d-48.7614203!3m4!1s0x94bf5578da2609ab:0xcd23939e02babd7a!8m2!3d-22.3326358!4d-48.7615203'
        );
    };

    return (
        <Footer>
            <FooterDiv>
                <Button onClick={() => (window.location.href = '/')}>
                    Mestra © 2022
                </Button>
                <Button onClick={() => (window.location.href = '/about')}>
                    {t('about')}
                </Button>
                <Button onClick={() => (window.location.href = '/contact')}>
                    {t('contact')}
                </Button>
                <Button
                    onClick={() => {
                        showInMapClicked();
                    }}
                >
                    {t('local')}
                </Button>
            </FooterDiv>
        </Footer>
    )
}

export default FooterHome;