import React from "react";
import { makeStyles } from "@material-ui/styles";
import theme from "../../theme/Theme";
import { Grid, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  boxTextContainer: {
    display: "flex",
    width: "100%",
    height: "100vh",
    backgroundColor: "black",
    color: "white",
    justifyContent: "center",
    alignItems: "center",
    padding: "300px",
    //mobile
    [theme.breakpoints.down("md")]: {
      padding: "0px",
    },
  },
  boxText: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    textAlign: "center",
    padding: "10px",
  },

  detailsBoxTextEnd: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "black",
    flexDirection: "column",
  },
  detailsBoxTextStart: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "black",
    flexDirection: "column",
  },
  textColumn: {
    width: "40em",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: "50px",
  },
  textColumnStart: {
    width: "40em",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: "50px",
  },
  gridMobile: {
    width: "100%",
    backgroundColor: "black",
    //mobile
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "row-reverse",
      gap: "10px",
    },
  },
  detailsBox: {
    display: "flex",
    width: "100%",
    backgroundColor: "black",
    justifyContent: "center",
    alignItems: "center",
  },
  detailsBoxFirstImg: {
    display: "flex",
    width: "100%",
    backgroundColor: "black",
    justifyContent: "flex-end",
    alignItems: "center",
    //mobile
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
  detailsBoxSecondImg: {
    display: "flex",
    width: "100%",
    backgroundColor: "black",
    justifyContent: "flex-start",
    alignItems: "center",
    //mobile
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
  gridItems: {
    width: "100%",
    height: "100vh",
    backgroundColor: "black",
    display: "flex",
    justifyContent: "center",
    //alignItems:"center",
    //mobile
    [theme.breakpoints.down("md")]: {
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column-reverse",
    },
  },
  gridItemImg: {
    width: "40em",
    height: "auto",
    //mobile
    [theme.breakpoints.down("md")]: {
      width: "20em",
    },
  },
  gridItemImgContainer1: {
    width: "10em",
    height: "auto",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    //mobile
    [theme.breakpoints.down("md")]: {
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  gridItemImgContainer2: {
    width: "10em",
    height: "auto",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    //mobile
    [theme.breakpoints.down("md")]: {
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  gridItem: {
    width: "50%",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    //mobile
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  gridText: {
    width: "50%",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    //mobile
    [theme.breakpoints.down("md")]: {
      width: "100%",
      fontSize: "15px",
    },
  },
});
const DetailsSectionMobile = (props) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  return (
    <div className={classes.detailsBoxContainer} >
      {/* 1 item */}
      <Grid container className={classes.gridMobile}>
        <Grid item xs={12} className={classes.detailsBoxFirstImg}>
          <img src={props.imgDetails1} className={classes.gridItemImg} />
        </Grid>
        <Grid item xs={12} className={classes.detailsBoxTextStart}>
          <div className={classes.textColumnStart}>
            <Typography
              variant="body1"
              color="white"
              style={{ fontStyle: "italic" }}
            >
              {t("painelNavegacao")}
            </Typography>
          </div>
        </Grid>
        {/* 2 item */}
        <Grid item xs={12} className={classes.detailsBoxSecondImg}>
          <img src={props.imgDetails2} className={classes.gridItemImg} />
        </Grid>
        <Grid item xs={12} className={classes.detailsBoxTextEnd}>
          <div className={classes.textColumn}>
            <Typography
              variant="body1"
              color="white"
              style={{ fontStyle: "italic" }}
            >
              {t("sistemaAncora")}
            </Typography>
          </div>
        </Grid>

        {/* 3 item */}
        <Grid item xs={12} className={classes.detailsBoxFirstImg}>
          <img src={props.imgDetails3} className={classes.gridItemImg} />
        </Grid>
        <Grid item xs={12} className={classes.detailsBoxTextStart}>
          {props.imgDetails3 ? (
            <div className={classes.textColumnStart}>
              <Typography
                variant="body1"
                color="white"
                style={{ fontStyle: "italic" }}
              >
                {t("sistemaSom")}
              </Typography>
            </div>
          ) : null}
        </Grid>
        {/* 4 item */}
        <Grid item xs={12} className={classes.detailsBoxSecondImg}>
          <img src={props.imgDetails4} className={classes.gridItemImg} />
        </Grid>
        <Grid item xs={12} className={classes.detailsBoxTextEnd}>
          {props.imgDetails4 ? (
            <div className={classes.textColumn}>
              <Typography
                variant="body1"
                color="white"
                style={{ fontStyle: "italic" }}
              >
                {t("sincronizacaoOpcional")}
              </Typography>
            </div>
          ) : null}
        </Grid>

        {/* 5 item */}
        <Grid item xs={12} className={classes.detailsBoxFirstImg}>
          <img src={props.imgDetails5} className={classes.gridItemImg} />
        </Grid>
        <Grid item xs={12} className={classes.detailsBoxTextStart}>
          {props.imgDetails5 ? (
            <div className={classes.textColumnStart}>
              <Typography
                variant="body1"
                color="white"
                style={{ fontStyle: "italic" }}
              >
                {t("acabamentoAcrilico")}
              </Typography>
            </div>
          ) : null}
        </Grid>
        {/* 6 item */}
        <Grid item xs={12} className={classes.detailsBoxSecondImg}>
          <img src={props.imgDetails6} className={classes.gridItemImg} />
        </Grid>
        <Grid item xs={12} className={classes.detailsBoxTextEnd}>
          {props.imgDetails6 ? (
            <div className={classes.textColumn}>
              <Typography
                variant="body1"
                color="white"
                style={{ fontStyle: "italic" }}
              >
                {t("portaCopos")}
              </Typography>
            </div>
          ) : null}
        </Grid>

        {/* 7 item */}
        <Grid item xs={12} className={classes.detailsBoxFirstImg}>
          <img src={props.imgDetails7} className={classes.gridItemImg} />
        </Grid>
        <Grid item xs={12} className={classes.detailsBoxTextStart}>
          {props.imgDetails6 ? (
            <div className={classes.textColumnStart}>
              <Typography
                variant="body1"
                color="white"
                style={{ fontStyle: "italic" }}
              >
                {t("estofamento")}
              </Typography>
            </div>
          ) : null}
        </Grid>
        {/* 8 item */}
        <Grid item xs={12} className={classes.detailsBoxSecondImg}>
          <img src={props.imgDetails8} className={classes.gridItemImg} />
        </Grid>
        <Grid item xs={12} className={classes.detailsBoxTextEnd}>
          {props.imgDetails7 ? (
            <div className={classes.textColumn}>
              <Typography
                variant="body1"
                color="white"
                style={{ fontStyle: "italic" }}
              >
                {t("pisosDiversasCores")}
              </Typography>
            </div>
          ) : null}
        </Grid>

        {/* 9 item */}

        <Grid item xs={12} className={classes.detailsBoxFirstImg}>
          <img src={props.imgDetails9} className={classes.gridItemImg} />
        </Grid>

        <Grid item xs={12} className={classes.detailsBoxTextStart}>
          {props.imgDetails9 ? (
            <div
              className={classes.textColumnStart}
              style={{ fontStyle: "italic" }}
            >
              <Typography variant="body1" color="white">
                {t("cockpitCapitao")}
              </Typography>
            </div>
          ) : null}
        </Grid>
        {/* 10 item */}
        <Grid item xs={12} className={classes.detailsBoxSecondImg}>
          <img src={props.imgDetails10} className={classes.gridItemImg} />
        </Grid>
        <Grid item xs={12} className={classes.detailsBoxTextEnd}>
          {props.imgDetails10 ? (
            <div className={classes.textColumn}>
              <Typography
                variant="body1"
                color="white"
                style={{ fontStyle: "italic" }}
              >
                {t("desfruteNavegar")}
              </Typography>
            </div>
          ) : null}
        </Grid>
      </Grid>
    </div>
  );
};

export default DetailsSectionMobile;
