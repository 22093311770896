/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import { Switch, Route } from "react-router";
import "./App.css";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Mestra240 from "./pages/CustomLanchas/Mestra240";
import Mestra160 from "./pages/CustomLanchas/Mestra160";
import Mestra180 from "./pages/CustomLanchas/Mestra180";
import Mestra198 from "./pages/CustomLanchas/Mestra198";
import Mestra200 from "./pages/CustomLanchas/Mestra200";
import Mestra212 from "./pages/CustomLanchas/Mestra212";
import Mestra222 from "./pages/CustomLanchas/Mestra222";
import Mestra230 from "./pages/CustomLanchas/Mestra230";

import ModelMestra322 from "./pages/Models/ModelMestra322"
import ModelMestra292 from "./pages/Models/ModelMestra292"
import ModelMestra240 from "./pages/Models/ModelMestra240";
import ModelMestra160 from "./pages/Models/ModelMestra160";
import ModelMestra180 from "./pages/Models/ModelMestra180";
import ModelMestra230 from "./pages/Models/ModelMestra230";
import ModelMestra222 from "./pages/Models/ModelMestra222";
import ModelMestra198 from "./pages/Models/ModelMestra198";
import ModelMestra200 from "./pages/Models/ModelMestra200";
import ModelMestra212 from "./pages/Models/ModelMestra212";

import Home from './pages/Home'

import About from "./pages/About";
import Contact from "./pages/Contact";
import SejaRevendedor from './pages/SejaRevendedor'
import Revendedores from './pages/Revendedores'


// import Mestra230 from "./pages/lanchas/Mestra230";
// import Mestra220 from "./pages/lanchas/Mestra220.js";
// eslint-disable-next-line import/no-anonymous-default-export

export default () => {
  return (
    <Route
      render={({ location }) => (
        <TransitionGroup>
          <CSSTransition key={location.key} timeout={450} classNames="fade">
            <Switch location={location}>
              <Route exact path="/">
                <Home />
              </Route>
              <Route exact path="/lanchas/mestra240">
                <Mestra240 />
              </Route>
              <Route exact path="/lanchas/mestra160">
                <Mestra160 />
              </Route>
              <Route exact path="/lanchas/mestra180">
                <Mestra180 />
              </Route>
              <Route exact path="/lanchas/mestra200">
                <Mestra200 />
              </Route>
              <Route exact path="/lanchas/mestra230">
                <Mestra230 />
              </Route>
              <Route exact path="/lanchas/mestra212">
                <Mestra212 />
              </Route>
              <Route exact path="/lanchas/mestra222">
                <Mestra222 />
              </Route>
              <Route exact path="/lanchas/mestra198">
                <Mestra198 />{" "}
              </Route>
              <Route exact path="/models/mestra322">
                <ModelMestra322 />
              </Route>
              <Route exact path="/models/mestra292">
                <ModelMestra292 />
              </Route>
              <Route exact path="/models/mestra240">
                <ModelMestra240 />
              </Route>
              <Route exact path="/models/mestra230">
                <ModelMestra230 />
              </Route>
              <Route exact path="/models/mestra222">
                <ModelMestra222 />
              </Route>
              <Route exact path="/models/mestra212">
                <ModelMestra212 />
              </Route>
              <Route exact path="/models/mestra200">
                <ModelMestra200 />
              </Route>
              <Route exact path="/models/mestra198">
                <ModelMestra198 />
              </Route>
              <Route exact path="/models/mestra180">
                <ModelMestra180 />
              </Route>
              <Route exact path="/models/mestra160">
                <ModelMestra160 />
              </Route>
              <Route exact path="/about">
                <About />
              </Route>
              <Route exact path="/contact">
                <Contact />
              </Route>
              <Route exact path="/revendedores">
                <Revendedores />
              </Route>
              <Route exact path="/sejarevendedor">
                <SejaRevendedor />
              </Route>
            </Switch>
          </CSSTransition>
        </ TransitionGroup>
      )}
    />
  );
};
