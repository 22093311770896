import React, { useState } from 'react';

//componentes
import { makeStyles } from '@material-ui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Grid, Button, Drawer, Typography } from '@mui/material';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';
import { PtButton, EnButton } from './LanguageButton';
import SidebarMenu from './SidebarMenu';
import SidebarMobile from './SidebarMobile';
import theme from '../../theme/Theme';
import MenuIcon from '@mui/icons-material/Menu';

//imagens
import logo from '../../data/images/logo.jpg';
import { red } from '@mui/material/colors';

// CLASSES
const useStyles = makeStyles({
  root: {
    width: '100%',
    position: (props) => props.position,
    zIndex: '1000',

    backgroundColor: (props) => props.backgroundColor,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Montserrat, sans-serif',
  },
  grid: {
    [theme.breakpoints.down('sm')]: {
      spacing: '0',
    },
  },
  navLogo: {
    width: '200px',
    cursor: 'pointer',
    WebkitTapHighlightColor: 'transparent',
    userSelect: 'none',
    [theme.breakpoints.down('sm')]: {
      width: '150px',
    },
  },
  menuLogo: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  menuCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: "center",
    //mobile
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  menuRight: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    //mobile
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  mobile: {
    // display: "none",
    //mobile
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
  },
  lancamento: {
    color: 'red',
    fontSize: '8px',
    position: 'absolute',
    bottom: 0,
  },
});

const Navbar = (props) => {
  //classe
  const classes = useStyles(props);

  //Botao linguagem
  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const [ptButton, setPtButton] = useState(true);
  const ButtonLanguage = ptButton ? (
    <PtButton language={() => changeLanguage('en')}></PtButton>
  ) : (
    <EnButton language={() => changeLanguage('pt')}></EnButton>
  );

  //sidebar
  const mobileSidebar = useMediaQuery(theme.breakpoints.down('sm'));

  const [state, setState] = useState(false);

  const toggleDrawer = (open) => (event) => {
    setState(open);
  };
  //sidebar mobile

  const [stateMobile, setStateMobile] = useState(false);

  const toggleDrawerMobile = (open) => (event) => {
    setStateMobile(open);
  };
  return (
    <Box p={3} className={classes.root}>
      <Grid container className={classes.grid} spacing={3}>
        <Grid
          className={classes.menuLogo}
          item
          md={4}
          xs={6}
          onClick={() => {
            window.location.href = '/';
          }}
        >
          <img className={classes.navLogo} src={logo} alt="logo" />
        </Grid>
        <Grid className={classes.menuCenter} item xs={4}>
          <Button
            className={classes.button}
            style={{
              fontWeight: 'bold',
              fontSize: '14px',
              letterSpacing: '0.1em',
            }}
            onClick={() => {
              window.location.href = '/models/mestra322';
            }}
          >
            M322
          </Button>
          <Button
            className={classes.button}
            style={{
              fontWeight: 'bold',
              fontSize: '14px',
              letterSpacing: '0.1em',
            }}
            onClick={() => {
              window.location.href = '/models/mestra292';
            }}
          >
            M292
            <div className={classes.lancamento}><p style={{ letterSpacing: "0" }}>Lançamento</p></div>
          </Button>

          <Button
            className={classes.button}
            style={{
              fontWeight: 'bold',
              fontSize: '14px',
              letterSpacing: '0.1em',
            }}
            onClick={() => {
              window.location.href = '/models/mestra240';
            }}
          >
            M240
          </Button>
          <Button
            className={classes.button}
            style={{
              fontWeight: 'bold',
              fontSize: '14px',
              letterSpacing: '0.1em',
            }}
            onClick={() => {
              window.location.href = '/models/mestra230';
            }}
          >
            M230
          </Button>
          <Button
            className={classes.button}
            style={{
              fontWeight: 'bold',
              fontSize: '14px',
              letterSpacing: '0.1em',
            }}
            onClick={() => {
              window.location.href = '/models/mestra222';
            }}
          >
            M222
          </Button>
          <Button
            className={classes.button}
            style={{
              fontWeight: 'bold',
              fontSize: '14px',
              letterSpacing: '0.1em',
            }}
            onClick={() => {
              window.location.href = '/models/mestra212';
            }}
          >
            M212
          </Button>
          <Button
            className={classes.button}
            style={{
              fontWeight: 'bold',
              fontSize: '14px',
              letterSpacing: '0.1em',
            }}
            onClick={() => {
              window.location.href = '/models/mestra200';
            }}
          >
            M200
          </Button>
          <Button
            style={{
              fontWeight: 'bold',
              fontSize: '14px',
              letterSpacing: '0.1em',
            }}
            onClick={() => {
              window.location.href = '/models/mestra198';
            }}
          >
            M198
          </Button>
          <Button
            style={{
              fontWeight: 'bold',
              fontSize: '14px',
              letterSpacing: '0.1em',
            }}
            onClick={() => {
              window.location.href = '/models/mestra180';
            }}
          >
            M180
          </Button>
          <Button
            style={{
              fontWeight: 'bold',
              fontSize: '14px',
              letterSpacing: '0.1em',
            }}
            onClick={() => {
              window.location.href = '/models/mestra160';
            }}
          >
            M160
          </Button>
        </Grid>

        <Grid xs={4} item className={classes.menuRight}>
          <Button
            style={{
              fontWeight: 'bold',
              fontSize: '14px',
              marginLeft: '10px',
              letterSpacing: '0.1em',
            }}
            onClick={toggleDrawer(true)}
          >
            MENU
          </Button>
          <div
            onClick={() => {
              setPtButton(!ptButton);
            }}
          >
            {ButtonLanguage}
          </div>
        </Grid>
        {mobileSidebar ? (
          <Grid xs={6} item className={classes.mobile}>
            <Button onClick={toggleDrawerMobile(true)}>
              <MenuIcon />
            </Button>
          </Grid>
        ) : null}
      </Grid>
      <SidebarMenu open={state} close={toggleDrawer(false)} />
      <SidebarMobile open={stateMobile} close={toggleDrawerMobile(false)} />
    </Box>
  );
};

export default Navbar;
