import React from 'react';
import CheckAcessoriosFabrica from './CheckAcessoriosFabrica';
import CheckAcc from './CheckAcc';
import '../Styles/customPage.css';
import { ButtonColor, ButtonPiso, MotorButton } from '../Styles/buttonsStyle';
import { useTranslation } from 'react-i18next';

const RightColumn = (props) => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <h1>{props.title}</h1>
      <p>{props.subtitle}</p>
      <button className="Buttons">Solicitar Orçamento</button>
      <div className="info">
        <div className="text">
          {props.comprimento}
          <p>{t('comprimento')}</p>
        </div>
        <div className="text">
          {props.boca}
          <p>{t('boca')}</p>
        </div>
        <div className="text">
          {props.calado}
          <p>{t('calado')}</p>
        </div>
      </div>
      <div>
        {/* BOTÕES  */}
        <div style={{ paddingTop: '50px' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <h2>{t('cores')}</h2>
            <p>{t('selecionarCor')}</p>
            <div className="buttonColors">
              <ButtonColor
                backgroundColor="#162D46"
                border={props.coluna === 1 ? '1px solid blue' : 'none'}
                onClick={() => {
                  props.ShowColorImage('blue');
                  props.setCorCasco('Azul');
                  // setColuna(1);
                  // setLinha(0);
                }}
              />

              <ButtonColor
                backgroundColor="black"
                border={props.coluna === 2 ? '1px solid blue' : 'none'}
                onClick={() => {
                  props.ShowColorImage('black');
                  props.setCorCasco('Preto');
                }}
              />
              <ButtonColor
                backgroundColor="Gray"
                border={props.coluna === 3 ? '1px solid blue' : 'none'}
                onClick={() => {
                  props.ShowColorImage('darkGray');
                  props.setCorCasco('Cinza escuro');
                }}
              />
              <ButtonColor
                border={props.coluna === 4 ? '1px solid blue' : 'none'}
                backgroundColor="white"
                onClick={() => {
                  props.ShowColorImage('white');
                  props.setCorCasco('Branco');
                }}
              />
              <ButtonColor
                backgroundColor="red"
                border={props.coluna === 5 ? '1px solid blue' : 'none'}
                onClick={() => {
                  props.ShowColorImage('red');
                  props.setCorCasco('Vermelho');
                }}
              />
              <ButtonColor
                border={props.coluna === 6 ? '1px solid blue' : 'none'}
                backgroundColor="#008080"
                onClick={() => {
                  props.ShowColorImage('green');
                  props.setCorCasco('Verde');
                }}
              />
              <ButtonColor
                border={props.coluna === 7 ? '1px solid blue' : 'none'}
                backgroundColor="#c6c6c6"
                onClick={() => {
                  props.ShowColorImage('gray');
                  props.setCorCasco('Cinza');
                }}
              />
            </div>
          </div>
        </div>
        <div style={{ paddingTop: '20px' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <h2>{t('corBanco')}</h2>
            <p>{t('selecionarCorBanco')}</p>
            <div className="buttonColors">
              <ButtonColor
                backgroundColor="#9F5036"
                border={props.colunaEstofado === 1 ? '1px solid blue' : 'none'}
                onClick={() => {
                  props.ShowColorEstofadoImage('terracota');
                  props.setCorBancos('Terracotta')
                }}
              ></ButtonColor>
              <ButtonColor
                backgroundColor="white"
                border={props.colunaEstofado === 2 ? '1px solid blue' : 'none'}
                onClick={() => {
                  props.ShowColorEstofadoImage('white');
                  props.setCorBancos('Branco')
                }}
              ></ButtonColor>

              <ButtonColor
                backgroundColor="#1E204B"
                border={props.colunaEstofado === 3 ? '1px solid blue' : 'none'}
                onClick={() => {
                  props.ShowColorEstofadoImage('blue');
                  props.setCorBancos('Azul')
                }}
              ></ButtonColor>

              <ButtonColor
                backgroundColor="#A5A6A7"
                border={props.colunaEstofado === 4 ? '1px solid blue' : 'none'}
                onClick={() => { props.ShowColorEstofadoImage('cinza'); props.setCorBancos('Cinza') }}
              ></ButtonColor>
              <ButtonColor
                backgroundColor="#BCA78A"
                border={props.colunaEstofado === 5 ? '1px solid blue' : 'none'}
                onClick={() => { props.ShowColorEstofadoImage('caramelo'); props.setCorBancos('Caramelo') }}
              ></ButtonColor>
              <ButtonColor
                backgroundColor="#D1BA8C"
                border={props.colunaEstofado === 6 ? '1px solid blue' : 'none'}
                onClick={() => { props.ShowColorEstofadoImage('begeClaro'); props.setCorBancos('Bege') }}
              ></ButtonColor>
            </div>
          </div>
        </div>

        <div style={{ paddingTop: '50px' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <h2>{t('tapecaria')}</h2>
            <p>{t('selecionarCor')}</p>
            <p>Soft Wood:</p>
            <div className="buttonColors">
              <ButtonPiso
                img={props.evaTechTerracotta}
                border={props.colunaPiso === 1 ? '1px solid blue' : 'none'}
                onClick={() => {
                  props.ShowColorPisoImage('terracotta');
                  props.setCorTapecaria('Soft Wood Terracotta')
                }}
              ></ButtonPiso>

              <ButtonPiso
                img={props.evaTechCinza}
                border={props.colunaPiso === 2 ? '1px solid blue' : 'none'}
                onClick={() => {
                  props.ShowColorPisoImage('cinza');
                  props.setCorTapecaria('Soft Wood Cinza')
                }}
              ></ButtonPiso>
              <ButtonPiso
                img={props.evaTechCaramelo}
                border={props.colunaPiso === 3 ? '1px solid blue' : 'none'}
                onClick={() => {
                  props.ShowColorPisoImage('caramelo');
                  props.setCorTapecaria('Soft Wood Caramelo')
                }}
              ></ButtonPiso>
              <ButtonPiso
                img={props.evaTechBegeClaro}
                border={props.colunaPiso === 4 ? '1px solid blue' : 'none'}
                onClick={() => {
                  props.ShowColorPisoImage('bege');
                  props.setCorTapecaria('Soft Wood Bege')
                }}
              ></ButtonPiso>
            </div>
            <p>Hard Wood:</p>
            <div className="buttonColors">
              <ButtonPiso
                img={props.hardWoodterracota}
                border={props.colunaPiso === 5 ? '1px solid blue' : 'none'}
                onClick={() => {
                  props.ShowColorPisoImage('terracottaHW');
                  props.setCorTapecaria('Hard Wood Terracotta')
                }}
              ></ButtonPiso>
              <ButtonPiso
                img={props.hardWoodBegeClaro}
                border={props.colunaPiso === 6 ? '1px solid blue' : 'none'}
                onClick={() => {
                  props.ShowColorPisoImage('areiaHW');
                  props.setCorTapecaria('Hard Wood Areia')
                }}
              ></ButtonPiso>
              <ButtonPiso
                img={props.hardWoodCinza}
                border={props.colunaPiso === 7 ? '1px solid blue' : 'none'}
                onClick={() => {
                  props.ShowColorPisoImage('cinzaHW');
                  props.setCorTapecaria('Hard Wood Cinza')
                }}
              ></ButtonPiso>

              <ButtonPiso
                img={props.hardWoodBegeEscuro}
                border={props.colunaPiso === 8 ? '1px solid blue' : 'none'}
                onClick={() => {
                  props.ShowColorPisoImage('carameloHW');
                  props.setCorTapecaria('Hard Wood Caramelo')
                }}
              ></ButtonPiso>
            </div>
          </div>
        </div>
        {/* BOTÃO MOTORES */}
        <div
          className="motorizacaoLab"
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            color: 'gray',
            fontWeight: 'bold',
            padding: '10px',
          }}
        >
          {t('motorizacao')}{' '}
        </div>
        <div style={{
          display: "flex", justifyContent: "center", alignItems: "center", width: "100%", color: 'gray',
          fontWeight: 'bold', paddingTop: "2em", paddingBottom: "2em",
        }}>
          {props.motorizacao100a150popa}
          {props.motorizacao60a115popa}
          {props.motorizacao40a60popa}
        </div>
        <div className="ButtonsMotor">
          {props.mercury40hp && (
            <MotorButton
              border={
                props.clickMercruiser40 === true
                  ? '1px solid blue'
                  : '1px solid gray'
              }
              onClick={() => {
                props.handleCarouselChange('Mercury40hp');
                props.setClickMercruiser40(true);
                props.setClickMercruiser60(false);
                props.setClickMercruiser50(false);
                props.setClickMercruiser100(false);
                props.setClickMercruiser150(false);
                props.setClickMercruiser115(false);
                props.setClickNone(false);
              }}
            >
              {props.mercury40hp}
            </MotorButton>
          )}
          {props.mercruiser50 && (
            <MotorButton
              border={
                props.clickMercruiser50 === true
                  ? '1px solid blue'
                  : '1px solid gray'
              }
              onClick={() => {
                props.handleCarouselChange('Mercury50hp');
                props.setClickMercruiser50(true);
                props.setClickMercruiser40(false);
                props.setClickMercruiser60(false);
                props.setClickMercruiser100(false);
                props.setClickMercruiser150(false);
                props.setClickMercruiser115(false);
                props.setClickNone(false);
              }}
            >
              {props.mercruiser50}
            </MotorButton>
          )}

          {props.mercruiser60 && (
            <MotorButton
              border={
                props.clickMercruiser60 === true
                  ? '1px solid blue'
                  : '1px solid gray'
              }
              onClick={() => {
                props.handleCarouselChange('Mercury60hp');
                props.setClickMercruiser60(true);
                props.setClickMercruiser50(false);
                props.setClickMercruiser40(false);
                props.setClickMercruiser100(false);
                props.setClickMercruiser150(false);
                props.setClickMercruiser115(false);
                props.setClickNone(false);
              }}
            >
              {props.mercruiser60}
            </MotorButton>
          )}
          {props.mercury100 && (
            <MotorButton
              border={
                props.clickMercruiser100 === true
                  ? '1px solid blue'
                  : '1px solid gray'
              }
              onClick={() => {
                props.handleCarouselChange('Mercury100hp');
                props.setClickMercruiser100(true);
                props.setClickMercruiser60(false);
                props.setClickMercruiser150(false);
                props.setClickMercruiser115(false);
                props.setClickNone(false);
              }}
            >
              {props.mercury100}
            </MotorButton>
          )}
          {props.mercury115 && (
            <MotorButton
              border={
                props.clickMercruiser115 === true
                  ? '1px solid blue'
                  : '1px solid gray'
              }
              onClick={() => {
                props.handleCarouselChange('Mercury115hp');
                props.setClickMercruiser115(true);
                props.setClickMercruiser60(false);
                props.setClickMercruiser150(false);
                props.setClickMercruiser100(false);
                props.setClickNone(false);
              }}
            >
              {props.mercury115}
            </MotorButton>
          )}
          {props.mercury150 && (
            <MotorButton
              border={
                props.clickMercruiser150 === true
                  ? '1px solid blue'
                  : '1px solid gray'
              }
              onClick={() => {
                props.handleCarouselChange('Mercury150hp');
                props.setClickMercruiser150(true);
                props.setClickMercruiser115(false);
                props.setClickMercruiser100(false);
                props.setClickNone(false);
              }}
            >
              {props.mercury150}
            </MotorButton>
          )}

          {props.mercruiser200 && (
            <MotorButton
              border={
                props.clickMercruiser200 === true
                  ? '1px solid blue'
                  : '1px solid gray'
              }
              onClick={() => {
                props.handleCarouselChange('MerCruiser200');
                props.setClickMercruiser200(true);
                props.setClickMercruiser250(false);
                props.setClickVolvoPenta280(false);
                props.setClickNone(false);
              }}
            >
              {props.mercruiser200}
            </MotorButton>
          )}

          {props.mercruiser250 && (
            <MotorButton
              border={
                props.clickMercruiser250 === true
                  ? '1px solid blue'
                  : '1px solid gray'
              }
              onClick={() => {
                props.handleCarouselChange('MerCruiser250');
                props.setClickMercruiser250(true);
                props.setClickVolvoPenta280(false);
                props.setClickMercruiser200(false);
                props.setClickNone(false);
              }}
            >
              {props.mercruiser250}
            </MotorButton>
          )}
          {props.volvoPenta280 && (
            <MotorButton
              border={
                props.clickVolvoPenta280 === true
                  ? '1px solid blue'
                  : '1px solid gray'
              }
              onClick={() => {
                props.handleCarouselChange('VolvoPenta');
                props.setClickVolvoPenta280(true);
                props.setClickMercruiser200(false);
                props.setClickMercruiser250(false);
                props.setClickNone(false);
              }}
            >
              {props.volvoPenta280}
            </MotorButton>
          )}

          <MotorButton
            border={
              props.clickNone === true ? '1px solid blue' : '1px solid gray'
            }
            onClick={() => {
              props.setClickNone(true);
              props.setClickMercruiser60(false);
              props.setClickMercruiser40(false);
              props.setClickMercruiser50(false);
              props.setClickVolvoPenta280(false);
              props.setClickMercruiser200(false);
              props.setClickMercruiser250(false);
              props.setClickMercruiser100(false);
              props.setClickMercruiser150(false);
              props.setClickMercruiser115(false);
            }}
          >
            Sem motor
          </MotorButton>
        </div>
      </div>
      <div className="text" style={{ fontSize: '16px', padding: '20px', marginTop: "2em", textTransform: "uppercase" }}>
        {t('ficha')}
      </div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-around',
        }}
      >
        <div className="list">
          <ul style={{
            margin: '10px', color: 'gray', padding: '10px',
          }}>
            {props.li1 && <li>{props.li1}</li>}
            {props.li2 && <li>{props.li2}</li>}
            {props.li3 && <li>{props.li3}</li>}
            {props.li4 && <li>{props.li4}</li>}
            {props.li5 && <li>{props.li5}</li>}
            {props.li6 && <li>{props.li6}</li>}
            {props.li7 && <li>{props.li7}</li>}
            {props.li8 && <li>{props.li8}</li>}
            {props.li9 && <li>{props.li9}</li>}
            {props.li10 && <li>{props.li10}</li>}
            {props.li11 && <li>{props.li11}</li>}
            {props.li12 && <li>{props.li12}</li>}
            {props.li13 && <li>{props.li13}</li>}
          </ul>
        </div>
        <div className="list">
          <ul
            style={{
              listStyle: 'none',
              margin: '10px',
              color: 'gray',
              padding: '10px',
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "center",
              flexDirection: "column"
            }}
          >
            {props.angulo && <li>{props.angulo}</li>}
            {props.combustivel && <li>{props.combustivel}</li>}
            {props.tanqueAguaDoce && <li>{props.tanqueAguaDoce}</li>}
            {props.peso && <li>{props.peso}</li>}
            {props.pessoas && <li>{props.pessoas}</li>}
            {props.camarotes && <li>{props.camarotes}</li>}
            {props.cabine && <li>{props.cabine}</li>}
            {props.motorizacao && <li>{props.motorizacao}</li>}
          </ul>
        </div>
      </div>
      <div></div>

      <div>
      </div>
      <div style={{ padding: '60px' }}>
        <div
          style={{
            backgroundColor: '#E1E1E1',
            borderRadius: '50px',
            padding: '10px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {t('informacoes')}
        </div>
        <div
          style={{
            marginTop: '50px',
            fontSize: '16px',
            fontWeight: 'bold',
            display: 'flex',
            justifyContent: 'center',
            textTransform: "uppercase",
            paddingBottom: "50px"
          }}
        >
          {t('itensSerie')}
        </div>
        <ul className="list-style" >
          {props.itensSerie.map((item, index) => {
            return (
              <li key={index}>{item}</li>
            )
          })}
        </ul>
      </div>
      <div
        style={{
          fontSize: '16px',
          fontWeight: 'bold',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {t('motorizacaoFabrica')}
      </div>
      <ul
        className="list-style"
        style={{
          padding: '50px',
        }}
      >
        <li>{props.itemFabrica1}</li>
        <li>{props.itemFabrica2}</li>
        <li>{props.itemFabrica3}</li>
        <li>{props.itemFabrica4}</li>
        <li>{props.itemFabrica5}</li>
        <li>{props.itemFabrica6}</li>
        <li>{props.itemFabrica7}</li>
        <li>{props.itemFabrica8}</li>
        <li>{props.itemFabrica9}</li>
        <li>{props.itemFabrica10}</li>
      </ul>
      {/* ACESSÓRIOS FABRICA CHECKBOX */}
      <CheckAcessoriosFabrica
        mestra240={props.mestra240}
        mestra230={props.mestra230}
        mestra222={props.mestra222}
        mestra212={props.mestra212}
        mestra200={props.mestra200}
        mestra198={props.mestra198}
        mestra180={props.mestra180}
        mestra160={props.mestra160}
        opicionais={props.opicionais}
        setOpicionais={props.setOpicionais}
      />
      <CheckAcc
        mestra240={props.mestra240}
        mestra230={props.mestra230}
        mestra222={props.mestra222}
        mestra212={props.mestra212}
        mestra200={props.mestra200}
        mestra198={props.mestra198}
        mestra180={props.mestra180}
        mestra160={props.mestra160}
        acessorios={props.acessorios}
        setAcessorios={props.setAcessorios}

      />
      <h2>{t('orcamentoItem')}</h2>
      <p>{t('orcamentoItemDescricao')}</p>{' '}
      <button className="buyButton" onClick={() => props.setOpen(true)}>
        {t('solicitarOrcamento')}
      </button>
    </>
  );
};
export default RightColumn;
