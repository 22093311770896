/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import CustomPage from "../../components/CustomPage/CustomPage";
import { useTranslation } from "react-i18next";

// CASCO E CORES

import fotoCasco from "./../../data/images/customLancha180/fotoBasePNG.png";
import visao1 from "./../../data/images/customLancha180/visao1.png";
import visao2 from "./../../data/images/customLancha180/visao2.png";

// cores default
import colorRed from "../../data/images/customLancha180/cascoVermelho.png";
import colorDarkGray from "../../data/images/customLancha180/cascoCinzaEscuro.png";
import colorGray from "../../data/images/customLancha180/cascoCinza.png";
import colorGreen from "../../data/images/customLancha180/cascoVerde.png";
import colorBlack from "../../data/images/customLancha180/cascoPreto.png";
import colorBlue from "../../data/images/customLancha180/cascoAzul.png";
import colorWhite from "../../data/images/customLancha180/cascoBranco.png";

//default cor do estofado e piso detalhes
import visaoDefaultEstofadoTerracotta from "../../data/images/customLancha222/visaoDefaultEstofadoTerracotta.png";
import visaoDefaultEstofadoAzul from "../../data/images/customLancha222/visaoDefaultEstofadoAzul.png";
import visaoDefaultEstofadoBranco from "../../data/images/customLancha222/visaoDefaultEstofadoBranco.png";
import visaoDefaultEstofadoCinza from "../../data/images/customLancha222/visaoDefaultEstofadoCinza.png";
import visaoDefaultEstofadoCaramelo from "../../data/images/customLancha222/visaoDefaultEstofadoCaramelo.png";
import visaoDefaultEstofadoBege from "../../data/images/customLancha222/visaoDefaultEstofadoBege.png";

import visaoDefaultPisoTerracotta from "../../data/images/customLancha222/visaoDefaultPisoTerracotta.png";
import visaoDefaultPisoCinza from "../../data/images/customLancha222/visaoDefaultPisoCinza.png";
import visaoDefaultPisoCaramelo from "../../data/images/customLancha222/visaoDefaultPisoCaramelo.png";
import visaoDefaultPisoBege from "../../data/images/customLancha222/visaoDefaultPisoBege.png";

import visaoDefaultPisoTerracottaHW from "../../data/images/customLancha222/visaoDefaultPisoTerracottaHW.png";
import visaoDefaultPisoCinzaHW from "../../data/images/customLancha222/visaoDefaultPisoCinzaHW.png";
import visaoDefaultPisoCarameloHW from "../../data/images/customLancha222/visaoDefaultPisoCarameloHW.png";
import visaoDefaultPisoAreiaHW from "../../data/images/customLancha222/visaoDefaultPisoAreiaHW.png";

import visao1EstofadoTerracotta from "../../data/images/customLancha222/visao1ExteriorEstofadoTerracotta.png";
import visao1EstofadoBranco from "../../data/images/customLancha222/visao1ExteriorEstofadoBranco.png";
import visao1EstofadoCinza from "../../data/images/customLancha222/visao1ExteriorEstofadoCinza.png";
import visao1EstofadoCaramelo from "../../data/images/customLancha222/visao1ExteriorEstofadoCaramelo.png";
import visao1EstofadoBege from "../../data/images/customLancha222/visao1ExteriorEstofadoBege.png";
import visao1EstofadoAzul from "../../data/images/customLancha222/visao1ExteriorEstofadoAzul.png";

// cores casco visao 1
import visao1Vermelho from "./../../data/images/customLancha180/visao1CascoVermelho.png";
import visao1Azul from "./../../data/images/customLancha180/visao1CascoAzul.png";
import visao1Verde from "./../../data/images/customLancha180/visao1CascoVerde.png";
import visao1CinzaEscuro from "./../../data/images/customLancha180/visao1CascoCinzaEscuro.png";
import visao1Preto from "./../../data/images/customLancha180/visao1CascoPreto.png";
import visao1Cinza from "./../../data/images/customLancha180/visao1CascoCinza.png";
import visao1Branco from "./../../data/images/customLancha180/visao1CascoBranco.png";

//cores casco visao 2
import visao2Vermelho from "./../../data/images/customLancha180/visao2CascoVermelho.png";
import visao2Azul from "./../../data/images/customLancha180/visao2CascoAzul.png";
import visao2Verde from "./../../data/images/customLancha180/visao2CascoVerde.png";
import visao2CinzaEscuro from "./../../data/images/customLancha180/visao2CascoCinzaEscuro.png";
import visao2Preto from "./../../data/images/customLancha180/visao2CascoPreto.png";
import visao2Cinza from "./../../data/images/customLancha180/visao2CascoCinza.png";
import visao2Branco from "./../../data/images/customLancha180/visao2CascoBranco.png";

// ESTOFADO/PISO
// visao default
import interior from "../../data/images/customLancha180/interior.png";
import seatBlue from "../../data/images/customLancha180/seatBlue.png";
import seatWhite from "../../data/images/customLancha180/seatBranco.png";
import seatTerracota from "../../data/images/customLancha180/seatTerracotta.png";
import seatGray from "../../data/images/customLancha180/seatCinza.png";
import seatCaramelo from "../../data/images/customLancha180/seatCaramelo.png";
import seatBegeClaro from "../../data/images/customLancha180/seatBege.png";

import pisoBege from "../../data/images/customLancha180/pisoBege.png";
import pisoCinza from "../../data/images/customLancha180/pisoCinza.png";
import pisoCaramelo from "../../data/images/customLancha180/pisoCaramelo.png";
import pisoTerracotta from "../../data/images/customLancha180/pisoTerracotta.png";
import pisoTerracottaHW from "../../data/images/customLancha180/pisoTerracottaHW.png";
import pisoAreiaHW from "../../data/images/customLancha180/pisoAreiaHW.png";
import pisoCinzaHW from "../../data/images/customLancha180/pisoCinzaHW.png";
import pisoCarameloHW from "../../data/images/customLancha180/pisoCarameloHW.png";

// visao 1 interior
import visao1Estofado from "../../data/images/customLancha180/visao1Estofado.png";
import estofadoTerracotta2 from "../../data/images/customLancha180/visao1EstofadoTerracotta.png";
import estofadoBranco2 from "../../data/images/customLancha180/visao1EstofadoBranco.png";
import estofadoAzul2 from "../../data/images/customLancha180/visao1EstofadoAzul.png";
import estofadoCinza2 from "../../data/images/customLancha180/visao1EstofadoCinza.png";
import estofadoCaramelo2 from "../../data/images/customLancha180/visao1EstofadoCaramelo.png";
import estofadoBege2 from "../../data/images/customLancha180/visao1EstofadoBege.png";

import pisoBege2 from "../../data/images/customLancha180/pisoBege2.png";
import pisoCinza2 from "../../data/images/customLancha180/pisoCinza2.png";
import pisoCaramelo2 from "../../data/images/customLancha180/pisoCaramelo2.png";
import pisoTerracotta2 from "../../data/images/customLancha180/pisoTerracotta2.png";

import pisoTerracottaHW2 from "../../data/images/customLancha180/pisoTerracottaHW2.png";
import pisoAreiaHW2 from "../../data/images/customLancha180/pisoAreiaHW2.png";
import pisoCinzaHW2 from "../../data/images/customLancha180/pisoCinzaHW2.png";
import pisoCarameloHW2 from "../../data/images/customLancha180/pisoCarameloHW2.png";

//visao 2 interior
import visao2Estofado from "../../data/images/customLancha180/visao2Estofado.png";
import pisoBege3 from "../../data/images/customLancha180/pisoBege3.png";
import pisoCinza3 from "../../data/images/customLancha180/pisoCinza3.png";
import pisoCaramelo3 from "../../data/images/customLancha180/pisoCaramelo3.png";
import pisoTerracotta3 from "../../data/images/customLancha180/pisoTerracotta3.png";
import pisoTerracottaHW3 from "../../data/images/customLancha180/pisoTerracottaHW3.png";
import pisoAreiaHW3 from "../../data/images/customLancha180/pisoAreiaHW3.png";
import pisoCinzaHW3 from "../../data/images/customLancha180/pisoCinzaHW3.png";
import pisoCarameloHW3 from "../../data/images/customLancha180/pisoCaramelo3.png";

import estofadoTerracotta3 from "../../data/images/customLancha180/estofadoTerracotta3.png";
import estofadoBranco3 from "../../data/images/customLancha180/estofadoBranco3.png";
import estofadoAzul3 from "../../data/images/customLancha180/estofadoAzul3.png";
import estofadoCinza3 from "../../data/images/customLancha180/estofadoCinza3.png";
import estofadoCaramelo3 from "../../data/images/customLancha180/estofadoCaramelo3.png";
import estofadoBege3 from "../../data/images/customLancha180/estofadoBege3.png";

import interiorCascoAzul2 from "../../data/images/customLancha180/interiorCascoAzul2.png";
import interiorCascoPreto2 from "../../data/images/customLancha180/interiorCascoPreto2.png";
import interiorCascoBranco2 from "../../data/images/customLancha180/interiorCascoBranco2.png";
import interiorCascoCinza2 from "../../data/images/customLancha180/interiorCascoCinza2.png";
import interiorCascoVermelho2 from "../../data/images/customLancha180/interiorCascoVermelho2.png";
import interiorCascoVerde2 from "../../data/images/customLancha180/interiorCascoVerde2.png";
import interiorCascoCinzaEscuro2 from "../../data/images/customLancha180/interiorCascoCinzaEscuro2.png";

//visao 3 interior
import visao3Estofado from "../../data/images/customLancha180/visao3Estofado.png";
import estofadoTerracotta4 from "../../data/images/customLancha180/estofadoTerracotta4.png";
import estofadoBranco4 from "../../data/images/customLancha180/estofadoBranco4.png";
import estofadoAzul4 from "../../data/images/customLancha180/estofadoAzul4.png";
import estofadoCinza4 from "../../data/images/customLancha180/estofadoCinza4.png";
import estofadoCaramelo4 from "../../data/images/customLancha180/estofadoCaramelo4.png";
import estofadoBege4 from "../../data/images/customLancha180/estofadoBege4.png";

import pisoBege4 from "../../data/images/customLancha180/pisoBege4.png";
import pisoCinza4 from "../../data/images/customLancha180/pisoCinzaHW4.png";
import pisoCaramelo4 from "../../data/images/customLancha180/pisoCaramelo4.png";
import pisoTerracotta4 from "../../data/images/customLancha180/pisoTerracotta4.png";
import pisoTerracottaHW4 from "../../data/images/customLancha180/pisoTerracottaHW4.png";
import pisoAreiaHW4 from "../../data/images/customLancha180/pisoAreiaHW4.png";
import pisoCinzaHW4 from "../../data/images/customLancha180/pisoCinzaHW4.png";
import pisoCarameloHW4 from "../../data/images/customLancha180/pisoCarameloHW4.png";

export default () => {
  const { t, i18n } = useTranslation();

  const itensSerie = [
    t("pegaMaoInox"),
    t("bocalCombustivel"),
    t("bocalAguaDoce"),
    t("portaCoposInox"),
    t("escadaDePopaInox"),
    t("kitTorneiraPia"),
    t("luzesNavegacao"),
    t("sistemaEletrico"),
    t("duchaPopa"),
    t("estofamentoCourvim"),
    t("caixaMovelTraseiro"),
    t("acentoRemovivel"),
    t("paiosProa"),
  ]

  const fichaTecnica = [
    t("combustivelTanque") + "⠀100 L",
    t("tanqueAguaDoce") + "⠀28 L",
    t("pesoSemMotor") + "⠀485 KG",
    t("passageirosDiurnos") + "⠀1+7",
    t("motorizacaoPopa") + `⠀${t("motorizacao60a135")}`,
  ]
  return (
    <>
      <CustomPage
        mestra180
        pisoOptions
        mercury100hp
        coresCascoDefault={fotoCasco}
        visao1={visao1}
        visao2={visao2}
        colorRed={colorRed}
        colorBlue={colorBlue}
        colorGreen={colorGreen}
        colorDarkGray={colorDarkGray}
        colorGray={colorGray}
        colorBlack={colorBlack}
        colorWhite={colorWhite}
        // VISOES DA COR DO CASCO VERMELHA
        visaoCoresRed2={visao1Vermelho}
        visaoCoresRed3={visao2Vermelho}
        //VISOES DA COR DO CASCO AZUL
        visaoCoresBlue2={visao1Azul}
        visaoCoresBlue3={visao2Azul}
        //VISOES DA COR VERDE
        visaoCoresGreen2={visao1Verde}
        visaoCoresGreen3={visao2Verde}
        //VISOES DA COR DO CASCO CINZA
        visaoCoresGray2={visao1Cinza}
        visaoCoresGray3={visao2Cinza}
        //VISOES DA COR CINZA COR DO CASCO ESCURO
        visaoCoresDarkGray2={visao1CinzaEscuro}
        visaoCoresDarkGray3={visao2CinzaEscuro}
        //VISOES DA COR  COR DO CASCO PRETO
        visaoCoresBlack2={visao1Preto}
        visaoCoresBlack3={visao2Preto}
        // VISOES DA COR COR DO CASCO BRANCA
        visaoCoresWhite2={visao1Branco}
        visaoCoresWhite3={visao2Branco}
        //INTERIOR
        coresEstofadoDefault={interior}
        estofadoTerracota={seatTerracota}
        estofadoAzul={seatBlue}
        estofadoBranco={seatWhite}
        estofadoBegeClaro={seatBegeClaro}
        estofadoCaramelo={seatCaramelo}
        estofadoCinza={seatGray}
        estofadoBlue={seatBlue}
        pisoTerracotta={pisoTerracotta}
        pisoCinza={pisoCinza}
        pisoCaramelo={pisoCaramelo}
        pisoBege={pisoBege}
        pisoTerracottaHW={pisoTerracottaHW}
        pisoAreiaHW={pisoAreiaHW}
        pisoCinzaHW={pisoCinzaHW}
        pisoCarameloHW={pisoCarameloHW}
        //INTERIOR VISAO 1
        visao1Estofado={visao1Estofado}
        estofadoAzul2={estofadoAzul2}
        estofadoBegeClaro2={estofadoBege2}
        estofadoCaramelo2={estofadoCaramelo2}
        estofadoBranco2={estofadoBranco2}
        estofadoCinza2={estofadoCinza2}
        estofadoTerracotta2={estofadoTerracotta2}
        pisoTerracotta2={pisoTerracotta2}
        pisoCinza2={pisoCinza2}
        pisoCaramelo2={pisoCaramelo2}
        pisoBege2={pisoBege2}
        pisoTerracottaHW2={pisoTerracottaHW2}
        pisoAreiaHW2={pisoAreiaHW2}
        pisoCinzaHW2={pisoCinzaHW2}
        pisoCarameloHW2={pisoCarameloHW2}
        interiorCascoAzul2={interiorCascoAzul2}
        interiorCascoVerde2={interiorCascoVerde2}
        interiorCascoCinza2={interiorCascoCinza2}
        interiorCascoCinzaEscuro2={interiorCascoCinzaEscuro2}
        interiorCascoBranco2={interiorCascoBranco2}
        interiorCascoVermelho2={interiorCascoVermelho2}
        interiorCascoPreto2={interiorCascoPreto2}
        //INTERIOR VISAO 2
        visao2Estofado={visao2Estofado}
        estofadoAzul3={estofadoAzul3}
        estofadoBegeClaro3={estofadoBege3}
        estofadoCaramelo3={estofadoCaramelo3}
        estofadoBranco3={estofadoBranco3}
        estofadoCinza3={estofadoCinza3}
        estofadoTerracotta3={estofadoTerracotta3}
        pisoTerracotta3={pisoTerracotta3}
        pisoCinza3={pisoCinza3}
        pisoCaramelo3={pisoCaramelo3}
        pisoBege3={pisoBege3}
        pisoTerracottaHW3={pisoTerracottaHW3}
        pisoAreiaHW3={pisoAreiaHW3}
        pisoCinzaHW3={pisoCinzaHW3}
        pisoCarameloHW3={pisoCarameloHW3}
        //INTERIOR VISAO 3
        visao3Estofado={visao3Estofado}
        estofadoAzul4={estofadoAzul4}
        estofadoBegeClaro4={estofadoBege4}
        estofadoCaramelo4={estofadoCaramelo4}
        estofadoBranco4={estofadoBranco4}
        estofadoCinza4={estofadoCinza4}
        estofadoTerracotta4={estofadoTerracotta4}
        pisoTerracotta4={pisoTerracotta4}
        pisoCinza4={pisoCinza4}
        pisoCaramelo4={pisoCaramelo4}
        pisoBege4={pisoBege4}
        pisoTerracottaHW4={pisoTerracottaHW4}
        pisoAreiaHW4={pisoAreiaHW4}
        pisoCinzaHW4={pisoCinzaHW4}
        pisoCarameloHW4={pisoCarameloHW4}
        title="Mestra 180"
        subtitle="Lancha de 18 pés"
        mercury100="Mercury 100 HP 4T CT"
        mercury115="Mercury 115 HP 4T CT"
        motorizacao60a115popa={
          <div
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <p>Motorizacao de 60 a 115 hp popa </p>
            <p>- 4 tempos CT </p>
            <p>- 4 tempos CT PRO XS </p>
          </div>
        }
        comprimento="5,15 M"
        boca="2,20 M"
        calado="0,30 M"
        li1={t("combustivelTanque")}
        li2={t("tanqueAguaDoce")}
        li3={t("pesoSemMotor")}
        li4={t("passageirosDiurnos")}
        li5={t("motorizacaoPopa")}
        motorizacao={t("motorizacao60a135")}
        combustivel="100 L"
        tanqueAguaDoce="28 L"
        peso="485 KG"
        pessoas="1+7"
        fichaTecnica={fichaTecnica}
        itensSerie={itensSerie}
        itemFabrica1={t("motorizacaoFabricaSubtitle")}
        itemFabrica2={t("sistemaDirecao")}
        itemFabrica3={t("bateria")}
        itemFabrica4={t("bombaPorao")}
        itemFabrica5={t("chaveGeral")}
        itemFabrica6={t("automaticoBombaPorao")}
        itemFabrica7={t("bombaPressurizacao")}
        itemFabrica8={t("volante")}
        itemFabrica9={t("filtros")}
        itemFabrica10={t("relogioMontagem")}
      />
    </>
  );
};
