import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@mui/material';
import theme from '../../theme/Theme';
import { useMediaQuery } from '@material-ui/core';
//icons
import { IconContext } from "react-icons";
import { GiFuelTank } from 'react-icons/gi';
import { GiBoatEngine } from 'react-icons/gi';
import { MdMeetingRoom } from 'react-icons/md';
import { GiWeight } from 'react-icons/gi';
import { IoIosPeople } from 'react-icons/io';
import { MdDirectionsBoat } from 'react-icons/md';
import { useTranslation } from 'react-i18next';


const useStyles = makeStyles({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    height: 'fit-content',
    paddingBottom: "5em",
    //mobile
    [theme.breakpoints.down('md')]: {
      paddingInline: '5em',
    },
  },
  content: {
    display: "flex",
    gap: "5em",
    [theme.breakpoints.down('md')]: {
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      gap: 0

    },
  },
  liLabels: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: '3.5em',
    [theme.breakpoints.down('md')]: {
      alignItems: "flex-start",
      justifyContent: "center",
      gap: '0em',
      padding: 0,
    },
  },
  li: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    gap: '3.5em',

    [theme.breakpoints.down('md')]: {
      justifyContent: "flex-start",
      alignItems: "flex-start",
      gap: '0em',

    },
  },
  mobileText: {
    display: 'flex',
    justifyContent: "center",
    alignItems: "center",
    gap: "1em",
    [theme.breakpoints.down('md')]: {
      paddingBottom: "4em",
      gap: "1em",
      justifyContent: "flex-start",
      alignItems: "center",

    },
  },
  ul: {
    display: 'flex',
    gap: '3em',
    flexDirection: 'column',
    listStyle: 'none',
    justifyContent: 'center',
    alignItems: 'flex-start',
    [theme.breakpoints.down('md')]: {
      gap: "0em",
      justifyContent: 'flex-end',
      alignItems: 'flex-end',

    },
  },

});

const NewFichaContainer = (props) => {
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  console.log("props", props)

  const labelsData = {
    mestra292: [
      'Calado máx.',
      'Ângulo do V do casco',
      'Tanque de combustível',
      'Tanque de água doce',
      'Peso sem motor',
      'Pessoas',
      'Motorização centro-rabeta',
      'Material do casco',
      'Altura T-Top',
      'Altura cabine',
      'Altura do banheiro',
      'Altura do T-Top em relação ao convés',
    ],
    mestra322: [
      'Calado máx.',
      'Ângulo do V do casco',
      'Tanque de combustível',
      'Tanque de água doce',
      'Peso sem motor',
      'Pessoas',
      'Motorização centro-rabeta',
      'Material do casco',
      'Altura T-Top',
      'Altura cabine',
      'Altura do banheiro',
      'Altura do T-Top em relação ao convés',
    ],
    mestra240: [
      'Ângulo do V na popa',
      'Tanque de combustível',
      'Tanque de água doce',
      'Peso sem motor',
      'Pessoas',
      'Camarotes / Banheiros',
      'Pé-direito cabine',
      'Motorização centro-rabeta',
    ],
    mestra230: [
      'Ângulo do V na popa',
      'Tanque de combustível',
      'Tanque de água doce',
      'Peso sem motor',
      // '8 dia / 2 noite',
      'Pessoas',
      'Pé-direito cabine',
      'Motorização centro-rabeta',
    ],
    mestra222: [
      'Tanque de combustível',
      'Tanque de água doce',
      'Peso sem motor',
      'Pessoas',
      'Motorização centro-rabeta',
    ],
    mestra212: [
      'Tanque de combustível',
      'Tanque de água doce',
      'Peso sem motor',
      'Pessoas',
      'Motorização centro-rabeta',
    ],
    mestra200: [
      'Tanque de combustível',
      'Tanque de água doce',
      'Peso sem motor',
      'Pessoas',
      'Motorização centro-rabeta',
    ],
    mestra198: [
      'Tanque de combustível',
      'Tanque de água doce',
      'Peso sem motor',
      'Pessoas',
      'Motorização centro-rabeta',
    ],
    mestra180: [
      'Tanque de combustível',
      'Tanque de água doce',
      'Peso sem motor',
      'Pessoas',
      'Motorização centro-rabeta',
    ],
    mestra160: [
      'Tanque de combustível',
      'Tanque de água doce',
      'Peso sem motor',
      'Pessoas',
      'Motorização centro-rabeta',
    ],
    // Add other data objects here as required.
  };

  const fichaData = {
    mestra292: [
      '0,84 M',
      '19°',
      '300 L',
      '120 L',
      '2.300 KG',
      '13/4',
      'de 300 à 380 HP',
      'Fibra de vidro',
      '1,95 M',
      '1,78 M',
      '1,85 M',
      '1,95 M',
    ],
    mestra322: [
      '0,84 M',
      '19°',
      '400 L',
      '120 L',
      '3.500 KG',
      '15/4',
      'de 380 à 250 HP',
      'Fibra de vidro',
      '2,00 M',
      '1,90 M',
      '1,85 M',
      '2,10 M',
    ],
    mestra240: [
      '19°',
      '192 L',
      '60 L',
      '1150 KG',
      '1 + 9',
      '1',
      '1,45 M',
      'de 200 à 280 HP',
    ],
    mestra230: [
      '19°',
      '192 L',
      '60 L',
      '1150 KG',
      '8 dia / 2 noite',
      // '1',
      '1,45 M',
      'de 200 à 280 HP',
    ],
    mestra222: [
      '100 L', '60 L', '900 KG', '1+9', 'de 200 à 280 HP'
    ],
    mestra212: [
      '100 L', '60 L', '700 KG', '1 + 8', 'de 100 à 120 HP'
    ],
    mestra200: [
      '100 L', '45 L', '550 KG', '1+7', 'de 90 à 150 HP'
    ],
    mestra198: [
      '100 L', '28 L', '550 KG', '1+7', 'de 90 à 150 HP'
    ],
    mestra180: [
      '100 L', '28 L', '485 KG', '1+7', 'de 60 à 135 HP'
    ],
    mestra160: [
      '43 L', '28 L', '485 KG', '1+3', 'de 40 à 60 HP'
    ]
  }

  const iconsLancha = {
    mestra322: [
      <MdDirectionsBoat />,
      <MdDirectionsBoat />,
      <GiFuelTank />,
      <GiFuelTank />,
      <GiWeight />,
      <IoIosPeople />,
      <GiBoatEngine />,
      <MdDirectionsBoat />,
      <MdDirectionsBoat />,
      <MdDirectionsBoat />,
      <MdDirectionsBoat />,
      <MdDirectionsBoat />,
    ],
    mestra292: [
      <MdDirectionsBoat />,
      <MdDirectionsBoat />,
      <GiFuelTank />,
      <GiFuelTank />,
      <GiWeight />,
      <IoIosPeople />,
      <GiBoatEngine />,
      <MdDirectionsBoat />,
      <MdDirectionsBoat />,
      <MdDirectionsBoat />,
      <MdDirectionsBoat />,
      <MdDirectionsBoat />,
    ],
    mestra240: [
      <MdDirectionsBoat />,
      <GiFuelTank />,
      <GiFuelTank />,
      <GiWeight />,
      <IoIosPeople />,
      <MdMeetingRoom />,
      <MdMeetingRoom />,
      <GiBoatEngine />,
    ],
    mestra230: [
      <MdDirectionsBoat />,
      <GiFuelTank />,
      <GiFuelTank />,
      <GiWeight />,
      <IoIosPeople />,
      <MdMeetingRoom />,
      <GiBoatEngine />,
    ],
    mestra222: [
      <GiFuelTank />,
      <GiFuelTank />,
      <GiWeight />,
      <IoIosPeople />,
      <GiBoatEngine />,
      <MdMeetingRoom />,
      <GiBoatEngine />,
    ],
    mestra212: [
      <GiFuelTank />,
      <GiFuelTank />,
      <GiWeight />,
      <IoIosPeople />,
      <GiBoatEngine />,
      <MdMeetingRoom />,
      <GiBoatEngine />,
    ],
    mestra200: [
      <GiFuelTank />,
      <GiFuelTank />,
      <GiWeight />,
      <IoIosPeople />,
      <GiBoatEngine />,
      <MdMeetingRoom />,
      <GiBoatEngine />,
    ],
    mestra198: [
      <GiFuelTank />,
      <GiFuelTank />,
      <GiWeight />,
      <IoIosPeople />,
      <GiBoatEngine />,
      <MdMeetingRoom />,
      <GiBoatEngine />,
    ],
    mestra180: [
      <GiFuelTank />,
      <GiFuelTank />,
      <GiWeight />,
      <IoIosPeople />,
      <GiBoatEngine />,
      <MdMeetingRoom />,
      <GiBoatEngine />,
    ],
    mestra160: [
      <GiFuelTank />,
      <GiFuelTank />,
      <GiWeight />,
      <IoIosPeople />,
      <GiBoatEngine />,
      <MdMeetingRoom />,
      <GiBoatEngine />,
    ]
  }

  const [icons, setIcons] = useState([])

  const [lancha, setLancha] = useState();

  const lanchaMap = () => {
    Object.keys(props.fichaProps).forEach((key) => {
      const item = props.fichaProps[key];
      if (item === true) {
        setLancha((prevLancha) => {
          console.log("lancha::", key);
          return key;
        });
        setIcons(iconsLancha[key])
      }
    });
  };

  useEffect(() => {
    lanchaMap();
  }, []);

  const renderContent = (data) => {
    return (
      <>
        {Object.keys(data).map((key, index) => {
          if (lancha === key) {
            const labels = data[key];
            const values = fichaData[key];
            return (
              <div key={index} className={classes.content} >
                <div className={classes.label}>
                  <ul className={classes.ul} >
                    <li className={classes.liLabels} >
                      {labels.map((label, idx) => (
                        <div className={classes.mobileText}>
                          <div>
                            <IconContext.Provider value={{ className: "shared-class", size: isMobile ? 35 : 50 }}>
                              {icons[idx]}
                            </IconContext.Provider>
                          </div>
                          <div>
                            <Typography key={idx} variant={isMobile ? 'body1' : 'h5'}>
                              {label}
                            </Typography>
                          </div>
                        </div>

                      ))}
                    </li>
                  </ul>
                </div>
                <div className={classes.values} >
                  <ul className={classes.ul} style={{ gap: '3.5em' }}>
                    <li className={classes.li} >
                      {values.map((value, idx) => (
                        <div className={classes.mobileText}>
                          <div style={{ width: isMobile ? '35px' : '50px', height: isMobile ? '35px' : '50px' }}></div>
                          <Typography key={idx} variant={isMobile ? 'body1' : 'h5'}>
                            {value}
                          </Typography>
                        </div>
                      ))}
                    </li>
                  </ul>
                </div>
              </div>
            )
          } else {
            return null
          }
        })}
      </>
    );
  };


  return (
    <div className={classes.root} >
      {renderContent(labelsData)}
    </div>
  );
};

export default NewFichaContainer;
