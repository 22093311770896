import React from "react";
import ModelPage from "../../components/ModelPage/ModelPage";
import mestra212Modelo from "../../data/images/desktop/model212_desktop.jpg";
import mestra212ModeloMobile from "../../data/images/mobile/model212_mobile_v01.jpg";
import interior from "../../data/images/customLancha212/visao1Estofado.png";
import imgCarousel1 from "../../data/images/mestra212/carouselImg1.jpg";
import imgCarousel2 from "../../data/images/mestra212/carouselImg2.jpg";
import imgCarousel3 from "../../data/images/mestra212/carouselImg3.jpg";
import imgCarousel4 from "../../data/images/mestra212/carouselImg4.jpg";
import imgCarousel5 from "../../data/images/mestra212/carouselImg5.jpg";
import imgCarousel6 from "../../data/images/mestra212/carouselImg6.jpg";
import imgDetails1 from "../../data/images/details230_240_2.jfif";
import imgDetails2 from "../../data/images/details230_240.jfif";
import model from "../../data/images/customLancha212/fotoBasePNG.png";

const ModelMestra212 = () => {
  const fichaProps = {
    mestra292: false,
    mestra322: false,
    mestra240: false,
    mestra230: false,
    mestra222: false,
    mestra212: true,
    mestra200: false,
    mestra198: false,
    mestra180: false,
    mestra160: false,
  };

  const imagens = [
    imgCarousel1,
    imgCarousel2,
    imgCarousel3,
    imgCarousel4,
    imgCarousel5,
    imgCarousel6
  ]


  return (
    <ModelPage
      lastSectionWhite={true}
      mestra212Text
      mestra212
      mestra240
      fichaProps={fichaProps}
      length="6,17 M"
      width="2,25 M"
      draught="0,32 M"
      img={mestra212Modelo}
      mobileImage={mestra212ModeloMobile}
      imgInterior={interior}
      carouselImages={imagens}
      imgDetails1={imgDetails1}
      imgDetails2={imgDetails2}
      modelName="Mestra 212"
      modelURL="mestra212"
      model={model}
    />
  );
};

export default ModelMestra212;
