import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          semMotor: "No engine",
          contactPageTitle: "Contact",
          nome: "Name",
          sobrenome: "Last Name",
          telefone: "Phone",
          mensagem: "Message",
          motorizacao: "Motorization",
          cores: "Exterior colors",
          selecionarCor: "Select a color:",
          corBanco: "Seat Color",
          selecionarCorBanco: "Select a color for the interior",
          tapecaria: "Floor",
          customizar: "CUSTOM ORDER",
          solicitarOrcamento: "REQUEST A QUOTE",
          mestra240Subtitle: "Mestra 240 - 24 feet Speedboat",
          mestra216Subtitle: "Mestra 240 - 16 feet Speedboat",
          carrinhoDeCompras: "Add to cart",
          carrinhoDeComprasDescricao:
            "Add this custom item to your cart and continue viewing other items",
          comprimento: "Length",
          boca: "Width",
          calado: "Draught",
          ou: "OR",
          orcamentoItem: "Request a quote for this item",
          orcamentoItemDescricao: "Contact us to request your order",
          ficha: "Datasheet",
          informacoes: "More information:",
          itensSerie: "Serial Items:",
          combustivelTanque: "Fuel tank",
          acessorios: "Accessories",
          anguloVCopa: "Angle of V Stern",
          tanqueAguaDoce: "Fresh water tank",
          pesoSemMotor: "Weight without engine",
          pessoas: "People",
          camarotes: "Cabins / Bathrooms",
          peDireitoCabine: "Right foot cabin",
          motorizacaoCentroRabeta: "Sterndrive engine",
          help: "Ask for help",
          helpModalTitle: "Need help setting up your speedboat?",
          helpModalSubtitle: "Contact us",
          passageirosDiurnos: "Day passengers",
          motorizacaoPopa: "stern engine",
          pedidoTitle: "Request a quote for your order",
          pedidoSubtitle: "Confirm your order",
          informacoesAdicionais: "Additional Information:",
          digiteMensagem: "Type your message",
          // Itens de serie
          lixeiraAcrilico: "Acrylic Recycle Bin",
          armarioAcrilico: "Acrylic cabinet",
          cristaleiraAcrilico: "Acrylic cabinet",
          bocalCombustivel: "Stainless fuel nozzle",
          bocalAguaDoce: "Stainless steel fresh water nozzle",
          portaCoposInox: "Stainless steel coaster",
          escadaDePopaInox: "Stainless steel stern ladder",
          kitTorneiraPia: "Faucet and Sink Kit",
          mesaFibraSuporteInox:
            "Fiber table with stainless steel tube and support",
          luzesNavegacao: "Navigation lights",
          luzProaBicolor: "Bicolor Bow Light",
          luzPopaMastro: "Stern Light Mast",
          luzesGabinete: "Cabin Lights",
          sistemaEletrico: "12V electrical system",
          duchaPopa: "Shower on the stern",
          estofamentoCourvim: "Upholstery in Nautical Courvin",
          paiosProa: "3 Storerooms on the bow",
          paiosCockPit: "2 lockers in the cockpit",
          compartimentoBoiaCircular: "Circular Buoy Compartment",
          portaPopaInox: "Stainless steel transom door",
          sistemaHidraulico: "Hydraulic System (Sink, Faucet, Shower)",
          vasoSanitario: "Manual toilet bowl",
          rollerAncora: "Anchor Roller",
          bancoGiratorio: "Pilot Swivel Bench",
          caixaMovel: "Mobile Box under Popa Solarium",
          pegaMaoInox: "Stainless steel handgrip",
          caixaMovelTraseiro: "Mobile Box under Rear Upholstery",
          acentoRemovivel: "Removable seat passage to the bow",
          acentosRemovivelPopa: "Removable Stern Seats",

          // Itens de Fábrica
          motorizacaoFabrica: "FACTORY INSTALLED ENGINE:",
          motorizacaoFabricaSubtitle: "Motor + Accessories Basic Assembly Kit:",
          sistemaDirecao: "Complete steering and command system",
          bateria: "Battery",
          bombaPorao: "Bilge bomb",
          chaveGeral: "Master key",
          automaticoBombaPorao: "Bilge pump automatic",
          bombaPressurizacao: "Pressurizing pump",
          volante: "steering wheel",
          filtros: "Filters",
          relogioMontagem: "Mounting Watches",

          //acessorios
          churrasqueira: "Barbecue grill",
          ancoraEletronica: "Electronic Anchor",
          cobertura: "Boat cover",
          som: "Stereo",
          luzes: "Lights",
          targaEsporte: "Targa for sport",
          bandeiraBrasil: "Brazil's flag",
          interiorPersonalizavel: "Customizable Interior",
          colorOptions: "COLOR OPTIONS",
          cascoCores: "for the hull",
          coresEstofado: "to the seats",
          coresTapecaria: "for the tapestry",
          mercuryMotor: "MERCURY ENGINES",
          mercuryMotorIdeal: "Choose the ideal engine for your boat",
          peçaAgora: "Order now",

          //contact page
          models: "Models",
          send: "Send",
          about: "About us",
          contactUs: "Contact Us",
          preencherForm: "Fill in the form to request a quote",
          contact: "Contact",
          detailRequest:
            "* If you want to request a detailed and customized quote, select the desired model and go to CUSTOMIZE. Complete the steps and submit your desired Mestra model.",
          //About Page
          aboutPageTitle: "WELCOME TO MESTRA BOATS WORLD",
          aboutPageDescriptionOne:
            "Founded in 2010, Mestra Boats is a company that was born with the aim of producing high quality boats that prioritize comfort, performance, sportiness and most importantly  safety.",
          aboutPageDescriptionTwo:
            "Based since its foundation in Pederneiras, in the interior of São Paulo, our shipyard has its own headquarters with an area of ​​20 thousand square meters, where 8 models of vessels are currently produced.",
          aboutPageDescriptionThree:
            "Composed by a qualified and careful team in the construction and finishing of its products, Mestra Boats produces all its boats with the technology of 3 laminations, characterizing itself as one of the main differentials of the shipyard, thus bringing boats more resistant, safe and with high quality on the market.",
          aboutPageDescriptionFour:
            "It is currently the only shipyard that has in its line the option of bars with the submersion system, built with the aim of improving the safety of navigators in adverse conditions. This system provides the passengers safety of floating in case of an accident, as well as reducing noise during navigation.",
          aboutPageDescriptionFive:
            "Our riverside location allows all our vessels to be submitted to a complex and thorough testing schedule before they are delivered to our customers. Furthermore, as it is located in the center-west of São Paulo, our factory has a professional logistics team to assist our partners and customers in the national and international distribution of products and parts.",
          aboutPageDescriptionSix:
            "Our boats are designed to deliver an unrivaled combination of speed, agility and efficiency, which combine to ensure an effortless journey wherever you choose to venture.",
          aboutPageDescriptionSeven:
            "Getting into a Mestra you'll step into a speedboat that reflects our unique vision of style, enjoyment and design. Where the highest levels of quality always come as standard across the board, which our qualified workforce ensures the process of improvement continues, that is also a motivation of our employees. Supported by a network that can be recognized as part of our family, you can rest assured that you will have the best levels of customer service to complement what makes Mestra Boats the best of international quality boats.",
          aboutPageDescriptionEight:
            "Mestra offers confidence behind the wheel, safe maneuvering and high-speed euphoria.",
          aboutPageDescriptionNine:
            "You know you have a boat that was and was built with seriousness and aims to make our customers' dreams come true.",
          aboutPageDescriptionTen: "",
          aboutPageDescriptionEleven: "",
          aboutPageDescriptionTwelve:
            "Meet the needs and expectations of its customers, through the continuous improvement of processes, training of its employees, meeting requirements and continuous improvement of the quality management system and its products.",
          aboutPageMission1:
            "- To promote the entry of new consumers in the Brazilian nautical market, by being a manufacturer, among others, of models suitable for new sailors;",
          aboutPageMission2:
            "- To be recognized as a large nautical industry, with managers, technicians, and skilled professionals who are fully concerned with the development of special products, with raw materials within the norms and standards of quality for the commitment to the environment and social responsibility.",
          aboutPageDescriptionThirteen:
            "Meet the needs and expectations of its customers, through the continuous improvement of processes, training of its employees, meeting requirements and continuous improvement of the quality management system and its products.",
          aboutPageDescriptionFourteen:
            "Solidify the brand in the context of the nautical market in Brazil, in the segment of vessels in the range from 18 to 23 feet, providing for the creation and improvement of models that will meet market expectations.",
          aboutPageValueOne: "Professionalism",
          aboutPageValueTwo: "Commitment to the customer",
          aboutPageValueThree: "Commitment to quality",
          aboutPageValueFour: "Commitment to the environment",
          AboutPageValueLabel: "Our Values",
          AboutPageMissionLabel: "Our Mission",
          AboutPageVisionLabel: "Our Vision",
          AboutPageGoalsLabel: "Our Goals",
          modalHelp: "Needing help?",
          idioma: "PT-BR",
          mestra240Text:
            "“Present throughout the national territory, Mestra 240 revolutionizes the nautical market in many aspects. Designed by our engineers, this vessel is the pioneer of its category in offering a large bathroom, as well as a design that provides comfort, internal space and a very safe seaworthiness. DNA of our shipyard. With 7.00 meters in length, 2.45 meters in width and 0.34 meters in draft, this model meets the limit of requirements for transport on state and federal highways. Approved model for 1+9 people and equipped with engines from 200 hp to 280hp.”",
          mestra230Text:
            "“One of the most renowned models of the brand, Mestra 230 is the favorite of those who wish to travel with the vessel, given that it offers a large cabin, with space for a bathroom and two internal windows. It is 7.00 meters long, 2.45 meters wide and 0.36 meters deep. This model, in addition to having a sophisticated interior space, has a spacious bow solarium with a comfortable bow cushion, being the perfect environment for those who wish to sunbathe. Approved for 1+9 crew and built to be equipped with engines from 200 hp to 280hp.”",
          mestra222Text:
            "“A model renowned for its large internal space and its powerful and agile navigation,  Mestra 222 is the perfect choice for those who seek, in addition to leisure and refinement, an option for the practice of sports such as wakeboarding. With 6.80 meters in length, 2.45 meters in width, 0.34 meters in draft and also built for engines from 200 hp to 280hp, this vessel has a combination of comfort and radical spirit. Approved for 1+9 crew.”",
          mestra212Text:
            "“Designed by our shipyard in 2017, Mestra 212 meets the needs of interior space and comfort for nautical lovers. Having in its DNA the Mestra characteristics of navigability, stability and comfort, this boat measures 6.17 meters in length, 2.25 meters in width and 0.32 meters in draft. Built to be equipped with engines from 100hp to 220hp, with a fuel tank of 100 liters, this model offers its 1+8 crew members the longest range in its category”",
          mestra200Text:
            "“Launched at the São Paulo Boat Show 2021, the Mestra 200 brings a new concept to the nautical world, in which engineers found the best combination of interior space and hull size. Built in measures of 5.80 meters in length, 2.35 meters in width and 0.30 meters in draft, this boat comes to revolutionize its category, bringing sportiness with engines from 90hp to 150hp, stability and safety with a bold hull design for its 8+1 passengers.”",
          mestra198Text:
            "“One of our most renowned models, Mestra 198 has in its essence the combination of internal modernity and traditionality in its hull, showing to the customer a superior navigability in its category. At 5.80 meters long, 2.25 meters wide and 0.30 meters deep, this vessel is the perfect choice for navigation in a variety of environments. Built to support engines from 100hp to 150hp, its performance brings not only a sporty DNA, but also safety and stability to its 7+1 passengers.”",
          mestra180: "“”",
          mestra160: "“”",
          mestra180Text:
            "“On the production line since 2010, Mestra 180 is a very popular model in the Brazilian industry. With 5.15 meters in length, 2.20 meters in width and 0.30 meters in draft, this vessel is often chosen as the entry boat to the nautical world. Built with the aim of obtaining the best relationship between weight and power, this vessel has outboard engines from 60Hp to 115Hp, 100 liter fuel tank, making its performance superior to any boat in this category. Its interior space is approved to accommodate 7+1 passengers.”",
          mestra160Text:
            "“Mestra 160 is our first boat designed for fishing, which also serves recreational purposes. Known as Mestra Fish, this model combines the navigability present in all our vessels, the comfort and elegance characteristic of the brand, as well as all the needs of a fisherman. Equipped with rotating and removable seats to facilitate the sport activity, this boat is 4.74 meters long, 1.70 meters wide and 0.37 meters in draft. The word that best defines this vessel is “versatility”. Therefore, for those looking for a high quality vessel with optimized space, the Mestra 160 is the best option, with engines from 40hp to 60hp and homologated for 1+3 people.”",
          motorizacao200a280: "from 200 to 280 HP",
          motorizacao100a220: "from 100 to 220 HP",
          motorizacao90a150: "from 90 to 150 HP",
          motorizacao60a135: "from 60 to 135 HP",
          motorizacao40a60: "from 40 to 60 HP",
          aboutPageValueOne: "Professionalism",
          aboutPageValueTwo: "Commitment to the customer",
          aboutPageValueThree: "Commitment to quality",
          aboutPageValueFour: "Commitment to the environment",
          AboutPageValueLabel: "Values",
          AboutPageVisionLabel: "Vision",
          AboutPageGoalsLabel: "Goals",
          AboutPageMissionLabel: "Mission",
          //Model page texto ao lado das imagens
          painelNavegacao: "Customizable navigation panel, aiming at the best comfort for the captain.",
          sistemaAncora: "Modern electronic anchor system, finished in stainless steel.",
          sistemaSom: "High-tech, marinized and customizable sound system, aimed at providing the best experience for Mestra customers.",
          sincronizacaoOpcional: "Optional synchronization of music and colors, to turn your Mestra into the perfect place for celebrations",
          acabamentoAcrilico: "Acrylic finishes and indirect lighting for passenger safety.",
          portaCopos: "Stainless steel cup holder following our brand pattern. Optional multicolor lighting.",
          estofamento: "Handmade upholstery developed by our specialists in order to meet the ergonomic needs of our passengers.",
          pisosDiversasCores: "We offer two types of flooring in different colors, Mestra Hardwood and Mestra Softwood, both aimed at the comfort as well as the safety of our passengers when moving around inside.",
          cockpitCapitao: "Fully adaptable captain's cockpit, to suit various statures. High-tech control panel, with analog and electronic clocks, following our stainless steel finishing.",
          desfruteNavegar: "Enjoy navigate in what is best in the market, in terms of quality, safety, and leisure.",
          videos: "Videos",
          modelPage: "Model Page"
          // here we will place our translations...
        },
      },
      pt: {
        translation: {
          semMotor: "Sem motor",
          contactPageTitle: "Contato",
          // Itens de serie
          acentosRemovivelPopa: "Acentos removíveis de Popa",
          acentoRemovivel: "Acento removivel passagem para Proa",
          pegaMaoInox: "Pega mão de Inox",
          lixeiraAcrilico: "Lixeira de Acrilico",
          armarioAcrilico: "Armário de Acrilico",
          cristaleiraAcrilico: "Cristaleira de Acrilico",
          bocalCombustivel: "Bocal de combustível Inox",
          bocalAguaDoce: "Bocal de água doce Inox",
          portaCoposInox: "Porta copos de Inox",
          escadaDePopaInox: "Escada de Popa em Inox",
          kitTorneiraPia: "Kit Torneira e Pia",
          mesaFibraSuporteInox: "Mesa de fibra c/ suporte e tubo inox",
          luzesNavegacao: "Luzes de navegação",
          luzProaBicolor: "Luz de Proa Bicolor",
          luzPopaMastro: "Luz de Popa Mastro",
          luzesGabinete: "Luzes de cabine",
          sistemaEletrico: "Sistema elétrico 12V",
          duchaPopa: "Ducha na Popa",
          bancoGiratorio: "Banco Giratorio Piloto",
          estofamentoCourvim: "Estofamento em Courvim Náutico",
          paiosProa: "3 Paióis na proa",
          paiosCockPit: "2 Paióis no cockpit",
          compartimentoBoiaCircular: "Compartimento Boia Circular",
          portaPopaInox: "Porta de popa de inox",
          sistemaHidraulico: "Sistema Hidraulico (Pia, torneira, Chuveiro)",
          vasoSanitario: "Vaso sanitáro manual",
          rollerAncora: "Roller para Ancora",
          caixaMovel: "Caixa Móvel sob solario de Popa",
          caixaMovelTraseiro: "Caixa Móvel sob Estofamento Traseiro",
          // Itens de Fábrica

          motorizacaoFabrica: "MOTORIZAÇÃO INSTALADA NA FÁBRICA:",
          motorizacaoFabricaSubtitle:
            "Motor + Acessórios Kit de Montagem Básica:",
          sistemaDirecao: "Sistema de direção e comando completo",
          bateria: "Bateria",
          bombaPorao: "Bomba de porão",
          chaveGeral: "Chave geral",
          automaticoBombaPorao: "automático de bomba de porão",
          bombaPressurizacao: "Bomba de pressurização",
          volante: "Volante",
          filtros: "Filtros",
          relogioMontagem: "Relógios de montagem",

          //acessorios

          churrasqueira: "Churrasqueira",
          ancoraEletronica: "Âncora Eletrônica",
          cobertura: "Cobertura",
          som: "Som",
          luzes: "Luzes",
          targaEsporte: "Targa para esporte",
          bandeiraBrasil: "Bandeira do Brasil",

          digiteMensagem: "Digite a sua mensagem",
          motorizacaoPopa: "Motorização popa",
          passageirosDiurnos: "Passageiros diurnos",
          informacoesAdicionais: "Informações adicionais:",
          pedidoTitle: "Solicite o orçamento do seu pedido",
          pedidoSubtitle: "Confirme seu pedido ",
          helpModalSubtitle: "Entre em contato conosco",
          helpModalTitle: "Precisa de ajuda para montar sua lancha?",
          help: "Peça ajuda",
          nome: "Nome",
          sobrenome: "Sobrenome",
          telefone: "Telefone",
          mensagem: "Mensagem",
          motorizacao: "Motorização",
          cores: "Cores do exterior",
          selecionarCor: "Selecione uma cor:",
          corBanco: "Cor dos bancos",
          selecionarCorBanco: "Selecione uma cor para o interior:",
          tapecaria: "Piso",
          customizar: "CUSTOMIZAR",
          solicitarOrcamento: "SOLICITAR ORÇAMENTO",
          mestra240Subtitle: "Mestra 240 - Lancha 24 pés",
          carrinhoDeCompras: "Adicionar ao carrinho",
          carrinhoDeComprasDescricao:
            " Adicione esse item personalizado ao carrinho e continue visualizando outros itens",
          comprimento: "Comprimento",
          boca: "Boca",
          calado: "Calado",
          ou: "OU",
          orcamentoItem: "Solicite orçamento desse item",
          orcamentoItemDescricao:
            "Entre em contato conosco para solicitar o seu pedido",
          ficha: "Ficha Técnica",
          informacoes: "Mais informações:",
          itensSerie: "Itens de Série:",
          combustivelTanque: "Tanque de combustível",
          acessorios: "ACESSÓRIOS OPCIONAIS DE FÁBRICA:",
          anguloVCopa: "Ângulo do V na popa",
          tanqueAguaDoce: "Tanque de água doce",
          pesoSemMotor: "Peso sem motor",
          pessoas: "Pessoas",
          camarotes: "Camarotes / Banheiros",
          peDireitoCabine: "Pé-direito cabine",
          motorizacaoCentroRabeta: "Motorização centro-rabeta",
          interiorPersonalizavel: "Interior Personalizável",
          colorOptions: "OPÇÕES DE CORES",
          cascoCores: "para o casco",
          coresEstofado: "para o estofado",
          coresTapecaria: "para a tapeçaria",
          mercuryMotor: "MOTORES MERCURY",
          mercuryMotorIdeal: "Escolha o motor ideal para sua lancha",
          peçaAgora: "Peça o seu agora",
          //contact page
          models: "Modelos",
          send: "Enviar",
          about: "Sobre nós",
          contactUs: "Entre em contato conosco",
          contact: "Contato",
          preencherForm: "Preencha o formulário para solicitar um orçamento",
          detailRequest:
            "* Caso queira solicitar um orçamento detalhado e customizado, selecione o modelo desejado e entre em CUSTOMIZAR. Complete as etapas e envie seu modelo de Mestra desejado.",

          //About Page
          aboutPageTitle: "BEM-VINDO AO MUNDO MESTRA BOATS",
          aboutPageDescriptionOne:
            "Fundada em 2010, a Mestra Boats é uma empresa que nasceu com o objetivo de produzir  embarcações de alta qualidade que priorizam conforto, desempenho, esportividade e acima de tudo segurança.",
          aboutPageDescriptionTwo:
            "Sediada desde sua fundação em Pederneiras, interior de São Paulo, nosso estaleiro possui sede própria com uma área de 20 mil metros quadrados, onde são produzidos atualmente 8 modelos de embarcações.",
          aboutPageDescriptionThree:
            "Constituída por uma equipe qualificada e criteriosa na construção e acabamento de seus produtos, a Mestra Boats produz todas as suas embarcações com a tecnologia de 3 laminações, caracterizando-se como um dos principais diferenciais do estaleiro, trazendo assim lanchas mais resistentes, seguras e com altíssima qualidade ao mercado.",
          aboutPageDescriptionFour:
            "Atualmente é o único estaleiro que possui em sua linha, barcos com o sistema de insubmergibilidade, construído com o intuito de melhorar a segurança dos navegantes em condições adversas. Esse sistema traz aos passageiros a segurança de uma flutuação em caso de acidentes, bem como a diminuição de ruídos durante a navegação",
          aboutPageDescriptionFive:
            "Nossa localização próxima ao rio, permite que todas as nossas embarcações sejam submetidas a um cronograma complexo e minucioso de testes antes que sejam entregues aos nossos clientes. Ademais, por estar no centro-oeste paulista, nossa fábrica conta com um time profissional de logística para atender nossos parceiros e clientes na distribuição nacional e internacional de produtos e peças.",
          aboutPageDescriptionSix:
            "As nossas lanchas foram projetadas para oferecer uma combinação incomparável de velocidade, agilidade e eficiência, que se combinam para garantir um trajeto sem esforço, onde quer que você escolha se aventurar.",
          aboutPageDescriptionSeven:
            "Entrando em uma Mestra e você adentrará em uma lancha que reflete nossa visão única de estilo, prazer e design. Onde os maiores níveis de qualidade sempre vêm como padrão em toda linha com a nossa força no trabalho capacitada que garantem que o processo de melhoria contínua que é a motivação dos nossos colaboradores. Auxiliado por uma rede de revendedores experientes, muitos dos quais são parte da nossa família, você pode estar tranquilo que terá os melhores níveis de atendimento ao cliente para complementar no que acreditamos a Mestra Boats é a melhor empresa de barcos de lazer do Brasil com qualidade internacional. A Mestra oferece confiança no volante, segurança nas manobras e euforia em alta velocidade. Você sabe que tem uma lancha que foi projetada e construída com seriedade e esforço visando a realização do sonho dos nosso clientes.",
          aboutPageDescriptionEight: "",
          aboutPageMission1:
            "- Fomentar a entrada de novos consumidores no mercado náutico brasileiro, por ser fabricante, entre outros, de modelos indicados para novos navegadores;",
          aboutPageMission2:
            " - Ser reconhecida como uma indústria náutica de porte, com gestores, técnicos e profissionais capacitados e que atuam plenamente preocupados com o desenvolvimento de produtos especiais, com matérias primas dentro das normas e padrões de qualidade para o compromisso com o meio ambiente e a responsabilidade social.",
          aboutPageDescriptionNine: "",
          aboutPageDescriptionTen:
            "Auxiliado por uma rede de revendedores experientes, muitos dos quais são parte da nossa família, você pode estar tranquilo que terá os melhores níveis de atendimento ao cliente para complementar no que acreditamos a Mestra Boats é a melhor empresa de barcos de lazer do Brasil com qualidade internacional.",
          aboutPageDescriptionEleven:
            "A Mestra oferece confiança no volante, segurança nas manobras e euforia em alta velocidade. Você sabe que tem uma lancha que foi projetada e construída com seriedade e esforço visando a realização do sonho dos nosso clientes.",
          aboutPageDescriptionTwelve:
            "Atender as necessidades e expectativas de seus clientes, através do aprimoramento contínuo dos processos, da capacitação de seus colaboradores, do atendimento aos requisitos e da melhoria contínua do sistema de gestão da qualidade e dos seus produtos.",
          aboutPageDescriptionThirteen:
            "Atender as necessidades e expectativas de seus clientes, através do aprimoramento contínuo dos processos, da capacitação de seus colaboradores, do atendimento aos requisitos e da melhoria contínua do sistema de gestão da qualidade e dos seus produtos. ",
          aboutPageDescriptionFourteen:
            "Solidificar a marca no contexto do mercado náutico do Brasil, no segmento de embarcações na faixa de 18 a 23 pés, prevendo criar e aperfeiçoar modelos que venham atender às expectativas de mercado.",
          aboutPageValueOne: "Profissionalismo",
          aboutPageValueTwo: "Compromisso com o cliente",
          aboutPageValueThree: "Compromisso com a qualidade",
          aboutPageValueFour: "Compromisso com o meio ambiente",
          AboutPageValueLabel: "Valores",
          AboutPageVisionLabel: "Visão",
          AboutPageGoalsLabel: "Objetivos",
          AboutPageMissionLabel: "Missão",
          modalHelp: "Precisando de ajuda?",
          idioma: "US",
          mestra240Text:
            "“Presente em todo o território nacional, a Mestra 240 revoluciona o mercado náutico em muitos quesitos. Projetada pelos nossos engenheiros, essa embarcação é a pioneira de sua categoria  em oferecer um banheiro amplo, bem como um design que proporciona conforto, espaço interno e navegabilidade de muita segurança, DNA de nosso estaleiro . Com 7.00 metros de comprimento, 2.45 metros de largura e 0.34 metros de calado, esse modelo atende o limite dos requisitos para transporte em rodovias estaduais e federais. Modelo homologado para 1+9 pessoas e equipado com motores de 200hp a 280hp.”",
          mestra230Text:
            "“Um dos mais consagrados modelos da marca, a Mestra 230, é a preferida daqueles que desejam viajar com a embarcação, tendo em vista que oferece uma cabine ampla, com espaço para um banheiro e com duas janelas internas. Com 7.00 metros de comprimento, 2.45 de largura e 0.36 de calado. Esse modelo além de possuir um espaço interno sofisticado, possui um solário de proa espaçoso  com uma almofada de proa confortável, sendo o ambiente perfeito para aqueles que desejam tomar sol. Homologada para 1+9 tripulantes e construída para ser equipada com motores de 200hp a 280hp.”",
          mestra222Text:
            "“Um modelo conceituado pelo seu grande espaço interno e sua navegação potente e  ágil, a Mestra 222 é a escolha perfeita para aqueles que procuram além de lazer e requinte, uma opção para a prática de esportes como o wakeboard. Com 6.80 metros de comprimento, 2.45 metros de largura, 0.34 metros de calado e construída para motores de 200hp a 280hp essa embarcação possui a união de conforto e espírito radical. Homologada para 1+9 tripulantes.”",
          mestra212Text:
            "“Elaborada pelo nosso estaleiro em 2017, a Mestra 212 atende as necessidades de espaço interno e comodidade para os amantes da náutica. Tendo em seu DNA as características Mestra de  navegabilidade, estabilidade e conforto esta lancha detém de 6.17 metros de comprimento, 2.25 metros de largura e 0.32 metros de calado. Construída para ser equipada com motores de 100hp a 220hp, com um tanque de combustível de 100 Litros, esse modelo traz aos seus 1+8 tripulantes a maior autonomia de sua categoria.”",
          mestra200Text:
            "“Lançada no São Paulo Boat Show 2021, a Mestra 200 traz ao mundo náutico um novo conceito, em que engenheiros encontraram a melhor combinação de espaço interno e tamanho de casco. Construída nas medidas de 5.80 metros de comprimento, 2.35 metros de largura e 0.30 metros de calado, essa lancha vem para revolucionar sua categoria, trazendo esportividade com motores de 90hp a 150hp, estabilidade e segurança com um desenho de casco arrojado para seus 8+1 passageiros.”",
          mestra198Text:
            "“Um dos nossos modelos mais consagrados, a Mestra 198 tem em sua essência a combinação de modernidade interna e tradicionalidade em seu casco, traduzindo ao cliente uma navegabilidade superior em sua categoria. Com 5.80 metros de comprimento, 2.25 metros de largura e 0.30 metros de calado, essa embarcação é a escolha perfeita para navegação em diversos ambientes. Construída para comportar motores de 100hp à 150hp, seu desempenho traz não apenas um DNA esportivo, mas também segurança e estabilidade aos seus 7+1 passageiros.”",
          mestra180Text:
            "“Em linha de produção desde 2010, a Mestra 180 é um modelo muito consagrado no mercado brasileiro. Com 5.15 metros de comprimento, 2.20 metros de largura e 0.30 metros de calado, essa embarcação é frequentemente escolhida como o barco de entrada para o mundo náutico. Construída com o intuito de obter a melhor relação entre peso e potência, essa embarcação comporta motores de popa de 60Hp a 115Hp, tanque de combustível de 100 litros fazendo com que seu desempenho seja superior a qualquer lancha dessa categoria. Seu espaço interno é homologado para acomodar 7+1 passageiros.” ”",
          mestra160Text:
            "“A Mestra 160 é o nosso primeiro barco desenhado para pesca, que também atende objetivos recreativos. Denominada como Mestra Fish, esse modelo une a navegabilidade  presente em todas as nossas embarcações, o conforto e a elegância característicos da marca, bem como todas as necessidades de um pescador. Equipado com bancos giratórios e removíveis para facilitar a atividade do esporte, esse barco tem 4.74 metros de comprimento, 1.70 metros de largura e 0.37 metros de calado, a palavra que melhor define essa embarcação é “versatilidade”. Portanto, para aqueles que buscam uma embarcação de alta qualidade e com um espaço otimizado, a Mestra 160 é a melhor opção, comportando motores de 40hp a 60hp e homologado para 1+3 pessoas.” ",
          motorizacao200a280: "de 200 à 280 HP",
          motorizacao100a220: "de 100 à 220 HP",
          motorizacao90a150: "de 90 à 150 HP",
          motorizacao60a135: "de 60 à 135 HP",
          motorizacao40a60: "de 40 à 60 HP",
          //Model page texto ao lado das imagens
          painelNavegacao: "Painel de navegação customizável, visando o melhor conforto ao capitão.",
          sistemaAncora: "Sistema de âncora eletrônico moderno, com acabamento em inox.",
          sistemaSom: "Sistema de som de alta tecnologia, marinizado e customizável, visando proporcionar a melhor experiência aos clientes Mestra.",
          sincronizacaoOpcional: "Sincronização opcional de músicas e cores, para transformar sua Mestra em um ambiente perfeito para comemoraçõe",
          acabamentoAcrilico: "Acabamentos em acrílico e uma iluminação indireta visando a segurança dos passageiros.",
          portaCopos: "Porta copos em aço inox seguimento padrão de nossa marca, opcional de iluminação multicolor.",
          estofamento: "Estofamento produzido a mão e desenvolvido por nossos especialistas, a fim de suprir as necessidades ergonômicas de nossos passageiros. ",
          pisosDiversasCores: "Oferecemos dois tipos de piso em diversas cores, Mestra Hardwood e Mestra Softwood, ambos visam a comodidade bem como a segurança de nossos passageiros ao circular no espaço interno.",
          cockpitCapitao: "Cockpit do capitão inteiramente adaptável, visando suprir diversas estaturas. Painel de comando de alta tecnologia, com relógios analógicos e eletrônicos, seguindo nosso acabamento em inox.",
          desfruteNavegar: "Desfrute de navegar no que há de melhor no mercado, em termos de qualidade, segurança e lazer.",
          videos: "Vídeos",
          modelPage: "Página do modelo"
        },
      },
    },
  });

export default i18n;
