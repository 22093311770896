
import image1 from './images/fourstroke150.png'
import image2 from './images/fourstroke150_2.png'
import image3 from "./images/mercury_pro_xs.jfif"
import image4 from "./images/mercury150.png"
export const Mercury150hp = [
    {
      image:
        image4,
        description: "Mercury 150 HP 4T CT"
    },
  ];
  