import styled from "styled-components";
import {
  home322,
  home292,
  home240,
  home230,
  home200,
  home212,
  home160,
  home180,
  home292Mobile,
  home322Mobile,
  home160Mobile,
  home200Mobile,
  home212Mobile,
  home230Mobile,
  home240Mobile,
  home180Mobile,
} from './Images'

export const Root = styled.div`
    /* Hide scrollbar for modern web browsers */
    ::-webkit-scrollbar {
    display: none;
    }

    /* Hide scrollbar for Internet Explorer and Microsoft Edge */
    -ms-overflow-style: none;
`;

export const Container = styled.div`
  height: 100vh;
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  position: relative;
  /* Remove default scrollbar styles */
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Microsoft Edge */

  /* Hide scrollbar for modern web browsers */
  ::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 900px) {
    overflow: overlay;
    ::-webkit-scrollbar {
      background: transparent;
      width: 4px;
    }
    ::-webkit-scrollbar-thumb {
      background: transparent;
    }
  }
`;


export const Wrapper = styled.div`
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  height: 100vh;
`;

export const Spacer = styled.div`
  height: 15vh;
  background: white;
  }
`;
export const Content = styled.div`
  position: relative;
  height: 100vh;
  scroll-snap-align: start;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  margin-top: 16.5vh;

  :nth-child(1) {
    background-image: url(${home322});
    background-size: cover;
    background-position: center center;
    @media (max-width: 1024px) {
      background-size: cover;
      background-repeat: no-repeat;
      background-image: url(${home322Mobile});
      background-position: center center;
    }
  }
  :nth-child(2) {
    background-image: url(${home292});
    background-size: cover;
    background-position: center center;
    @media (max-width: 1024px) {
      background-size: cover;
      background-repeat: no-repeat;
      background-image: url(${home292Mobile});
      background-position: center center;
    }
  }
  :nth-child(3) {
    background-image: url(${home240});
    background-size: cover;
    background-position: center center;
    @media (max-width: 1024px) {
      background-size: cover;
      background-repeat: no-repeat;
      background-image: url(${home240Mobile});
      background-position: center center;
    }
  }
  :nth-child(4) {
    background-position: center center;
    background-image: url(${home230});
    background-repeat: no-repeat;
    background-size: cover;
    @media (max-width: 1024px) {
      background-image: url(${home230Mobile});
      background-position: center center;
    }
  }
  :nth-child(5) {
    background-position: center center;
    background-image: url(${home200});
    background-repeat: no-repeat;
    background-size: cover;
    @media (max-width: 1024px) {
      background-image: url(${home200Mobile});
      background-position: center center;
    }
  }
  :nth-child(6) {
    background-position: center center;
    background-image: url(${home212});
    background-repeat: no-repeat;
    background-size: cover;
    @media (max-width: 1024px) {
      background-image: url(${home212Mobile});
      background-position: center center;
    }
  }
  :nth-child(7) {
    background-position: center center;
    background-image: url(${home180});
    background-repeat: no-repeat;
    background-size: cover;
    @media (max-width: 1024px) {
      background-image: url(${home180Mobile});
      background-position: center center;
    }
  }
  :nth-child(8) {
    background-position: center end;
      background-image: url(${home160});
    background-repeat: no-repeat;
    background-size: cover;
    @media (max-width: 1024px) {
      background-image: url(${home160Mobile});
      background-position: center center;
    }

  }
`;

export const Heading = styled.div`
margin-top: 16.5vh;
width: 100%;
text-align: center;
z-index: 11;
position: absolute;
> h1 {
  font-weight: 500;
  font-size: 40px;
  line-height: 48px;
  color: black;
}
> h2 {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #5c5e62;
}
`;

export const Buttons = styled.div`
height: 100vh;
bottom: 0;
position: absolute;
align-items: flex-end;
display: flex;
gap: 20px;
margin-bottom: 100px;
z-index: 10;

> button {
  background-color: black;
  color: #fff;
  opacity: 0.85;
  width: 260px;
  height: 39.8px;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.4px;
  text-transform: uppercase;

  padding: 13px 40px;
  border-radius: 20px;
  border: none;
  outline: 0;
  cursor: pointer;

  &.white {
    background: white;
    color: #1a1720;
  //   opacity: 0.65;

  }

  // & + button {
  //   margin: 10px 0 0;
  // }
}


@media (max-width: 600px) {
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 100px;
  gap: 1em;
  > button + button {
    margin: 5px 0 0px 0px;
  }
}
 @media (min-height: 568px) and (max-height: 799px) {
   flex-direction: column;
   justify-content: flex-end;
   margin-bottom: 100px;
   gap: 1em;
   > button + button {
     margin: 5px 0 0px 0px;
    
   }
 }
  @media only screen and (width:810px) and (height:1080px) and (orientation:portrait) {
    margin-bottom: 100px;
  }

    @media only screen and (width: 810px) and (height:1080px) and (orientation:landscape){
      flex-direction: column;
      margin-bottom: 100px;
      > button + button {
        margin: 0 0 0 10px;
      }
    }
   
  }
`;
export const Footer = styled.div`
  background-color: white;
  position: fixed;
  width: 100%;
  display: flex;
  justify-self: flex-end;
  justify-content: center;
  align-items: center;
  bottom: 0;
  left: 0;
  right: 0;
  
`;

export const ArrowDownDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-bottom: 20px;
    bottom: 20px;
`;

export const ArrowDownIcon = styled.div`
    animation: animateDown infinite 1.5s;
    height: 30px;
    width: 30px;
`;


export const ButtonArrow = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
`;

export const FooterDiv = styled.div`
width: '100%',
background-color: 'white',
display: 'flex',
justify-content: 'center',
align-items: 'center',
`;