
import image1 from "./images/mercury100hp.png"


export const Mercury100hp = [
    {
      image:
        image1,
        description: "DESCRIÇÃO AQUI SOBRE O PRODUTO"
    },
  ];
  