import React from 'react';
import ModelPage from '../../components/ModelPage/ModelPage';
import mestra240Modelo from '../../data/images/desktop/model240_desktop.jpg';
import mestra240ModeloMobile from '../../data/images/mobile/modelo240_mobile_v01.jpg';
import interior from '../../data/images/customLancha240/visao1Estofado.png';
import imgCarousel1 from '../../data/images/mestra240/carouselMestra240.jpg';
import imgCarousel2 from '../../data/images/mestra240/carouselMestra240_2.jpg';
import imgCarousel3 from '../../data/images/mestra240/carouselMestra240_3.jpg';
import imgCarousel4 from '../../data/images/mestra240/carouselMestra240_4.jpg';
import imgCarousel5 from '../../data/images/mestra240/carouselMestra240_5.jpg';
import imgCarousel6 from '../../data/images/mestra240/carouselMestra240_6.jpg';

import imgDetails1 from '../../data/images/details230_240_2.jfif';
import imgDetails2 from '../../data/images/details230_240.jfif';
import imgDetails3 from '../../data/images/image00018.jpeg';
import imgDetails4 from '../../data/images/image00029.jpeg';
import imgDetails5 from '../../data/images/image00013.jpeg';
import imgDetails6 from '../../data/images/image00009.jpeg';
import imgDetails7 from '../../data/images/image00017.jpeg';
import imgDetails8 from '../../data/images/image00014.jpeg';
import imgDetails9 from '../../data/images/image00011.jpeg';
import imgDetails10 from '../../data/images/image00016.jpeg';

import model from '../../data/images/customLancha240/fotoBasePNG.png';

const ModelMestra240 = () => {
  const fichaProps = {
    mestra292: false,
    mestra322: false,
    mestra240: true,
    mestra230: false,
    mestra222: false,
    mestra212: false,
    mestra200: false,
    mestra198: false,
    mestra180: false,
    mestra160: false,
  };


  const imagens = [
    imgCarousel1,
    imgCarousel2,
    imgCarousel3,
    imgCarousel4,
    imgCarousel5,
    imgCarousel6,

  ]

  return (
    <ModelPage
      lastSectionWhite={true}
      mestra240
      mestra240Text
      fichaProps={fichaProps}
      length="7,00 M"
      width="2,45 M"
      draught="0,38 M"
      img={mestra240Modelo}
      mobileImage={mestra240ModeloMobile}
      desktopImage={mestra240Modelo}
      imgInterior={interior}
      carouselImages={imagens}
      imgDetails1={imgDetails1}
      imgDetails2={imgDetails2}
      imgDetails3={imgDetails3}
      imgDetails4={imgDetails4}
      imgDetails5={imgDetails5}
      imgDetails6={imgDetails6}
      imgDetails7={imgDetails7}
      imgDetails8={imgDetails8}
      imgDetails9={imgDetails9}
      imgDetails10={imgDetails10}
      modelName="Mestra 240"
      modelURL="mestra240"
      model={model}
    />
  );
};

export default ModelMestra240;
