/* eslint-disable jsx-a11y/alt-text */
import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Button, Typography } from '@mui/material';
import { useMediaQuery } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import NewNavbar from '../Navbar/Navbar';
import theme from '../../theme/Theme';
import Carousel from 'react-elastic-carousel';
import ItemCarousel from './ItemCarousel';
import './carouselStyle.css';
import { BiSupport } from 'react-icons/bi';
import ModalButton from '../ModalButton/ModalButton';
import Newfooter from '../Footer/Footer';
import '../../App.css';
import DetailsSection from './DetailsSection';
import DetailsSectionMobile from './DetailsSectionMobile';

import FichaContainer from './FichaContainer';

const useStyles = makeStyles({
  root: {
    position: 'relative',
    minHeight: '100vh',
    backgroundColor: "black"
  },
  contentWrap: {
    width: "100%",
    backgroundColor: "black",
    '& > :nth-last-child(2)': {
      backgroundColor: 'white',
      margin: 0
    },
  },
  heroContainer: {
    position: 'relative',
    width: '100%',
    height: '100vh',
    //backgroundImage: (props) => `url(${})`,
    backgroundSize: 'cover',
    // backgroundPosition: (props) => (props.mestra160 ? 'center' : 'center'),
    backgroundPosition: '25%, center',
    //mobile
    [theme.breakpoints.down('md')]: {
      width: '200%',
      backgroundSize: 'contain',
    },

    //mobile
    [theme.breakpoints.down('md')]: {
      backgroundPosition: 'center center',
      // minHeight: "50vh",
      height: '100vh',
      width: '100%',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      top: '0',
      left: '0',
      zIndex: '1',
    },
  },
  title: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: '10%',
    //mobile
    [theme.breakpoints.down('sm')]: {
      marginTop: '12vh',
      top: 0,
    },
  },

  detailsContainer: {
    display: 'flex',
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'flex-end',
    position: 'absolute',
    width: '100%',
    gap: '100px',
    padding: '50px',

    //mobile
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      alignItems: 'center',
      gap: '0px',
      // padding: "10px",
      fontSize: '14px',
      padding: '0px 0px 25% 0px',
    },
    '@media (min-height: 100px) and (max-height:600px)': {
      padding: '0px 0px 10% 0px',
    },
    '@media (min-height: 768px) and (max-height:900px)': {
      padding: '0px 0px 5% 0px',
    },

  },
  detailsItems: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    //mobile
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '0px',
    },

  },
  detailsItemsButton: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    padding: '20px 0px 20px 0px',
  },

  interiorContainer: {
    backgroundColor: 'black',
    position: 'relative',
    width: '100%',
    height: '80vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    paddingTop: '10em',
    //mobile
    [theme.breakpoints.down('sm')]: {
      height: '80vh',
      paddingInline: '20px',
    },

  },
  interiorImg: {
    backgroundAttachment: 'scroll',
    backgroundColor: 'black',
    position: 'relative',
    width: '100%',
    height: '50vh',
    backgroundImage: (props) => `url(${props.imgInterior})`,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    //mobile
    [theme.breakpoints.down('sm')]: {
      width: '80%',
    },
  },
  interiorMask: {
    width: '100%',
    height: '100vh',
    position: 'absolute',
    // background: 'rgb(0,0,0)',
    background:
      'linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 35%, rgba(0,212,255,0) 100%)',
  },
  carouselBox: {
    width: '100%',
    height: '100vh',
    backgroundColor: 'black',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '15em',
    marginBottom: "15em",
    //mobile
    [theme.breakpoints.down('md')]: {
      padding: '20px',
      height: '50vh',
    },
    [theme.breakpoints.up('xl')]: {
      padding: '20px',
    },
  },
  carouselContainer: {
    width: '80%',
    height: '50%',
    backgroundColor: 'black',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    //mobile
    [theme.breakpoints.down('md')]: {
      width: '100vw',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '0',
    },
    [theme.breakpoints.up('xl')]: {
      width: '50%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',

    },
  },
  carouselImg: {
    width: '100%',
    height: '100%',
    //mobile
    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  gridItems: {
    width: '100%',
    height: '100vh',
    backgroundColor: 'black',
    display: 'flex',
    justifyContent: 'center',
    //alignItems:"center",
    //mobile
    [theme.breakpoints.down('md')]: {
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column-reverse',
    },
  },
  gridItemImg: {
    width: '40em',
    height: 'auto',
    //mobile
    [theme.breakpoints.down('md')]: {
      width: '20em',
    },
  },
  gridItemImgContainer1: {
    width: '10em',
    height: 'auto',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    //mobile
    [theme.breakpoints.down('md')]: {
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  gridItemImgContainer2: {
    width: '10em',
    height: 'auto',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    //mobile
    [theme.breakpoints.down('md')]: {
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  gridItem: {
    width: '50%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    //mobile
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  gridText: {
    width: '50%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',

    //mobile
    [theme.breakpoints.down('md')]: {
      width: '100%',
      fontSize: '15px',
    },
  },
  lastBox: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

  },
  lastBoxImgMainContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      eight: '100%',
      width: '100%',
      gap: 0,
    },
  },

  lastBoxDetails: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '20px',
    [theme.breakpoints.down('md')]: {
      height: '50%',
      fontSize: '15px',
    },
  },
  lastBoxImgContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      height: '50%',
      justifyContent: 'center',
      alignItems: 'flex-start',
      marginTop: 0
    },
  },
  lastBoxImg: {
    width: '50em',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    //mobile
    [theme.breakpoints.down('md')]: {
      width: '25em',
    },
  },
  detailsBox: {
    display: 'flex',
    width: '100%',
    backgroundColor: 'black',
    justifyContent: 'center',
    alignItems: 'center',
  },
  detailsBoxFirstImg: {
    display: 'flex',
    width: '100%',
    backgroundColor: 'black',
    justifyContent: 'flex-end',
    alignItems: 'center',
    //mobile
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
  },
  detailsBoxSecondImg: {
    display: 'flex',
    width: '100%',
    backgroundColor: 'black',
    justifyContent: 'flex-start',
    alignItems: 'center',
    //mobile
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
  },
  buttonContainer: {
    zIndex: '3',
    position: 'fixed',
    bottom: '20px',
    right: '50px',
    gap: '30px',
    margin: '10px',
    padding: '0',
    //mobile
    [theme.breakpoints.down('md')]: {
      bottom: '10px',
      right: '20px',
      gap: '30px',
      margin: '10px',
    },
  },
  modalButton: {
    borderRadius: '50%',
    border: 'none',
    height: ' 50px',
    width: '50px',
    color: 'black',
    cursor: 'pointer',
    backgroundColor: 'white',
    boxShadow:
      'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px',
    padding: ' 5px',
  },
  boxTextContainer: {
    display: 'flex',
    width: '100%',
    height: '100vh',
    backgroundColor: 'black',
    color: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    // padding: '300px',
    //mobile
    [theme.breakpoints.down('md')]: {
      paddingTop: '5em',
      paddingBottom: '1em',
      height: '100vh',
    },
  },
  boxText: {
    width: '80%',
    //mobile
    [theme.breakpoints.down('md')]: {
      textAlign: 'start',
      padding: '10px',
    },
  },

  fichaContainer: {
    display: 'flex',
    width: '100%',
    backgroundColor: 'black',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    paddingTop: (props) => (props.mestra322 || props.mestra292 ? '20em' : 0),

    [theme.breakpoints.down('md')]: {
      paddingTop: (props) => (props.mestra322 || props.mestra292 ? '5em' : 0),
    }
  },
  labelsDetails: {
    display: 'flex',
    gap: '100px',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      width: '100%',
      marginLeft: '20px',
      marginRight: '20px',
      justifyContent: 'space-evenly',
      alignItems: 'space-evenly',
      gap: '0px',

    },
  },
});
const ModelPage = (props) => {
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const [navbarColor, setNavbarColor] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const imageUrl = windowWidth <= 900 ? props.mobileImage : props.img;
  const classes = useStyles(props);

  const handleClose = () => setOpen(false);
  const id = props.modelURL;
  const detailsMobileVersion = useMediaQuery(theme.breakpoints.down('md'));

  const changeBackground = () => {
    if (window.scrollY >= 250) {
      setNavbarColor(true);
    } else {
      setNavbarColor(false);
    }
  };

  useEffect(() => {
    changeBackground();
    // adding the event when scroll change background
    window.addEventListener('scroll', changeBackground);
  });

  return (
    <div className={classes.root}>
      <div className={classes.contentWrap}>
        <NewNavbar
          backgroundColor={navbarColor ? 'white' : 'transparent'}
          position="fixed"
        />
        {/* Botão Modal */}
        <div className={classes.buttonContainer}>
          <BiSupport
            className={classes.modalButton}
            onClick={() => setOpen(true)}
          />
        </div>
        {/* Modal de contato */}
        <ModalButton contact open={open} handleClose={handleClose} />
        {/* Seção do Hero */}
        <Box
          className={classes.heroContainer}
          style={{
            backgroundPosition: props.mestra240 ? 'bottom, center' : '25%, center',
            backgroundBlendMode:
              props.mestra240 ||
                props.mestra198 ||
                props.mestra160 ||
                props.mestra322
                ? 'multiply'
                : null,
            backgroundImage: props.mestra240 ||
              props.mestra198 ||
              props.mestra160 ||
              props.mestra322
              ? `linear-gradient(180deg, rgba(240,240,240,0) 0%, rgba(255,255,255,0) 66%, rgba(134,139,138,0.3029586834733894) 75%, rgba(0,0,0,1) 100%, rgba(0,0,0,0) 100%), url(${imageUrl})`
              : props.mestra292 ? `linear-gradient(180deg, rgba(240,240,240,0) 0%, rgba(255,255,255,0) 66%, rgba(134,139,138,0.3029586834733894) 75%, rgba(0,0,0,1) 100%, rgba(0,0,0,0) 100%), url(${imageUrl})` : null,

          }}
          container
          alignItems="center"
          justifyContent="center"
        >
          <Box p={5} className={classes.title}>
            <h1
              color="black"
              style={{
                fontSize: detailsMobileVersion ? '34px' : '42px',
                fontWeight: 'bold',
                letterSpacing: '0.6px',
              }}
            >
              {props.modelName}
            </h1>
          </Box>
          <div className={classes.detailsContainer}>
            <div className={classes.labelsDetails}>
              <div className={classes.detailsItems}>
                <Typography
                  variant={detailsMobileVersion ? 'h5' : 'h3'}
                  color="white"
                >
                  {props.length}
                </Typography>
                <Typography variant="body1" color="white">
                  {t('comprimento')}
                </Typography>
              </div>
              <div className={classes.detailsItems}>
                <Typography
                  variant={detailsMobileVersion ? 'h5' : 'h3'}
                  color="white"
                >
                  {props.width}
                </Typography>
                <Typography variant="body1" color="white">
                  {t('boca')}
                </Typography>
              </div>
              <div className={classes.detailsItems}>
                <Typography
                  variant={detailsMobileVersion ? 'h5' : 'h3'}
                  color="white"
                >
                  {props.draught}
                </Typography>
                <Typography variant="body1" color="white">
                  {t('calado')}
                </Typography>
              </div>
            </div>
            <div className={classes.detailsItemsButton}>
              {props.mestra292 || props.mestra322 ? <Button
                variant="outlined"
                sx={{
                  border: '4px solid white',
                  width: '250px',
                  color: 'white',
                  borderRadius: '20px',
                  '&:hover': {
                    backgroundColor: 'white',
                    color: 'black',
                    border: '4px solid white',
                  },
                }}
                onClick={() => (window.location.href = `/contact`)}
              >
                {t('solicitarOrcamento')}
              </Button> : <Button
                variant="outlined"
                sx={{
                  border: '4px solid white',
                  width: '250px',
                  color: 'white',
                  borderRadius: '20px',
                  '&:hover': {
                    backgroundColor: 'white',
                    color: 'black',
                    border: '4px solid white',
                  },
                }}
                onClick={() => {
                  window.location.href = `/lanchas/${id}`;
                }}
              >
                {t('customizar')}
              </Button>}
            </div>
          </div>
        </Box>
        {props.mestra292 || props.mestra322 ? null :
          <>
            <Box className={classes.interiorContainer}>
              <Typography
                variant={detailsMobileVersion ? 'h4' : 'h3'}
                color="white"
                style={{ marginBottom: '2em' }}
              >
                {t('interiorPersonalizavel')}
              </Typography>
              <Box className={classes.interiorImg} ></Box>
            </Box>
            <Box maxWidth="sm" className={classes.boxTextContainer} >
              <Box className={classes.boxText}>
                <Typography
                  variant={detailsMobileVersion ? 'body1' : 'h5'}
                  style={{ fontStyle: 'italic', textAlign: 'left', letterSpacing: "1px" }}
                >
                  {props.mestra240Text ? t('mestra240Text') : ''}
                  {props.mestra230Text ? t('mestra230Text') : ''}
                  {props.mestra222Text ? t('mestra222Text') : ''}
                  {props.mestra212Text ? t('mestra212Text') : ''}
                  {props.mestra200Text ? t('mestra200Text') : ''}
                  {props.mestra198Text ? t('mestra198Text') : ''}
                  {props.mestra180Text ? t('mestra180Text') : ''}
                  {props.mestra160Text ? t('mestra160Text') : ''}
                </Typography>
              </Box>
            </Box>
          </>}
        <Box className={classes.fichaContainer} >
          <FichaContainer
            fichaProps={props.fichaProps}
          />
        </Box>
        <Box p={5} className={classes.carouselBox} style={{ backgroundColor: "black", paddingBottom: '5em' }}>
          <div className={classes.carouselContainer}>
            <Carousel outerSpacing={0} itemPadding={[0, 0]}>
              {props.carouselImages.map((item) => {
                return (
                  <ItemCarousel>
                    <img className={classes.carouselImg} src={item} />
                  </ItemCarousel>
                )
              })}
            </Carousel>
          </div>
        </Box>
        <div style={{ paddingBottom: "2em", backgroundColor: "black", }}>
          {detailsMobileVersion ? (
            <div style={{ width: '100%' }}>
              <DetailsSectionMobile
                imgDetails1={props.imgDetails1}
                imgDetails2={props.imgDetails2}
                imgDetails3={props.imgDetails3}
                imgDetails4={props.imgDetails4}
                imgDetails5={props.imgDetails5}
                imgDetails6={props.imgDetails6}
                imgDetails7={props.imgDetails7}
                imgDetails8={props.imgDetails8}
                imgDetails9={props.imgDetails9}
                imgDetails10={props.imgDetails10}
              />
            </div>
          ) : (
            <DetailsSection
              imgDetails1={props.imgDetails1}
              imgDetails2={props.imgDetails2}
              imgDetails3={props.imgDetails3}
              imgDetails4={props.imgDetails4}
              imgDetails5={props.imgDetails5}
              imgDetails6={props.imgDetails6}
              imgDetails7={props.imgDetails7}
              imgDetails8={props.imgDetails8}
              imgDetails9={props.imgDetails9}
              imgDetails10={props.imgDetails10}
            />
          )}
        </div>
        {props.mestra322 || props.mestra292 ? null :
          <Box className={classes.lastBox}>
            <div style={{ width: "100%", height: "100vh", backgroundColor: "white", margin: "0", paddingInline: "5em" }}>
              <Grid container className={classes.lastBoxImgMainContainer} style={{ height: "100%", backgroundColor: "white" }}>
                <Grid item xs={12} md={4}>
                  <Box className={classes.lastBoxDetails}>
                    <Typography variant="h4">{props.modelName}</Typography>
                    <Button
                      variant="outlined"
                      sx={{
                        width: '200px',
                        border: '2px solid black',
                        borderRadius: '20px',
                        '&:hover': {
                          width: '200px',
                          backgroundColor: 'black',
                          color: 'white',
                          border: '2px solid black',
                        },
                      }}
                      onClick={() => {
                        window.location.href = `/lanchas/${id}`;
                      }}
                    >
                      {t('peçaAgora')}
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={12} md={8} className={classes.lastBoxImgContainer}>
                  <img
                    src={props.model}
                    className={classes.lastBoxImg}
                    style={{ userSelect: 'none' }}
                  />
                </Grid>
              </Grid>
            </div>
          </Box>}
      </div>
      <div style={{ backgroundColor: props.lastSectionWhite ? "white" : "transparent", width: "100%", height: "fit-content", bottom: '0', paddingTop: "10em" }}>
        <Newfooter modelPage />
      </div>
    </div>
  );
};

export default ModelPage;

