import React from 'react';

import ModelPage from '../../components/ModelPage/ModelPage';
import mestra292Modelo from '../../data/images/MESTRA292/mestra292_modelo.png';
import mestra292ModeloMobile from '../../data/images/mobile/Mobile-Lancha-292-Modelo.png';
import interior from '../../data/images/customLancha240/visao1Estofado.png';
import imgCarousel1 from '../../data/images/MESTRA292/mestra292_1.jpg';
import imgCarousel2 from '../../data/images/MESTRA292/mestra292_2.jpg';
import imgCarousel3 from '../../data/images/MESTRA292/mestra292_3.jpg';
import imgCarousel4 from '../../data/images/MESTRA292/mestra292_4.jpg';
import imgCarousel5 from '../../data/images/MESTRA292/mestra292_5.jpg';
import imgCarousel6 from '../../data/images/MESTRA292/mestra292_6.jpg';
import imgCarousel7 from '../../data/images/MESTRA292/mestra292_7.jpg';
import imgCarousel8 from '../../data/images/MESTRA292/mestra292_8.jpg';
import imgDetails1 from '../../data/images/details230_240_2.jfif';
import imgDetails2 from '../../data/images/details230_240.jfif';


import model from '../../data/images/customLancha240/fotoBasePNG.png';

const ModelMestra292 = () => {
    const imagens = [
        imgCarousel1,
        imgCarousel2,
        imgCarousel3,
        imgCarousel4,
        imgCarousel5,
        imgCarousel6,
        imgCarousel7,
        imgCarousel8
    ]

    const fichaProps = {
        mestra292: true, // Assuming you want to render data for mestra292
        mestra322: false,
        mestra240: false,
        mestra230: false,
        mestra222: false,
        mestra212: false,
        mestra200: false,
        mestra198: false,
        mestra180: false,
        mestra160: false,
    };

    return (
        <ModelPage
            fichaProps={fichaProps}
            mestra292
            mestra240Text
            length="8,80 M"
            width="2,80 M"
            draught="0,52 M"
            img={mestra292Modelo}
            mobileImage={mestra292ModeloMobile}
            desktopImage={mestra292Modelo}
            imgInterior={interior}
            carouselImages={imagens}
            imgDetails1={imgDetails1}
            imgDetails2={imgDetails2}
            modelName="Mestra 292"
            modelURL="mestra292"
            model={model}
        />
    );
};

export default ModelMestra292;
