/* eslint-disable import/no-anonymous-default-export */
import React from 'react';
import CustomPage from '../../components/CustomPage/CustomPage';
import { useTranslation } from 'react-i18next';

// CASCO E CORES
import fotoCasco from './../../data/images/customLancha200/fotoBasePNG.png';
import visao1 from './../../data/images/customLancha200/visao1.png';
import visao2 from './../../data/images/customLancha200/visao2.png';

// cores default
import colorRed from '../../data/images/customLancha200/cascoVermelho.png';
import colorDarkGray from '../../data/images/customLancha200/cascoCinzaEscuro.png';
import colorGray from '../../data/images/customLancha200/cascoCinza.png';
import colorGreen from '../../data/images/customLancha200/cascoVerde.png';
import colorBlack from '../../data/images/customLancha200/cascoPreto.png';
import colorBlue from '../../data/images/customLancha200/cascoAzul.png';
import colorWhite from '../../data/images/customLancha200/cascoBranco.png';

// cores casco visao 1
import visao1Vermelho from './../../data/images/customLancha200/visao1CascoVermelho.png';
import visao1Azul from './../../data/images/customLancha200/visao1CascoAzul.png';
import visao1Verde from './../../data/images/customLancha200/visao1CascoVerde.png';
import visao1CinzaEscuro from './../../data/images/customLancha200/visao1CascoCinzaEscuro.png';
import visao1Preto from './../../data/images/customLancha200/visao1CascoPreto.png';
import visao1Cinza from './../../data/images/customLancha200/visao1CascoCinza.png';
import visao1Branco from './../../data/images/customLancha200/visao1CascoBranco.png';

//cores casco visao 2
import visao2Vermelho from './../../data/images/customLancha200/visao2CascoVermelho.png';
import visao2Azul from './../../data/images/customLancha200/visao2CascoAzul.png';
import visao2Verde from './../../data/images/customLancha200/visao2CascoVerde.png';
import visao2CinzaEscuro from './../../data/images/customLancha200/visao2CascoCinzaEscuro.png';
import visao2Preto from './../../data/images/customLancha200/visao2CascoPreto.png';
import visao2Cinza from './../../data/images/customLancha200/visao2CascoCinza.png';
import visao2Branco from './../../data/images/customLancha200/visao2CascoBranco.png';

// ESTOFADO/PISO
// visao default
import interior from '../../data/images/customLancha200/interior.png';
import seatBlue from '../../data/images/customLancha200/seatBlue.png';
import seatWhite from '../../data/images/customLancha200/seatBranco.png';
import seatTerracota from '../../data/images/customLancha200/seatTerracotta.png';
import seatGray from '../../data/images/customLancha200/seatCinza.png';
import seatCaramelo from '../../data/images/customLancha200/seatCaramelo.png';
import seatBegeClaro from '../../data/images/customLancha200/seatBege.png';

import pisoBege from '../../data/images/customLancha200/pisoBege.png';
import pisoCinza from '../../data/images/customLancha200/pisoCinza.png';
import pisoCaramelo from '../../data/images/customLancha200/pisoCaramelo.png';
import pisoTerracotta from '../../data/images/customLancha200/pisoTerracotta.png';
import pisoTerracottaHW from '../../data/images/customLancha200/pisoTerracottaHW.png';
import pisoAreiaHW from '../../data/images/customLancha200/pisoAreiaHW.png';
import pisoCinzaHW from '../../data/images/customLancha200/pisoCinzaHW.png';
import pisoCarameloHW from '../../data/images/customLancha200/pisoCarameloHW.png';

// visao 1 interior
import visao1Estofado from '../../data/images/customLancha200/visao1Estofado.png';
import estofadoTerracotta2 from '../../data/images/customLancha200/visao1EstofadoTerracotta.png';
import estofadoBranco2 from '../../data/images/customLancha200/visao1EstofadoBranco.png';
import estofadoAzul2 from '../../data/images/customLancha200/visao1EstofadoAzul.png';
import estofadoCinza2 from '../../data/images/customLancha200/visao1EstofadoCinza.png';
import estofadoCaramelo2 from '../../data/images/customLancha200/visao1EstofadoCaramelo.png';
import estofadoBege2 from '../../data/images/customLancha200/visao1EstofadoBege.png';

import pisoBege2 from '../../data/images/customLancha200/pisoBege2.png';
import pisoCinza2 from '../../data/images/customLancha200/pisoCinza2.png';
import pisoCaramelo2 from '../../data/images/customLancha200/pisoCaramelo2.png';
import pisoTerracotta2 from '../../data/images/customLancha200/pisoTerracotta2.png';

import pisoTerracottaHW2 from '../../data/images/customLancha200/pisoTerracottaHW2.png';
import pisoAreiaHW2 from '../../data/images/customLancha200/pisoAreiaHW2.png';
import pisoCinzaHW2 from '../../data/images/customLancha200/pisoCinzaHW2.png';
import pisoCarameloHW2 from '../../data/images/customLancha200/pisoCarameloHW2.png';

//visao 2 interior
import visao2Estofado from '../../data/images/customLancha200/visao2Estofado.png';
import pisoBege3 from '../../data/images/customLancha200/pisoBege3.png';
import pisoCinza3 from '../../data/images/customLancha200/pisoCinza3.png';
import pisoCaramelo3 from '../../data/images/customLancha200/pisoCaramelo3.png';
import pisoTerracotta3 from '../../data/images/customLancha200/pisoTerracotta3.png';
import pisoTerracottaHW3 from '../../data/images/customLancha200/pisoTerracottaHW3.png';
import pisoAreiaHW3 from '../../data/images/customLancha200/pisoAreiaHW3.png';
import pisoCinzaHW3 from '../../data/images/customLancha200/pisoCinzaHW3.png';
import pisoCarameloHW3 from '../../data/images/customLancha200/pisoCarameloHW3.png';

//cor do casco da visao 2 do estofado
import interiorCascoAzul3 from '../../data/images/customLancha200/interiorCascoAzul3.png';
import interiorCascoBranco3 from '../../data/images/customLancha200/interiorCascoBranco3.png';
import interiorCascoVerde3 from '../../data/images/customLancha200/interiorCascoVerde3.png';
import interiorCascoVermelho3 from '../../data/images/customLancha200/interiorCascoVermelho3.png';
import interiorCascoCinzaEscuro3 from '../../data/images/customLancha200/interiorCascoCinzaEscuro3.png';
import interiorCascoCinza3 from '../../data/images/customLancha200/interiorCascoCinza3.png';
import interiorCascoPreto3 from '../../data/images/customLancha200/interiorCascoPreto3.png';

import estofadoTerracotta3 from '../../data/images/customLancha200/estofadoTerracotta3.png';
import estofadoBranco3 from '../../data/images/customLancha200/estofadoBranco3.png';
import estofadoAzul3 from '../../data/images/customLancha200/estofadoAzul3.png';
import estofadoCinza3 from '../../data/images/customLancha200/estofadoCinza3.png';
import estofadoCaramelo3 from '../../data/images/customLancha200/estofadoCaramelo3.png';
import estofadoBege3 from '../../data/images/customLancha200/estofadoBege3.png';

import interiorCascoAzul2 from '../../data/images/customLancha180/interiorCascoAzul2.png';
import interiorCascoPreto2 from '../../data/images/customLancha180/interiorCascoPreto2.png';
import interiorCascoBranco2 from '../../data/images/customLancha180/interiorCascoBranco2.png';
import interiorCascoCinza2 from '../../data/images/customLancha180/interiorCascoCinza2.png';
import interiorCascoVermelho2 from '../../data/images/customLancha180/interiorCascoVermelho2.png';
import interiorCascoVerde2 from '../../data/images/customLancha180/interiorCascoVerde2.png';
import interiorCascoCinzaEscuro2 from '../../data/images/customLancha180/interiorCascoCinzaEscuro2.png';

//visao 3 interior
import visao3Estofado from '../../data/images/customLancha200/visao3Estofado.png';
import estofadoTerracotta4 from '../../data/images/customLancha200/estofadoTerracotta4.png';
import estofadoBranco4 from '../../data/images/customLancha200/estofadoBranco4.png';
import estofadoAzul4 from '../../data/images/customLancha200/estofadoAzul4.png';
import estofadoCinza4 from '../../data/images/customLancha200/estofadoCinza4.png';
import estofadoCaramelo4 from '../../data/images/customLancha200/estofadoCaramelo4.png';
import estofadoBege4 from '../../data/images/customLancha200/estofadoBege4.png';

import pisoBege4 from '../../data/images/customLancha200/pisoBege4.png';
import pisoCinza4 from '../../data/images/customLancha200/pisoCinzaHW4.png';
import pisoCaramelo4 from '../../data/images/customLancha200/pisoCaramelo4.png';
import pisoTerracotta4 from '../../data/images/customLancha200/pisoTerracotta4.png';
import pisoTerracottaHW4 from '../../data/images/customLancha200/pisoTerracottaHW4.png';
import pisoAreiaHW4 from '../../data/images/customLancha200/pisoAreiaHW4.png';
import pisoCinzaHW4 from '../../data/images/customLancha200/pisoCinzaHW4.png';
import pisoCarameloHW4 from '../../data/images/customLancha200/pisoCarameloHW4.png';

export default () => {
  const { t, i18n } = useTranslation();
  const itensSerie = [
    t("pegaMaoInox"),
    t("bocalCombustivel"),
    t("bocalAguaDoce"),
    t("portaCoposInox"),
    t("escadaDePopaInox"),
    t("kitTorneiraPia"),
    t("luzesNavegacao"),
    t("sistemaEletrico"),
    t("duchaPopa"),
    t("estofamentoCourvim"),
    t("paiosProa"),
  ]

  const fichaTecnica = [
    t('combustivelTanque') + "⠀100 L",
    t('tanqueAguaDoce') + "⠀45 L",
    t('pesoSemMotor') + "⠀550 KG",
    t('passageirosDiurnos') + "⠀1+7",
    t('motorizacaoPopa') + `⠀${t('motorizacao90a150')}`,
  ]

  return (
    <>
      <CustomPage
        mestra200
        pisoOptions
        mercury100hp
        coresCascoDefault={fotoCasco}
        visao1={visao1}
        visao2={visao2}
        colorRed={colorRed}
        colorBlue={colorBlue}
        colorGreen={colorGreen}
        colorDarkGray={colorDarkGray}
        colorGray={colorGray}
        colorBlack={colorBlack}
        colorWhite={colorWhite}
        // VISOES DA COR DO CASCO VERMELHA
        visaoCoresRed2={visao1Vermelho}
        visaoCoresRed3={visao2Vermelho}
        //VISOES DA COR DO CASCO AZUL
        visaoCoresBlue2={visao1Azul}
        visaoCoresBlue3={visao2Azul}
        //VISOES DA COR VERDE
        visaoCoresGreen2={visao1Verde}
        visaoCoresGreen3={visao2Verde}
        //VISOES DA COR DO CASCO CINZA
        visaoCoresGray2={visao1Cinza}
        visaoCoresGray3={visao2Cinza}
        //VISOES DA COR CINZA COR DO CASCO ESCURO
        visaoCoresDarkGray2={visao1CinzaEscuro}
        visaoCoresDarkGray3={visao2CinzaEscuro}
        //VISOES DA COR  COR DO CASCO PRETO
        visaoCoresBlack2={visao1Preto}
        visaoCoresBlack3={visao2Preto}
        // VISOES DA COR COR DO CASCO BRANCA
        visaoCoresWhite2={visao1Branco}
        visaoCoresWhite3={visao2Branco}
        //INTERIOR
        coresEstofadoDefault={interior}
        estofadoTerracota={seatTerracota}
        estofadoAzul={seatBlue}
        estofadoBranco={seatWhite}
        estofadoBegeClaro={seatBegeClaro}
        estofadoCaramelo={seatCaramelo}
        estofadoCinza={seatGray}
        estofadoBlue={seatBlue}
        pisoTerracotta={pisoTerracotta}
        pisoCinza={pisoCinza}
        pisoCaramelo={pisoCaramelo}
        pisoBege={pisoBege}
        pisoTerracottaHW={pisoTerracottaHW}
        pisoAreiaHW={pisoAreiaHW}
        pisoCinzaHW={pisoCinzaHW}
        pisoCarameloHW={pisoCarameloHW}
        //INTERIOR VISAO 1
        visao1Estofado={visao1Estofado}
        estofadoAzul2={estofadoAzul2}
        estofadoBegeClaro2={estofadoBege2}
        estofadoCaramelo2={estofadoCaramelo2}
        estofadoBranco2={estofadoBranco2}
        estofadoCinza2={estofadoCinza2}
        estofadoTerracotta2={estofadoTerracotta2}
        pisoTerracotta2={pisoTerracotta2}
        pisoCinza2={pisoCinza2}
        pisoCaramelo2={pisoCaramelo2}
        pisoBege2={pisoBege2}
        pisoTerracottaHW2={pisoTerracottaHW2}
        pisoAreiaHW2={pisoAreiaHW2}
        pisoCinzaHW2={pisoCinzaHW2}
        pisoCarameloHW2={pisoCarameloHW2}
        // INTERIOR VISAO 2
        visao2Estofado={visao2Estofado}
        interiorCascoAzul3={interiorCascoAzul3}
        interiorCascoVermelho3={interiorCascoVermelho3}
        interiorCascoBranco3={interiorCascoBranco3}
        interiorCascoCinzaEscuro3={interiorCascoCinzaEscuro3}
        interiorCascoCinza3={interiorCascoCinza3}
        interiorCascoPreto3={interiorCascoPreto3}
        interiorCascoVerde3={interiorCascoVerde3}
        estofadoTerracotta3={estofadoTerracotta3}
        estofadoBranco3={estofadoBranco3}
        estofadoAzul3={estofadoAzul3}
        estofadoCinza3={estofadoCinza3}
        estofadoBege3={estofadoBege3}
        estofadoCaramelo3={estofadoCaramelo3}
        pisoBege3={pisoBege3}
        pisoCinza3={pisoCinza3}
        pisoCaramelo3={pisoCaramelo3}
        pisoTerracotta3={pisoTerracotta3}
        pisoAreiaHW3={pisoAreiaHW3}
        pisoCinzaHW3={pisoCinzaHW3}
        pisoCarameloHW3={pisoCarameloHW3}
        // pisoAreiaHW3={pisoAreiaHW3}
        pisoTerracottaHW3={pisoTerracottaHW3}
        // INTERIOR VISAO 3
        visao3Estofado={visao3Estofado}
        estofadoTerracotta4={estofadoTerracotta4}
        estofadoBranco4={estofadoBranco4}
        estofadoAzul4={estofadoAzul4}
        estofadoCinza4={estofadoCinza4}
        estofadoBege4={estofadoBege4}
        estofadoCaramelo4={estofadoCaramelo4}
        pisoBege4={pisoBege4}
        pisoCinza4={pisoCinza4}
        pisoCaramelo4={pisoCaramelo4}
        pisoTerracotta4={pisoTerracotta4}
        pisoAreiaHW4={pisoAreiaHW4}
        pisoCinzaHW4={pisoCinzaHW4}
        pisoCarameloHW4={pisoCarameloHW4}
        // pisoAreiaHW4={pisoAreiaHW4}
        pisoTerracottaHW4={pisoTerracottaHW4}
        title="Mestra 200"
        mercury100="Mercury 100 HP 4T CT"
        mercury115="Mercury 115 HP 4T CT"
        mercury150="Mercury 150 HP 4T CT"
        // subtitle="Lancha de 16 pés"
        comprimento="5,80 M"
        boca="2,25 M"
        calado="0,30 M"
        li1={t('combustivelTanque')}
        li2={t('tanqueAguaDoce')}
        li3={t('pesoSemMotor')}
        li4={t('passageirosDiurnos')}
        li5={t('motorizacaoPopa')}
        combustivel="100 L"
        tanqueAguaDoce="45 L"
        peso="550 KG"
        pessoas="1+7"
        motorizacao={t('motorizacao90a150')}
        fichaTecnica={fichaTecnica}
        motorizacao100a150popa={
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
          >
            <p>Motorizacao de 100 a 150 hp popa</p>
            <p>- 4 tempos CT </p>
            <p> - 4 tempos CT PRO XS </p>
          </div>
        }
        motor="Mercury 50 HP Carburado"
        motor2="Mercury 60 HP 4 Tempos"
        itensSerie={itensSerie}
        itemFabrica1={t('motorizacaoFabricaSubtitle')}
        itemFabrica2={t('sistemaDirecao')}
        itemFabrica3={t('bateria')}
        itemFabrica4={t('bombaPorao')}
        itemFabrica5={t('chaveGeral')}
        itemFabrica6={t('automaticoBombaPorao')}
        itemFabrica7={t('bombaPressurizacao')}
        itemFabrica8={t('volante')}
        itemFabrica9={t('filtros')}
        itemFabrica10={t('relogioMontagem')}
      />
    </>
  );
};
