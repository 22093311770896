
import image1 from "./images/alphaOne.jpg"


export const MerCruiser250= [
    {
      image:
        image1,
        description: "DESCRIÇÃO AQUI SOBRE O PRODUTO"
        
    },
    {
      image:
        image1,
        description: "DESCRIÇÃO AQUI SOBRE O PRODUTO"
        
    },
    
  ];
  