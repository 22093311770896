import React from 'react';

import ModelPage from '../../components/ModelPage/ModelPage';
import mestra160Modelo from '../../data/images/desktop/model160_desktop.jpg';
import mestra160Mobile from '../../data/images/mobile/model198_mobile_v01.jpg';
import interior from '../../data/images/customLancha160/visao1Estofado.png';
import imgCarousel1 from '../../data/images/mestra160/CarouselMestra160.jpg';
import imgCarousel2 from '../../data/images/mestra160/CarouselMestra160_2.jpg';
import imgCarousel3 from '../../data/images/mestra160/CarouselMestra160_3.jpg';
import imgCarousel4 from '../../data/images/mestra160/CarouselMestra160_4.jpg';
import imgCarousel5 from '../../data/images/mestra160/CarouselMestra160_5.jpg';
import imgCarousel6 from '../../data/images/mestra160/CarouselMestra160_6.jpg';
import imgDetails1 from '../../data/images/details230_240_2.jfif';
import imgDetails2 from '../../data/images/details230_240.jfif';
import model from '../../data/images/customLancha160/fotoBasePNG.png';

const ModelMestra160 = () => {

  const fichaProps = {
    mestra292: false,
    mestra322: false,
    mestra240: false,
    mestra230: false,
    mestra222: false,
    mestra212: false,
    mestra200: false,
    mestra198: false,
    mestra180: false,
    mestra160: true,
  };

  const imagens = [
    imgCarousel1,
    imgCarousel2,
    imgCarousel3,
    imgCarousel4,
    imgCarousel5,
    imgCarousel6
  ]
  return (
    <ModelPage
      lastSectionWhite={true}
      mestra160
      mestra160Text
      fichaProps={fichaProps}
      length="4,74 M"
      width="1,70 M"
      draught="0,37 M"
      img={mestra160Modelo}
      mobileImage={mestra160Mobile}
      imgInterior={interior}
      carouselImages={imagens}
      imgDetails1={imgDetails1}
      imgDetails2={imgDetails2}
      modelName="Mestra 160"
      modelURL="mestra160"
      model={model}
    />
  );
};

export default ModelMestra160;
