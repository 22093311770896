/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from "react";
// import { SliderData } from './SliderData';
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";
import "./Carousel.css";

const ImageSlider = ({ slides }) => {
  const [current, setCurrent] = useState(0);
  const length = slides.length;

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  if (!Array.isArray(slides) || slides.length <= 0) {
    return null;
  }

  return (
    <section className="slider">
      {slides.map((slide, index) => {
        return (
          <>
            <div
              className={index === current ? "slide active" : "slide"}
              key={index}
              onClick={nextSlide}
            >
              <div className="arrows">
                <RiArrowLeftSLine className="left-arrow" onClick={prevSlide} />
                <RiArrowRightSLine
                  className="right-arrow"
                  onClick={nextSlide}
                />
              </div>
              {index === current && (
                <>
                  <div className="imageContainer">
                    <img
                      src={slide.image}
                      alt="travel image"
                      className="image"
                    />
                  </div>
                </>
              )}
            </div>
          </>
        );
      })}
    </section>
  );
};

export default ImageSlider;
