/* eslint-disable default-case */
/* eslint-disable no-fallthrough */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useCallback } from 'react';

import evaTechTerracotta from '../../data/evaTechColors/terracotta.PNG';
import evaTechBegeClaro from '../../data/evaTechColors/bege.PNG';
import evaTechCaramelo from '../../data/evaTechColors/caramelo.PNG';
import evaTechCinza from '../../data/evaTechColors/cinza.PNG';
// import evaTechAreia from '../../data/evaTechColors/areia.JPG';
// import evaTechCinzaEscuro from '../../data/evaTechColors/cinzaEscuro.JPG';
import hardWoodBegeClaro from '../../data/evaTechColors/hardWoodBegeClaro.PNG';
import hardWoodBegeEscuro from '../../data/evaTechColors/hardWoodBegeEscuro.png';
import hardWoodCinza from '../../data/evaTechColors/hardWoodCinza.png';
import hardWoodterracota from '../../data/evaTechColors/hardWoodterracota.png';
// COMPONENTES
import NewNavbar from '../Navbar/Navbar';
import ImageSlider from '../Carousel/ImageSlider';
import NewModal from '../NewModal/NewModal';
import Newfooter from '../Footer/Footer';

// ESTILOS
import '../Styles/customPage.css';

// MOTORES
import { Mercury40hp } from '../../data/motores/Mercury40hp';
import { Mercury50hp } from '../../data/motores/Mercury50hp';
import { Mercury60hp } from '../../data/motores/Mercury60hp';
import { Mercury100hp } from '../../data/motores/Mercury100hp';
import { Mercury115hp } from '../../data/motores/Mercury115hp';
import { Mercury150hp } from '../../data/motores/Mercury150hp';
import { MerCruiser250 } from '../../data/motores/MerCruiser250';
import { VolvoPenta280 } from '../../data/motores/VolvoPenta280';
import { MerCruiser200 } from '../../data/motores/MerCruiser200';


import LeftColumn from './LeftColumn';
import RightColumn from './RightColumn';

// CAROUSEL DOS MOTORES
const Carousel = ({ SliderColor }) => {
  return <ImageSlider slides={SliderColor} />;
};

const CustomPage = (props) => {
  // IMAGEM DO CAROUSEL DOS MOTORES QUE IRÁ APARECER AO CARREGAR A PÁGINA
  const [currentCarousel, setCarousel] = useState(
    (props.mercruiser250 && <Carousel SliderColor={MerCruiser250} />) ||
    (props.mercury60hp && <Carousel SliderColor={Mercury60hp} />) ||
    (props.mercury100hp && <Carousel SliderColor={Mercury100hp} />) ||
    (props.mercury115hp && <Carousel SliderColor={Mercury115hp} />)
    // (props.mercruiser60hp && <Carousel SliderColor={Mercury60hp} />)
  );

  const handleCarouselChange = useCallback((newVal) => {
    switch (newVal) {
      case 'MerCruiser250':
        setCarousel(<Carousel SliderColor={MerCruiser250} />);
        break;
      case 'Mercury115hp':
        setCarousel(<Carousel SliderColor={Mercury115hp} />);
        break;
      case 'Mercury150hp':
        setCarousel(<Carousel SliderColor={Mercury150hp} />);
        break;
      case 'Mercury100hp':
        setCarousel(<Carousel SliderColor={Mercury100hp} />);
        break;
      case 'Mercury60hp':
        setCarousel(<Carousel SliderColor={Mercury60hp} />);
        break;
      case 'Mercury40hp':
        setCarousel(<Carousel SliderColor={Mercury40hp} />);
        break;
      case 'Mercury50hp':
        setCarousel(<Carousel SliderColor={Mercury50hp} />);
        break;
      case 'MerCruiser200':
        setCarousel(<Carousel SliderColor={MerCruiser200} />);
        break;
      case 'VolvoPenta':
        setCarousel(<Carousel SliderColor={VolvoPenta280} />);
        break;
      default:
        setCarousel(
          props.mercury60hp && <Carousel SliderColor={Mercury60hp} />
        );
        setCarousel(
          props.mercruiser250 && <Carousel SliderColor={MerCruiser250} />
        );
    }
  }, []);

  //  SETTAR BORDA AZUL PARA O BOTÃO DAS OPÇÕES DE MOTORES
  const [clickMercruiser40, setClickMercruiser40] = useState(false);
  const [clickMercruiser50, setClickMercruiser50] = useState(false);
  const [clickMercruiser60, setClickMercruiser60] = useState(false);
  const [clickMercruiser100, setClickMercruiser100] = useState(false);
  const [clickMercruiser150, setClickMercruiser150] = useState(false);
  const [clickMercruiser115, setClickMercruiser115] = useState(false);
  const [clickMercruiser250, setClickMercruiser250] = useState(false);
  const [clickMercruiser200, setClickMercruiser200] = useState(false);
  const [clickVolvoPenta280, setClickVolvoPenta280] = useState(false);

  const [clickNone, setClickNone] = useState(false);

  // FIM DOS MOTORES

  //ESTADO DAS CORES DO CASCO
  const [visao, setVisao] = useState(0);
  const [cascoVisao, setCascoVisao] = useState('block');

  // ESTADO FUTURO  DOS PISOS
  const [piso, setPiso] = useState('none');

  //ESTADO DAS CORES DO ESTOFADO
  const [visaoEstofado, setVisaoEstofado] = useState('none');
  const [visaoEstofadoArray, setVisaoEstofadoArray] = useState(0);
  // SETTANDO AS CORES DO CASCO
  const ShowColorImage = (valor) => {
    setPiso('none');
    setVisaoEstofado('none');
    setCascoVisao('block');

    if (valor === 'blue') {
      setColuna(1);
      setColunaInteriorCasco(1);
    } else if (valor === 'black') {
      setColuna(2);
      setColunaInteriorCasco(2);
    } else if (valor === 'darkGray') {
      setColuna(3);
      setColunaInteriorCasco(3);
    } else if (valor === 'white') {
      setColuna(4);
      setColunaInteriorCasco(4);
    } else if (valor === 'red') {
      setColuna(5);
      setColunaInteriorCasco(5);
    } else if (valor === 'green') {
      setColuna(6);
      setColunaInteriorCasco(6);
    } else if (valor === 'gray') {
      setColuna(7);
      setColunaInteriorCasco(7);
    }
  };
  // SETTANDO AS CORES DO ESTOFADO
  const ShowColorEstofadoImage = (valor) => {
    setPiso('block');
    setCascoVisao('none');
    setVisaoEstofado('block');

    if (valor === 'terracota') {
      setColunaEstofado(1);
      setColunaExteriorEstofado(1);
    } else if (valor === 'white') {
      setColunaEstofado(2);
      setColunaExteriorEstofado(2);
    } else if (valor === 'blue') {
      setColunaEstofado(3);
      setColunaExteriorEstofado(3);
    } else if (valor === 'cinza') {
      setColunaEstofado(4);
      setColunaExteriorEstofado(4);
    } else if (valor === 'caramelo') {
      setColunaEstofado(5);
      setColunaExteriorEstofado(5);
    } else if (valor === 'begeClaro') {
      setColunaEstofado(6);
      setColunaExteriorEstofado(6);
    }
  };

  // SETTANDO AS CORES DO PISO
  const ShowColorPisoImage = (value) => {
    setVisaoEstofado('block');
    setCascoVisao('none');
    if (value === 'terracotta') {
      setColunaPiso(1);
      setColunaExteriorPiso(1);
    } else if (value === 'cinza') {
      setColunaPiso(2);
      setColunaExteriorPiso(2);
    } else if (value === 'caramelo') {
      setColunaPiso(3);
      setColunaExteriorPiso(3);
    } else if (value === 'bege') {
      setColunaPiso(4);
      setColunaExteriorPiso(4);
    } else if (value === 'terracottaHW') {
      setColunaPiso(5);
      setColunaExteriorPiso(5);
    } else if (value === 'areiaHW') {
      setColunaPiso(6);
      setColunaExteriorPiso(6);
    } else if (value === 'cinzaHW') {
      setColunaPiso(7);
      setColunaExteriorPiso(7);
    } else if (value === 'carameloHW') {
      setColunaPiso(8);
      setColunaExteriorPiso(8);
    }
  };

  // SETTANDO CAROUSEL DAS VISOES
  const visaoCascoIndices = (valor) => {
    var atual = visao + valor;
    var linhaMatriz = linha + valor;
    var atualEstofado = visaoEstofadoArray + valor;
    var linhaMatrizEstofado = linhaEstofado + valor;
    var linhaMatrizPiso = linhaPiso + valor;
    var linhaMatrizInteriorCasco = linhaInteriorCasco + valor;
    var linhaMatrizExteriorEstofado = linhaExteriorEstofado + valor;
    var linhaMatrizExteriorPiso = linhaExteriorPiso + valor;
    // var atualPiso = visaoPisoArray + valor;
    if (cascoVisao === 'block') {
      setVisao(atual);
      setLinha(linhaMatriz);
      setLinhaExteriorEstofado(linhaMatrizExteriorEstofado);
      setLinhaExteriorPiso(linhaMatrizExteriorPiso);
      if (atual >= arrayVisao.length) {
        setVisao(0);
        setLinha(0);
        setLinhaExteriorEstofado(0);
        setLinhaExteriorPiso(0);
        console.log('aumentando');
      }
      if (atual < 0) {
        setVisao(arrayVisao.length - 1);
        setLinha(arrayVisao.length - 1);
        setLinhaExteriorEstofado(arrayVisao.length - 1);
        setLinhaExteriorPiso(arrayVisao.length - 1);
        console.log('diminuindo');
      }
    }

    if (visaoEstofado === 'block') {
      setLinhaEstofado(linhaMatrizEstofado);
      setVisaoEstofadoArray(atualEstofado);
      setLinhaPiso(linhaMatrizPiso);
      setLinhaInteriorCasco(linhaMatrizInteriorCasco);

      if (atualEstofado >= arrayEstofadoVisao.length) {
        setVisaoEstofadoArray(0);
        setLinhaEstofado(0);
        setLinhaPiso(0);
        setLinhaInteriorCasco(0);
        console.log('aumentando');
      }

      if (atualEstofado < 0) {
        setVisaoEstofadoArray(arrayEstofadoVisao.length - 1);
        setLinhaEstofado(arrayEstofadoVisao.length - 1);
        setLinhaPiso(arrayEstofadoVisao.length - 1);
        setLinhaInteriorCasco(arrayEstofadoVisao.length - 1);
        console.log('diminuindo');
      }
      if (props.visao4Estofado === undefined && atualEstofado === 4) {
        setVisaoEstofadoArray(0);
        setLinhaEstofado(0);
        setLinhaPiso(0);
        setLinhaInteriorCasco(0);
        console.log('diminuindo');
      }
      if (props.visao3Estofado === undefined && atualEstofado === 3) {
        setVisaoEstofadoArray(4);
        setLinhaEstofado(4);
        setLinhaPiso(4);
        setLinhaInteriorCasco(4);
        console.log('diminuindo');
      }
      if (props.visao2Estofado === undefined && atualEstofado === 2) {
        setVisaoEstofadoArray(0);
        setLinhaEstofado(0);
        setLinhaPiso(0);
        setLinhaInteriorCasco(0);
        console.log('diminuindo');
      }
    }
  };

  // ONLOAD IMAGES
  const [loaded, setLoaded] = useState(false);

  const matrizCores = [
    [
      '',
      props.colorBlue,
      props.colorBlack,
      props.colorDarkGray,
      props.colorWhite,
      props.colorRed,
      props.colorGreen,
      props.colorGray,
    ],
    [
      '',
      props.visaoCoresBlue2,
      props.visaoCoresBlack2,
      props.visaoCoresDarkGray2,
      props.visaoCoresWhite2,
      props.visaoCoresRed2,
      props.visaoCoresGreen2,
      props.visaoCoresGray2,
    ],
    [
      '',
      props.visaoCoresBlue3,
      props.visaoCoresBlack3,
      props.visaoCoresDarkGray3,
      props.visaoCoresWhite3,
      props.visaoCoresRed3,
      props.visaoCoresGreen3,
      props.visaoCoresGray3,
    ],
  ];

  const arrayVisao = [props.coresCascoDefault, props.visao1, props.visao2];
  const [coluna, setColuna] = useState(0);
  const [linha, setLinha] = useState(0);

  //CAROUSEL DAS CORES DO ESTOFADO
  const matrizCoresEstofado = [
    [
      '',
      props.estofadoTerracota,
      props.estofadoBranco,
      props.estofadoAzul,
      props.estofadoCinza,
      props.estofadoCaramelo,
      props.estofadoBegeClaro,
    ],
    [
      '',
      props.estofadoTerracotta2,
      props.estofadoBranco2,
      props.estofadoAzul2,
      props.estofadoCinza2,
      props.estofadoCaramelo2,
      props.estofadoBegeClaro2,
    ],
    [
      '',
      props.estofadoTerracotta3,
      props.estofadoBranco3,
      props.estofadoAzul3,
      props.estofadoCinza3,
      props.estofadoCaramelo3,
      props.estofadoBegeClaro3,
    ],
    [
      '',
      props.estofadoTerracotta4,
      props.estofadoBranco4,
      props.estofadoAzul4,
      props.estofadoCinza4,
      props.estofadoCaramelo4,
      props.estofadoBegeClaro4,
    ],
    [
      '',
      props.estofadoTerracotta5,
      props.estofadoBranco5,
      props.estofadoAzul5,
      props.estofadoCinza5,
      props.estofadoCaramelo5,
      props.estofadoBegeClaro5,
    ],
  ];
  //CAROUSEL DAS CORES DO ESTOFADO
  const matrizInteriorCasco = [
    [
      '',
      props.interiorCascoAzul ? props.interiorCascoAzul : null,
      props.interiorCascoPreto ? props.interiorCascoPreto : null,
      props.interiorCascoCinzaEscuro ? props.interiorCascoCinzaEscuro : null,
      props.interiorCascoBranco ? props.interiorCascoBranco : null,
      props.interiorCascoVermelho ? props.interiorCascoVermelho : null,
      props.interiorCascoVerde ? props.interiorCascoVerde : null,
      props.interiorCascoCinza ? props.interiorCascoCinza : null,
    ],
    [
      '',
      props.interiorCascoAzul2 ? props.interiorCascoAzul2 : null,
      props.interiorCascoPreto2 ? props.interiorCascoPreto2 : null,
      props.interiorCascoCinzaEscuro2 ? props.interiorCascoCinzaEscuro2 : null,
      props.interiorCascoBranco2 ? props.interiorCascoBranco2 : null,
      props.interiorCascoVermelho2 ? props.interiorCascoVermelho2 : null,
      props.interiorCascoVerde2 ? props.interiorCascoVerde2 : null,
      props.interiorCascoCinza2 ? props.interiorCascoCinza2 : null,
    ],
    [
      '',
      props.interiorCascoAzul3 ? props.interiorCascoAzul3 : null,
      props.interiorCascoPreto3 ? props.interiorCascoPreto3 : null,
      props.interiorCascoCinzaEscuro3 ? props.interiorCascoCinzaEscuro3 : null,
      props.interiorCascoBranco3 ? props.interiorCascoBranco3 : null,
      props.interiorCascoVermelho3 ? props.interiorCascoVermelho3 : null,
      props.interiorCascoVerde3 ? props.interiorCascoVerde3 : null,
      props.interiorCascoCinza3 ? props.interiorCascoCinza3 : null,
    ],
    ['', '', '', '', '', '', '', '', ''],
    ['', '', '', '', '', '', '', '', ''],
  ];

  const [colunaInteriorCasco, setColunaInteriorCasco] = useState(0);
  const [linhaInteriorCasco, setLinhaInteriorCasco] = useState(0);

  const arrayEstofadoVisao = [
    props.coresEstofadoDefault,
    props.visao1Estofado,
    props.visao2Estofado ? props.visao2Estofado : null,
    props.visao3Estofado ? props.visao3Estofado : null,
    props.visao4Estofado ? props.visao4Estofado : null,
  ];
  const [colunaEstofado, setColunaEstofado] = useState(0);
  const [linhaEstofado, setLinhaEstofado] = useState(0);

  const matrizCoresPiso = [
    [
      '',
      props.pisoTerracotta,
      props.pisoCinza,
      props.pisoCaramelo,
      props.pisoBege,
      props.pisoTerracottaHW,
      props.pisoAreiaHW,
      props.pisoCinzaHW,
      props.pisoCarameloHW,
    ],
    [
      '',
      props.pisoTerracotta2 ? props.pisoTerracotta2 : null,
      props.pisoCinza2 ? props.pisoCinza2 : null,
      props.pisoCaramelo2 ? props.pisoCaramelo2 : null,
      props.pisoBege2 ? props.pisoBege2 : null,
      props.pisoTerracottaHW2 ? props.pisoTerracottaHW2 : null,
      props.pisoAreiaHW2 ? props.pisoAreiaHW2 : null,
      props.pisoCinzaHW2 ? props.pisoCinzaHW2 : null,
      props.pisoCarameloHW2 ? props.pisoCarameloHW2 : null,
    ],
    [
      '',
      props.pisoTerracotta3 ? props.pisoTerracotta3 : null,
      props.pisoCinza3 ? props.pisoCinza3 : null,
      props.pisoCaramelo3 ? props.pisoCaramelo3 : null,
      props.pisoBege3 ? props.pisoBege3 : null,
      props.pisoTerracottaHW3 ? props.pisoTerracottaHW3 : null,
      props.pisoAreiaHW3 ? props.pisoAreiaHW3 : null,
      props.pisoCinzaHW3 ? props.pisoCinzaHW3 : null,
      props.pisoCarameloHW3 ? props.pisoCarameloHW3 : null,
    ],

    [
      '',
      props.pisoTerracotta4 ? props.pisoTerracotta4 : null,
      props.pisoCinza4 ? props.pisoCinza4 : null,
      props.pisoCaramelo4 ? props.pisoCaramelo4 : null,
      props.pisoBege4 ? props.pisoBege4 : null,
      props.pisoTerracottaHW4 ? props.pisoTerracottaHW4 : null,
      props.pisoAreiaHW4 ? props.pisoAreiaHW4 : null,
      props.pisoCinzaHW4 ? props.pisoCinzaHW4 : null,
      props.pisoCarameloHW4 ? props.pisoCarameloHW4 : null,
    ],

    [
      '',
      props.pisoTerracotta5 ? props.pisoTerracotta5 : null,
      props.pisoTerracotta5 ? props.pisoCinza5 : null,
      props.pisoTerracotta5 ? props.pisoCaramelo5 : null,
      props.pisoTerracotta5 ? props.pisoBege5 : null,
      props.pisoTerracotta5 ? props.pisoTerracottaHW5 : null,
      props.pisoTerracotta5 ? props.pisoAreiaHW5 : null,
      props.pisoTerracotta5 ? props.pisoCinzaHW5 : null,
      props.pisoTerracotta5 ? props.pisoCarameloHW5 : null,
    ],
    ['', '', '', '', '', '', ''],
    ['', '', '', '', '', '', ''],
    ['', '', '', '', '', '', ''],
  ];

  const [colunaPiso, setColunaPiso] = useState(0);
  const [linhaPiso, setLinhaPiso] = useState(0);

  const matrizExteriorEstofado = [
    [
      '',
      props.visaoDefaultEstofadoTerracotta,
      props.visaoDefaultEstofadoBranco,
      props.visaoDefaultEstofadoAzul,
      props.visaoDefaultEstofadoCinza,
      props.visaoDefaultEstofadoCaramelo,
      props.visaoDefaultEstofadoBege,
    ],
    [
      '',
      props.visao1EstofadoTerracotta,
      props.visao1EstofadoBranco,
      props.visao1EstofadoAzul,
      props.visao1EstofadoCinza,
      props.visao1EstofadoCaramelo,
      props.visao1EstofadoBege,
    ],
    [
      '',
      props.visao2EstofadoTerracotta,
      props.visao2EstofadoBranco,
      props.visao2EstofadoAzul,
      props.visao2EstofadoCinza,
      props.visao2EstofadoCaramelo,
      props.visao2EstofadoBege,
    ],
  ];
  const matrizExteriorPiso = [
    [
      '',
      props.visaoDefaultPisoTerracotta,
      props.visaoDefaultPisoCinza,
      props.visaoDefaultPisoCaramelo,
      props.visaoDefaultPisoBege,
      props.visaoDefaultPisoTerracottaHW,
      props.visaoDefaultPisoAreiaHW,
      props.visaoDefaultPisoCinzaHW,
      props.visaoDefaultPisoCarameloHW,
    ],
    [
      '',
      props.visao1PisoTerracotta,
      props.visao1PisoCinza,
      props.visao1PisoCaramelo,
      props.visao1PisoBege,
      props.visao1PisoTerracottaHW,
      props.visao1PisoAreiaHW,
      props.visao1PisoCinzaHW,
      props.visao1PisoCarameloHW,
    ],
    ['', '', '', '', '', '', '', '', ''],
  ];

  const [colunaExteriorEstofado, setColunaExteriorEstofado] = useState(0);
  const [linhaExteriorEstofado, setLinhaExteriorEstofado] = useState(0);
  const [colunaExteriorPiso, setColunaExteriorPiso] = useState(0);
  const [linhaExteriorPiso, setLinhaExteriorPiso] = useState(0);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  // enviar para api
  const [corCasco, setCorCasco] = useState('');
  const [corBancos, setCorBancos] = useState('');
  const [corTapecaria, setCorTapecaria] = useState('');

  //opicionais
  const [opicionais, setOpicionais] = useState([]);
  const [acessorios, setAcessorios] = useState([]);


  return (
    <div className="App">
      <NewNavbar backgroundColor="white" position="fixed" />
      <NewModal
        //itens
        pedido
        open={open}
        handleClose={handleClose}
        modelo={props.title}
        corCasco={corCasco}
        corBancos={corBancos}
        corTapecaria={corTapecaria}
        opicionais={opicionais}
        acessorios={acessorios}
        ficha={props.fichaTecnica}
        serie={props.itensSerie}
      />
      <div className="columnSection">
        {/* COLUNA DA ESQUERDA (IMAGENS) */}
        <div className="leftContainer">
          <div className="leftDiv">
            <LeftColumn
              cascoVisao={cascoVisao}
              loaded={loaded}
              arrayVisao={arrayVisao}
              visao={visao}
              matrizCores={matrizCores}
              linha={linha}
              coluna={coluna}
              matrizExteriorEstofado={matrizExteriorEstofado}
              linhaExteriorEstofado={linhaExteriorEstofado}
              colunaExteriorEstofado={colunaExteriorEstofado}
              matrizExteriorPiso={matrizExteriorPiso}
              linhaExteriorPiso={linhaExteriorPiso}
              colunaExteriorPiso={colunaExteriorPiso}
              visaoEstofado={visaoEstofado}
              arrayEstofadoVisao={arrayEstofadoVisao}
              visaoEstofadoArray={visaoEstofadoArray}
              matrizCoresEstofado={matrizCoresEstofado}
              linhaEstofado={linhaEstofado}
              colunaEstofado={colunaEstofado}
              matrizInteriorCasco={matrizInteriorCasco}
              linhaInteriorCasco={linhaInteriorCasco}
              colunaInteriorCasco={colunaInteriorCasco}
              matrizCoresPiso={matrizCoresPiso}
              linhaPiso={linhaPiso}
              colunaPiso={colunaPiso}
              setLoaded={setLoaded}
              currentCarousel={currentCarousel}
              visaoCascoIndices={visaoCascoIndices}
            />
          </div>
        </div>
        {/* COLUNA DA DIREITA (OPÇÕES) */}
        <div className="rightDiv">
          <RightColumn
            title={props.title}
            subtitle={props.subtitle}
            comprimento={props.comprimento}
            boca={props.boca}
            calado={props.calado}
            coluna={coluna}
            ShowColorImage={ShowColorImage}
            colunaEstofado={colunaEstofado}
            ShowColorEstofadoImage={ShowColorEstofadoImage}
            colunaPiso={colunaPiso}
            ShowColorPisoImage={ShowColorPisoImage}
            setCorCasco={setCorCasco}
            setCorBancos={setCorBancos}
            setCorTapecaria={setCorTapecaria}
            evaTechTerracotta={evaTechTerracotta}
            evaTechCinza={evaTechCinza}
            evaTechCaramelo={evaTechCaramelo}
            evaTechBegeClaro={evaTechBegeClaro}
            hardWoodterracota={hardWoodterracota}
            hardWoodBegeClaro={hardWoodBegeClaro}
            hardWoodCinza={hardWoodCinza}
            hardWoodBegeEscuro={hardWoodBegeEscuro}
            motorizacao100a150popa={props.motorizacao100a150popa}
            motorizacao60a115popa={props.motorizacao60a115popa}
            motorizacao40a60popa={props.motorizacao40a60popa}
            mercury40hp={props.mercury40hp}
            mercruiser50={props.mercruiser50}
            mercruiser60={props.mercruiser60}
            mercury100={props.mercury100}
            mercury115={props.mercury115}
            mercury150={props.mercury150}
            mercruiser200={props.mercruiser200}
            mercruiser250={props.mercruiser250}
            volvoPenta280={props.volvoPenta280}
            clickMercruiser40={clickMercruiser40}
            clickMercruiser50={clickMercruiser50}
            clickMercruiser60={clickMercruiser60}
            clickMercruiser100={clickMercruiser100}
            clickMercruiser115={clickMercruiser115}
            clickMercruiser150={clickMercruiser150}
            clickMercruiser200={clickMercruiser200}
            clickMercruiser250={clickMercruiser250}
            clickVolvoPenta280={clickVolvoPenta280}
            clickNone={clickNone}
            handleCarouselChange={handleCarouselChange}
            setClickMercruiser40={setClickMercruiser40}
            setClickMercruiser60={setClickMercruiser60}
            setClickMercruiser50={setClickMercruiser50}
            setClickMercruiser100={setClickMercruiser100}
            setClickMercruiser115={setClickMercruiser115}
            setClickMercruiser150={setClickMercruiser150}
            setClickMercruiser200={setClickMercruiser200}
            setClickMercruiser250={setClickMercruiser250}
            setClickVolvoPenta280={setClickVolvoPenta280}
            setClickNone={setClickNone}
            li1={props.li1}
            li2={props.li2}
            li3={props.li3}
            li4={props.li4}
            li5={props.li5}
            li6={props.li6}
            li7={props.li7}
            li8={props.li8}
            li9={props.li9}
            li10={props.li10}
            li11={props.li11}
            li12={props.li12}
            li13={props.li13}
            angulo={props.angulo}
            combustivel={props.combustivel}
            tanqueAguaDoce={props.tanqueAguaDoce}
            peso={props.peso}
            pessoas={props.pessoas}
            camarotes={props.camarotes}
            cabine={props.cabine}
            motorizacao={props.motorizacao}
            itensSerie={props.itensSerie}
            itemFabrica1={props.itemFabrica1}
            itemFabrica2={props.itemFabrica2}
            itemFabrica3={props.itemFabrica3}
            itemFabrica4={props.itemFabrica4}
            itemFabrica5={props.itemFabrica5}
            itemFabrica6={props.itemFabrica6}
            itemFabrica7={props.itemFabrica7}
            itemFabrica8={props.itemFabrica8}
            itemFabrica9={props.itemFabrica9}
            itemFabrica10={props.itemFabrica10}
            mestra240={props.mestra240}
            mestra230={props.mestra230}
            mestra222={props.mestra222}
            mestra212={props.mestra212}
            mestra200={props.mestra200}
            mestra198={props.mestra198}
            mestra180={props.mestra180}
            mestra160={props.mestra160}
            acessorios={acessorios}
            opicionais={opicionais}
            setAcessorios={setAcessorios}
            setOpicionais={setOpicionais}
            setOpen={setOpen}
          />
        </div>
      </div>
      <Newfooter />
    </div>
  );
};
export default CustomPage;
