/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import CustomPage from "../../components/CustomPage/CustomPage";

// visao default
import fotoCasco from "./../../data/images/customLancha198/fotoBasePNG.png";
import colorRed from "../../data/images/customLancha198/cascoVermelho.png";
import colorDarkGray from "../../data/images/customLancha198/cascoCinzaEscuro.png";
import colorGray from "../../data/images/customLancha198/cascoCinza.png";
import colorGreen from "../../data/images/customLancha198/cascoVerde.png";
import colorBlack from "../../data/images/customLancha198/cascoPreto.png";
import colorBlue from "../../data/images/customLancha198/cascoAzul.png";
import colorWhite from "../../data/images/customLancha198/cascoBranco.png";
//visao 1

import visao1 from "./../../data/images/customLancha198/visao1.png";
import visao1Vermelho from "./../../data/images/customLancha198/visao1CascoVermelho.png";
import visao1Azul from "./../../data/images/customLancha198/visao1CascoAzul.png";
import visao1Verde from "./../../data/images/customLancha198/visao1CascoVerde.png";
import visao1CinzaEscuro from "./../../data/images/customLancha198/visao1CascoCinzaEscuro.png";
import visao1Preto from "./../../data/images/customLancha198/visao1CascoPreto.png";
import visao1Cinza from "./../../data/images/customLancha198/visao1CascoCinza.png";
import visao1Branco from "./../../data/images/customLancha198/visao1CascoBranco.png";
//visao 2
import visao2 from "./../../data/images/customLancha198/visao2.png";
import visao2Vermelho from "./../../data/images/customLancha198/visao2CascoVermelho.png";
import visao2Azul from "./../../data/images/customLancha198/visao2CascoAzul.png";
import visao2Verde from "./../../data/images/customLancha198/visao2CascoVerde.png";
import visao2CinzaEscuro from "./../../data/images/customLancha198/visao2CascoCinzaEscuro.png";
import visao2Preto from "./../../data/images/customLancha198/visao2CascoPreto.png";
import visao2Branco from "./../../data/images/customLancha198/visao2CascoBranco.png";
import visao2Cinza from "./../../data/images/customLancha198/visao2CascoCinza.png";

//interior imagem 1
import interior from "../../data/images/customLancha198/interior.png";
import seatBlue from "../../data/images/customLancha198/interiorEstofadoAzul.png";
import seatWhite from "../../data/images/customLancha198/interiorEstofadoBranco.png";
import seatTerracota from "../../data/images/customLancha198/interiorEstofadoTerracotta.png";
import seatGray from "../../data/images/customLancha198/interiorEstofadoCinza.png";
import seatCaramelo from "../../data/images/customLancha198/interiorEstofadoCaramelo.png";
import seatBegeClaro from "../../data/images/customLancha198/interiorEstofadoBege.png";
import interiorCascoAzul from "../../data/images/customLancha198/interiorCascoAzul.png";
import interiorCascoPreto from "../../data/images/customLancha198/interiorCascoPreto.png";
import interiorCascoBranco from "../../data/images/customLancha198/interiorCascoBranco.png";
import interiorCascoCinza from "../../data/images/customLancha198/interiorCascoCinza.png";
import interiorCascoVermelho from "../../data/images/customLancha198/interiorCascoVermelho.png";
import interiorCascoVerde from "../../data/images/customLancha198/interiorCascoVerde.png";
import interiorCascoCinzaEscuro from "../../data/images/customLancha198/interiorCascoCinzaEscuro.png";

//interior imagem 2
import visao1Estofado from "../../data/images/customLancha198/visao1Estofado.png";
import estofadoTerracotta2 from "../../data/images/customLancha198/visao1EstofadoTerracotta.png";
import estofadoBranco2 from "../../data/images/customLancha198/visao1EstofadoBranco.png";
import estofadoAzul2 from "../../data/images/customLancha198/visao1EstofadoAzul.png";
import estofadoCinza2 from "../../data/images/customLancha198/visao1EstofadoCinza.png";
import estofadoCaramelo2 from "../../data/images/customLancha198/visao1EstofadoCaramelo.png";
import estofadoBegeClaro2 from "../../data/images/customLancha198/visao1EstofadoBege.png";

//interior imagem 3
import visao2Estofado from "../../data/images/customLancha198/visao2Estofado.png";
import estofadoTerracotta3 from "../../data/images/customLancha198/visao2EstofadoTerracotta.png";
import estofadoBranco3 from "../../data/images/customLancha198/visao2EstofadoBranco.png";
import estofadoAzul3 from "../../data/images/customLancha198/visao2EstofadoAzul.png";
import estofadoCinza3 from "../../data/images/customLancha198/visao2EstofadoCinza.png";
import estofadoCaramelo3 from "../../data/images/customLancha198/visao2EstofadoCaramelo.png";
import estofadoBegeClaro3 from "../../data/images/customLancha198/visao2EstofadoBege.png";

//interior imagem 4
import visao3Estofado from "../../data/images/customLancha198/visao3Estofado.png";
import estofadoTerracotta4 from "../../data/images/customLancha198/visao3EstofadoTerracotta.png";
import estofadoBranco4 from "../../data/images/customLancha198/visao3EstofadoBranco.png";
import estofadoAzul4 from "../../data/images/customLancha198/visao3EstofadoAzul.png";
import estofadoCinza4 from "../../data/images/customLancha198/visao3EstofadoCinza.png";
import estofadoCaramelo4 from "../../data/images/customLancha198/visao3EstofadoCaramelo.png";
import estofadoBegeClaro4 from "../../data/images/customLancha198/visao3EstofadoBege.png";

import { useTranslation } from "react-i18next";
export default () => {
  const { t, i18n } = useTranslation();
  const itensSerie = [
    t("pegaMaoInox"),
    t("bocalCombustivel"),
    t("bocalAguaDoce"),
    t("portaCoposInox"),
    t("escadaDePopaInox"),
    t("kitTorneiraPia"),
    t("luzesNavegacao"),
    t("sistemaEletrico"),
    t("duchaPopa"),
    t("estofamentoCourvim"),
    t("paiosProa"),
  ]
  const fichaTecnica = [
    t("combustivelTanque") + "⠀100 L",
    t("tanqueAguaDoce") + "⠀28 L",
    t("pesoSemMotor") + "⠀550 KG",
    t("passageirosDiurnos") + "⠀1+7",
    t("motorizacaoPopa") + `⠀${t("motorizacao90a150")}`,
  ]

  return (
    <>
      <CustomPage
        mestra198
        mercury100hp
        title="Mestra 198"
        mercury100="Mercury 100 HP 4T CT"
        mercury115="Mercury 115 HP 4T CT"
        // subtitle="Lancha de 16 pés"
        //visao default
        coresCascoDefault={fotoCasco}
        colorBlue={colorBlue}
        colorRed={colorRed}
        colorDarkGray={colorDarkGray}
        colorWhite={colorWhite}
        colorGray={colorGray}
        colorGreen={colorGreen}
        colorBlack={colorBlack}
        //visao 1
        visao1={visao1}
        visaoCoresRed2={visao1Vermelho}
        visaoCoresBlue2={visao1Azul}
        visaoCoresGreen2={visao1Verde}
        visaoCoresGray2={visao1Cinza}
        visaoCoresDarkGray2={visao1CinzaEscuro}
        visaoCoresBlack2={visao1Preto}
        visaoCoresWhite2={visao1Branco}
        //visao 2
        visao2={visao2}
        visaoCoresRed3={visao2Vermelho}
        visaoCoresBlue3={visao2Azul}
        visaoCoresGreen3={visao2Verde}
        visaoCoresGray3={visao2Cinza}
        visaoCoresDarkGray3={visao2CinzaEscuro}
        visaoCoresBlack3={visao2Preto}
        visaoCoresWhite3={visao2Branco}
        // primeira visao interior
        coresEstofadoDefault={interior}
        interiorCascoAzul={interiorCascoAzul}
        interiorCascoPreto={interiorCascoPreto}
        interiorCascoCinzaEscuro={interiorCascoCinzaEscuro}
        interiorCascoBranco={interiorCascoBranco}
        interiorCascoVermelho={interiorCascoVermelho}
        interiorCascoVerde={interiorCascoVerde}
        interiorCascoCinza={interiorCascoCinza}
        estofadoAzul={seatBlue}
        estofadoBranco={seatWhite}
        estofadoBegeClaro={seatBegeClaro}
        estofadoCaramelo={seatCaramelo}
        estofadoCinza={seatGray}
        estofadoBlue={seatBlue}
        estofadoTerracota={seatTerracota}
        //segunda visao interior
        visao1Estofado={visao1Estofado}
        estofadoTerracotta2={estofadoTerracotta2}
        estofadoBranco2={estofadoBranco2}
        estofadoBegeClaro2={estofadoBegeClaro2}
        estofadoCaramelo2={estofadoCaramelo2}
        estofadoCinza2={estofadoCinza2}
        estofadoAzul2={estofadoAzul2}
        //terceira visao interior
        visao2Estofado={visao2Estofado}
        estofadoTerracotta3={estofadoTerracotta3}
        estofadoBranco3={estofadoBranco3}
        estofadoBegeClaro3={estofadoBegeClaro3}
        estofadoCaramelo3={estofadoCaramelo3}
        estofadoCinza3={estofadoCinza3}
        estofadoAzul3={estofadoAzul3}
        //quarta visao interior
        visao3Estofado={visao3Estofado}
        estofadoTerracotta4={estofadoTerracotta4}
        estofadoBranco4={estofadoBranco4}
        estofadoBegeClaro4={estofadoBegeClaro4}
        estofadoCaramelo4={estofadoCaramelo4}
        estofadoCinza4={estofadoCinza4}
        estofadoAzul4={estofadoAzul4}
        comprimento="5,80 M"
        boca="2,25 M"
        calado="0,30 M"
        li1={t("combustivelTanque")}
        li2={t("tanqueAguaDoce")}
        li3={t("pesoSemMotor")}
        li4={t("passageirosDiurnos")}
        li5={t("motorizacaoPopa")}
        motorizacao={t("motorizacao90a150")}
        combustivel="100 L"
        tanqueAguaDoce="28 L"
        peso="550 KG"
        pessoas="1+7"
        fichaTecnica={fichaTecnica}
        motorizacao100a150popa={
          <div
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <p>Motorizacao de 100 a 150 hp popa</p>
            <p>- 4 tempos CT</p>
            <p> - 4 tempos CT PRO XS </p>
          </div>
        }
        motor="Mercury 50 HP Carburado"
        motor2="Mercury 60 HP 4 Tempos"

        itensSerie={itensSerie}
        itemFabrica1={t("motorizacaoFabricaSubtitle")}
        itemFabrica2={t("sistemaDirecao")}
        itemFabrica3={t("bateria")}
        itemFabrica4={t("bombaPorao")}
        itemFabrica5={t("chaveGeral")}
        itemFabrica6={t("automaticoBombaPorao")}
        itemFabrica7={t("bombaPressurizacao")}
        itemFabrica8={t("volante")}
        itemFabrica9={t("filtros")}
        itemFabrica10={t("relogioMontagem")}
      />
    </>
  );
};
