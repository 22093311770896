/* eslint-disable import/no-anonymous-default-export */
import React from 'react';
import CustomPage from '../../components/CustomPage/CustomPage';
import { useTranslation } from 'react-i18next';

// ESTOFADO/PISO
// visao default
import interior from '../../data/images/customLancha212/interior.png';
import seatBlue from '../../data/images/customLancha212/interiorEstofadoAzul.png';
import seatWhite from '../../data/images/customLancha212/interior.png';
import seatTerracota from '../../data/images/customLancha212/interiorEstofadoTerracotta.png';
import seatGray from '../../data/images/customLancha212/interiorEstofadoCinza.png';
import seatCaramelo from '../../data/images/customLancha212/interiorEstofadoCaramelo.png';
import seatBegeClaro from '../../data/images/customLancha212/interiorEstofadoBege.png';

import pisoBege from '../../data/images/customLancha212/interiorPisoBege.png';
import pisoCinza from '../../data/images/customLancha212/interiorPisoCinza.png';
import pisoCaramelo from '../../data/images/customLancha212/interiorPisoCaramelo.png';
import pisoTerracotta from '../../data/images/customLancha212/interiorPisoTerracotta.png';
import pisoTerracottaHW from '../../data/images/customLancha212/interiorPisoTerracottaHW.png';
import pisoAreiaHW from '../../data/images/customLancha212/interiorPisoAreiaHW.png';
import pisoCinzaHW from '../../data/images/customLancha212/interiorPisoCinzaHW.png';
import pisoCarameloHW from '../../data/images/customLancha212/interiorPisoCarameloHW.png';

// visao 1 interior
import visao1Estofado from '../../data/images/customLancha212/visao1Estofado.png';
import estofadoTerracotta2 from '../../data/images/customLancha212/visao1EstofadoTerracotta.png';
import estofadoBranco2 from '../../data/images/customLancha212/visao1EstofadoBranco.png';
import estofadoAzul2 from '../../data/images/customLancha212/visao1EstofadoAzul.png';
import estofadoCinza2 from '../../data/images/customLancha212/visao1EstofadoCinza.png';
import estofadoCaramelo2 from '../../data/images/customLancha212/visao1EstofadoCaramelo.png';
import estofadoBegeClaro2 from '../../data/images/customLancha212/visao1EstofadoBege.png';

import pisoBege2 from '../../data/images/customLancha212/visao1PisoBege.png';
import pisoCinza2 from '../../data/images/customLancha212/visao1PisoCinza.png';
import pisoCaramelo2 from '../../data/images/customLancha212/visao1PisoCaramelo.png';
import pisoTerracotta2 from '../../data/images/customLancha212/visao1PisoTerracotta.png';

import pisoTerracottaHW2 from '../../data/images/customLancha212/visao1PisoTerracottaHW.png';
import pisoAreiaHW2 from '../../data/images/customLancha212/visao1PisoAreiaHW.png';
import pisoCinzaHW2 from '../../data/images/customLancha212/visao1PisoCinzaHW.png';
import pisoCarameloHW2 from '../../data/images/customLancha212/visao1PisoCarameloHW.png';

//visao 2 interior
import visao2Estofado from '../../data/images/customLancha212/visao2Estofado.png';
import pisoBege3 from '../../data/images/customLancha212/pisoBege3.png';
import pisoCinza3 from '../../data/images/customLancha212/pisoCinza3.png';
import pisoCaramelo3 from '../../data/images/customLancha212/pisoCaramelo3.png';
import pisoTerracotta3 from '../../data/images/customLancha212/pisoTerracotta3.png';
import pisoTerracottaHW3 from '../../data/images/customLancha212/pisoTerracottaHW3.png';
import pisoAreiaHW3 from '../../data/images/customLancha212/pisoAreiaHW3.png';
import pisoCinzaHW3 from '../../data/images/customLancha212/pisoCinzaHW3.png';
import pisoCarameloHW3 from '../../data/images/customLancha212/pisoCarameloHW3.png';

import estofadoTerracotta3 from '../../data/images/customLancha212/estofadoTerracotta3.png';
import estofadoBranco3 from '../../data/images/customLancha212/estofadoBranco3.png';
import estofadoAzul3 from '../../data/images/customLancha212/estofadoAzul3.png';
import estofadoCinza3 from '../../data/images/customLancha212/estofadoCinza3.png';
import estofadoCaramelo3 from '../../data/images/customLancha212/estofadoCaramelo3.png';
import estofadoBege3 from '../../data/images/customLancha212/estofadoBege3.png';

// visao 4 interior
import visao4Estofado from '../../data/images/customLancha212/visao4Estofado.png';
import pisoBege5 from '../../data/images/customLancha212/pisoBege5.png';
import pisoCinza5 from '../../data/images/customLancha212/pisoCinza5.png';
import pisoCaramelo5 from '../../data/images/customLancha212/pisoCaramelo5.png';
import pisoTerracotta5 from '../../data/images/customLancha212/pisoTerracotta5.png';
import pisoTerracottaHW5 from '../../data/images/customLancha212/pisoTerracottaHW5.png';
import pisoAreiaHW5 from '../../data/images/customLancha212/pisoAreiaHW5.png';
import pisoCinzaHW5 from '../../data/images/customLancha212/pisoCinzaHW5.png';
import pisoCarameloHW5 from '../../data/images/customLancha212/pisoCarameloHW5.png';
import estofadoTerracotta5 from '../../data/images/customLancha212/estofadoTerracotta5.png';
import estofadoAzul5 from '../../data/images/customLancha212/estofadoAzul5.png';
import estofadoBranco5 from '../../data/images/customLancha212/estofadoBranco5.png';
import estofadoCaramelo5 from '../../data/images/customLancha212/estofadoCaramelo5.png';
import estofadoCinza5 from '../../data/images/customLancha212/estofadoCinza5.png';
//visao 3 interior
import visao3Estofado from '../../data/images/customLancha230/visao3Estofado.png';
import estofadoTerracotta4 from '../../data/images/customLancha230/estofadoTerracotta4.png';
import estofadoBranco4 from '../../data/images/customLancha230/estofadoBranco4.png';
import estofadoAzul4 from '../../data/images/customLancha230/estofadoAzul4.png';
import estofadoCinza4 from '../../data/images/customLancha230/estofadoCinza4.png';
import estofadoCaramelo4 from '../../data/images/customLancha230/estofadoCaramelo4.png';
import estofadoBege4 from '../../data/images/customLancha230/estofadoBege4.png';

import pisoBege4 from '../../data/images/customLancha230/pisoBege4.png';
import pisoCinza4 from '../../data/images/customLancha230/pisoCinzaHW4.png';
import pisoCaramelo4 from '../../data/images/customLancha230/pisoCaramelo4.png';
import pisoTerracotta4 from '../../data/images/customLancha230/pisoTerracotta4.png';
import pisoTerracottaHW4 from '../../data/images/customLancha230/pisoTerracottaHW4.png';
import pisoAreiaHW4 from '../../data/images/customLancha230/pisoAreiaHW4.png';
import pisoCinzaHW4 from '../../data/images/customLancha230/pisoCinzaHW4.png';
import pisoCarameloHW4 from '../../data/images/customLancha230/pisoCarameloHW4.png';

// CASCO E CORES

import fotoCasco from './../../data/images/customLancha212/fotoBasePNG.png';
import visao1 from './../../data/images/customLancha212/visao1.png';
import visao2 from './../../data/images/customLancha212/visao2.png';

// cores default
import colorRed from '../../data/images/customLancha212/cascoVermelho.png';
import colorDarkGray from '../../data/images/customLancha212/cascoCinzaEscuro.png';
import colorGray from '../../data/images/customLancha212/cascoCinzaClaro.png';
import colorGreen from '../../data/images/customLancha212/cascoVerde.png';
import colorBlack from '../../data/images/customLancha212/cascoPreto.png';
import colorBlue from '../../data/images/customLancha212/cascoAzul.png';
import colorWhite from '../../data/images/customLancha212/cascoBranco.png';

//default cor do estofado e piso detalhes
import visaoDefaultEstofadoTerracotta from '../../data/images/customLancha212/visaoDefaultEstofadoTerracotta.png';
import visaoDefaultEstofadoAzul from '../../data/images/customLancha212/visaoDefaultEstofadoAzul.png';
import visaoDefaultEstofadoBranco from '../../data/images/customLancha212/visaoDefaultEstofadoBranco.png';
import visaoDefaultEstofadoCinza from '../../data/images/customLancha212/visaoDefaultEstofadoCinza.png';
import visaoDefaultEstofadoCaramelo from '../../data/images/customLancha212/visaoDefaultEstofadoCaramelo.png';
import visaoDefaultEstofadoBege from '../../data/images/customLancha212/visaoDefaultEstofadoBege.png';

import interiorCascoAzul2 from '../../data/images/customLancha212/interiorCascoAzul2.png';
import interiorCascoPreto2 from '../../data/images/customLancha212/interiorCascoPreto2.png';
import interiorCascoBranco2 from '../../data/images/customLancha212/interiorCascoBranco2.png';
import interiorCascoCinza2 from '../../data/images/customLancha212/interiorCascoCinza2.png';
import interiorCascoVermelho2 from '../../data/images/customLancha212/interiorCascoVermelho2.png';
import interiorCascoVerde2 from '../../data/images/customLancha212/interiorCascoVerde2.png';
import interiorCascoCinzaEscuro2 from '../../data/images/customLancha212/interiorCascoCinzaEscuro2.png';

// cores casco visao 1
import visao1Vermelho from './../../data/images/customLancha212/visao1CascoVermelho.png';
import visao1Azul from './../../data/images/customLancha212/visao1CascoAzul.png';
import visao1Verde from './../../data/images/customLancha212/visao1CascoVerde.png';
import visao1CinzaEscuro from './../../data/images/customLancha212/visao1CascoCinzaEscuro.png';
import visao1Preto from './../../data/images/customLancha212/visao1CascoPreto.png';
import visao1Cinza from './../../data/images/customLancha212/visao1CascoCinza.png';

//cores casco visao 2
import visao2Vermelho from './../../data/images/customLancha212/visao2CascoVermelho.png';
import visao2Azul from './../../data/images/customLancha212/visao2CascoAzul.png';
import visao2Verde from './../../data/images/customLancha212/visao2CascoVerde.png';
import visao2CinzaEscuro from './../../data/images/customLancha212/visao2CascoCinzaEscuro.png';
import visao2Preto from './../../data/images/customLancha212/visao2CascoPreto.png';
import visao2Cinza from './../../data/images/customLancha212/visao2CascoCinza.png';

export default () => {
  const { t, i18n } = useTranslation();

  const itensSerie = [
    t('lixeiraAcrilico'),
    t('bocalCombustivel'),
    t('bocalAguaDoce'),
    t('portaCoposInox'),
    t('escadaDePopaInox'),
    t('kitTorneiraPia'),
    t('luzesNavegacao'),
    t('sistemaEletrico'),
    t('duchaPopa'),
    t('estofamentoCourvim'),
    t('paiosProa'),
    t('caixaMovel'),
    t('compartimentoBoiaCircular'),
  ]

  const fichaTecnica = [
    t('combustivelTanque') + "⠀100 L",
    t('tanqueAguaDoce') + "⠀60 L",
    t('pesoSemMotor') + "⠀700 KG",
    t('passageirosDiurnos') + "⠀1 + 8",
    t('motorizacaoCentroRabeta') + `⠀${t('motorizacao100a220')}`,
  ]
  return (
    <>
      <CustomPage
        mestra212
        pisoOptions
        mercury100hp
        coresCascoDefault={fotoCasco}
        estofadoTerracota={seatTerracota}
        estofadoAzul={seatBlue}
        estofadoBranco={seatWhite}
        estofadoBegeClaro={seatBegeClaro}
        estofadoCaramelo={seatCaramelo}
        estofadoCinza={seatGray}
        estofadoBlue={seatBlue}
        // interiorCascoAzul={interiorCascoAzul}
        // interiorCascoPreto={interiorCascoPreto}
        // interiorCascoCinzaEscuro={interiorCascoCinzaEscuro}
        // interiorCascoBranco={interiorCascoBranco}
        // interiorCascoVermelho={interiorCascoVermelho}
        // interiorCascoVerde={interiorCascoVerde}
        // interiorCascoCinza={interiorCascoCinza}
        // detalhes primeira visao exterior pisos e estofado

        interiorCascoAzul2={interiorCascoAzul2}
        interiorCascoPreto2={interiorCascoPreto2}
        interiorCascoBranco2={interiorCascoBranco2}
        interiorCascoCinza2={interiorCascoCinza2}
        interiorCascoCinzaEscuro2={interiorCascoCinzaEscuro2}
        interiorCascoVermelho2={interiorCascoVermelho2}
        interiorCascoVerde2={interiorCascoVerde2}
        // visoes
        visao1={visao1}
        visao2={visao2}
        visaoDefaultEstofadoTerracotta={visaoDefaultEstofadoTerracotta}
        visaoDefaultEstofadoAzul={visaoDefaultEstofadoAzul}
        visaoDefaultEstofadoBranco={visaoDefaultEstofadoBranco}
        visaoDefaultEstofadoCinza={visaoDefaultEstofadoCinza}
        visaoDefaultEstofadoCaramelo={visaoDefaultEstofadoCaramelo}
        visaoDefaultEstofadoBege={visaoDefaultEstofadoBege}
        // VISOES DO ESTOFADO
        coresEstofadoDefault={interior}
        visao1Estofado={visao1Estofado}
        visao2Estofado={visao2Estofado}
        visao4Estofado={visao4Estofado}
        // visao3Estofado={visao3Estofado}
        // visao4Estofado={visao4Estofado}
        // VISOES DO PISO
        //visao default
        pisoTerracotta={pisoTerracotta}
        pisoCinza={pisoCinza}
        pisoCaramelo={pisoCaramelo}
        pisoBege={pisoBege}
        pisoTerracottaHW={pisoTerracottaHW}
        pisoAreiaHW={pisoAreiaHW}
        pisoCinzaHW={pisoCinzaHW}
        pisoCarameloHW={pisoCarameloHW}
        // visao 1
        pisoTerracotta2={pisoTerracotta2}
        pisoCinza2={pisoCinza2}
        pisoCaramelo2={pisoCaramelo2}
        pisoBege2={pisoBege2}
        pisoTerracottaHW2={pisoTerracottaHW2}
        pisoAreiaHW2={pisoAreiaHW2}
        pisoCinzaHW2={pisoCinzaHW2}
        pisoCarameloHW2={pisoCarameloHW2}
        //visao 2
        pisoTerracotta3={pisoTerracotta3}
        pisoCinza3={pisoCinza3}
        pisoCaramelo3={pisoCaramelo3}
        pisoBege3={pisoBege3}
        pisoTerracottaHW3={pisoTerracottaHW3}
        pisoAreiaHW3={pisoAreiaHW3}
        pisoCinzaHW3={pisoCinzaHW3}
        pisoCarameloHW3={pisoCarameloHW3}
        estofadoTerracotta3={estofadoTerracotta3}
        estofadoCaramelo3={estofadoCaramelo3}
        estofadoBege3={estofadoBege3}
        estofadoCinza3={estofadoCinza3}
        estofadoBranco3={estofadoBranco3}
        estofadoAzul3={estofadoAzul3}
        //visao 3
        // pisoTerracotta4={pisoTerracotta4}
        // pisoCinza4={pisoCinza4}
        // pisoCaramelo4={pisoCaramelo4}
        // pisoBege4={pisoBege4}
        // pisoTerracottaHW4={pisoTerracottaHW4}
        // pisoAreiaHW4={pisoAreiaHW4}
        // pisoCinzaHW4={pisoCinzaHW4}
        // pisoCarameloHW4={pisoCarameloHW4}
        //visao 4
        pisoTerracotta5={pisoTerracotta5}
        pisoCinza5={pisoCinza5}
        pisoCaramelo5={pisoCaramelo5}
        pisoBege5={pisoBege5}
        pisoTerracottaHW5={pisoTerracottaHW5}
        pisoAreiaHW5={pisoAreiaHW5}
        pisoCinzaHW5={pisoCinzaHW5}
        pisoCarameloHW5={pisoCarameloHW5}
        // estofadoTerracotta4={estofadoTerracotta4}
        // estofadoCaramelo4={estofadoCaramelo4}
        // estofadoBege4={estofadoBege4}
        // estofadoBranco4={estofadoBranco4}
        // estofadoAzul4={estofadoAzul4}
        // estofadoCinza4={estofadoCinza4}
        //  CORES DO CASCO DEFAULT
        colorBlue={colorBlue}
        colorRed={colorRed}
        colorDarkGray={colorDarkGray}
        colorWhite={colorWhite}
        colorGray={colorGray}
        colorGreen={colorGreen}
        colorBlack={colorBlack}
        // VISOES DA COR VERMELHA
        visaoCoresRed2={visao1Vermelho}
        visaoCoresRed3={visao2Vermelho}
        //VISOES DA COR AZUL
        visaoCoresBlue2={visao1Azul}
        visaoCoresBlue3={visao2Azul}
        //VISOES DA COR VERDE
        visaoCoresGreen2={visao1Verde}
        visaoCoresGreen3={visao2Verde}
        //VISOES DA COR CINZA
        visaoCoresGray2={visao1Cinza}
        visaoCoresGray3={visao2Cinza}
        //VISOES DA COR CINZA ESCURO
        visaoCoresDarkGray2={visao1CinzaEscuro}
        visaoCoresDarkGray3={visao2CinzaEscuro}
        //VISOES DA COR PRETO
        visaoCoresBlack2={visao1Preto}
        visaoCoresBlack3={visao2Preto}
        // VISOES DO ESTOFADO TERRACOTA
        estofadoTerracotta2={estofadoTerracotta2}
        estofadoBranco2={estofadoBranco2}
        // estofadoTerracotta3={estofadoTerracotta3}
        // estofadoTerracotta4={estofadoTerracotta4}
        estofadoTerracotta5={estofadoTerracotta5}
        // VISOES DO ESTOFADO BRANCO
        // estofadoBranco2={estofadoBranco2}
        // estofadoBranco3={estofadoBranco3}
        // estofadoBranco4={estofadoBranco4}
        estofadoBranco5={estofadoBranco5}
        // VISOES DO ESTOFADO AZUL
        estofadoAzul2={estofadoAzul2}
        // estofadoAzul3={estofadoAzul3}
        // estofadoAzul4={estofadoAzul4}
        estofadoAzul5={estofadoAzul5}
        // VISOES DO ESTOFADO CINZA
        estofadoCinza2={estofadoCinza2}
        // estofadoCinza3={estofadoCinza3}
        // estofadoCinza4={estofadoCinza4}
        estofadoCinza5={estofadoCinza5}
        // VISOES DO ESTOFADO CARAMELO
        estofadoCaramelo2={estofadoCaramelo2}
        // estofadoCaramelo3={estofadoCaramelo3}
        // estofadoCaramelo4={estofadoCaramelo4}
        estofadoCaramelo5={estofadoCaramelo5}
        // VISOES DO ESTOFADO BEGE CLARO
        estofadoBegeClaro2={estofadoBegeClaro2}
        // estofadoBegeClaro3={estofadoBegeClaro3}
        // estofadoBegeClaro4={estofadoBegeClaro4}
        // estofadoBegeClaro4={estofadoBege4}
        // imgVisaoCasco={imgVisaoCasco}

        title="Mestra 212"
        mercury100="Mercury 100 HP 4T CT"
        mercury115="Mercury 115 HP 4T CT"
        mercury150="Mercury 150 HP 4T CT"
        // subtitle="Lancha de 16 pés"
        comprimento="6,17 M"
        boca="2,25 M"
        calado="0,32 M"
        li1={t('combustivelTanque')}
        li2={t('tanqueAguaDoce')}
        li3={t('pesoSemMotor')}
        li4={t('passageirosDiurnos')}
        li5={t('motorizacaoCentroRabeta')}
        combustivel="100 L"
        tanqueAguaDoce="60 L"
        peso="700 KG"
        pessoas="1 + 8"
        motorizacao={t('motorizacao100a220')}
        fichaTecnica={fichaTecnica}
        motorizacao100a150popa={
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
          >
            <p>Motorizacao de 100 a 150 hp popa</p>
            <p>- 4 tempos CT</p>
            <p> - 4 tempos CT PRO XS</p>
          </div>
        }
        motor="Mercury 50 HP Carburado"
        motor2="Mercury 60 HP 4 Tempos"
        itensSerie={itensSerie}
        itemFabrica1={t('motorizacaoFabricaSubtitle')}
        itemFabrica2={t('sistemaDirecao')}
        itemFabrica3={t('bateria')}
        itemFabrica4={t('bombaPorao')}
        itemFabrica5={t('chaveGeral')}
        itemFabrica6={t('automaticoBombaPorao')}
        itemFabrica7={t('bombaPressurizacao')}
        itemFabrica8={t('volante')}
        itemFabrica9={t('filtros')}
        itemFabrica10={t('relogioMontagem')}
      />
    </>
  );
};
