import React from "react";
import { Checkbox, FormControlLabel } from '@mui/material';

const CheckAcc = (props) => {
  const itens = [
    props.mestra240 || props.mestra230 || props.mestra212 || props.mestra200
      ? " Âncora inox 5KG c/ cabo 30 metros"
      : null,
    props.mestra240 || props.mestra230
      ? "Guincho Elétrico + Corrente + Girador + Manilhas"
      : null,
    props.mestra240 ||
      props.mestra230 ||
      props.mestra222 ||
      props.mestra212 ||
      props.mestra200 ||
      props.mestra198 ||
      props.mestra180 ||
      props.mestra160
      ? "Bandeira do Brasil pano"
      : null,
    props.mestra240 ||
      props.mestra230 ||
      props.mestra222 ||
      props.mestra200 ||
      props.mestra198 ||
      props.mestra180
      ? "Churrasqueira com base e pedestral"
      : null,
    props.mestra240 ||
      props.mestra230 ||
      props.mestra222 ||
      props.mestra212 ||
      props.mestra200 ||
      props.mestra198 ||
      props.mestra180 ||
      props.mestra160
      ? "Bússola"
      : null,
    props.mestra240 ||
      props.mestra230 ||
      props.mestra222 ||
      props.mestra212 ||
      props.mestra200 ||
      props.mestra198 ||
      props.mestra180 ||
      props.mestra160
      ? "Buzina de embutir ou corneta"
      : null,
    props.mestra240 ||
      props.mestra230 ||
      props.mestra222 ||
      props.mestra212 ||
      props.mestra200 ||
      props.mestra198 ||
      props.mestra180 ||
      props.mestra160
      ? "Tomada 12V"
      : null,
    props.mestra240 ||
      props.mestra230 ||
      props.mestra222 ||
      props.mestra212 ||
      props.mestra200 ||
      props.mestra198 ||
      props.mestra180 ||
      props.mestra160
      ? "Tomada USB"
      : null,
    props.mestra240 ||
      props.mestra230 ||
      props.mestra222 ||
      props.mestra212 ||
      props.mestra200 ||
      props.mestra198 ||
      props.mestra180 ||
      props.mestra160
      ? "Bateria (Extra) 100 Amperes"
      : null,
    props.mestra240 ||
      props.mestra230 ||
      props.mestra222 ||
      props.mestra212 ||
      props.mestra200
      ? "Capa Korino C/ Targa"
      : null,
    props.mestra240 ||
      props.mestra230 ||
      props.mestra222 ||
      props.mestra212 ||
      props.mestra200
      ? "Capa Korino S/ Targa"
      : null,
    props.mestra240 ||
      props.mestra230 ||
      props.mestra222 ||
      props.mestra212 ||
      props.mestra200 ||
      props.mestra198 ||
      props.mestra180 ||
      props.mestra160
      ? "Conj. Rádio VHF com antena"
      : null,
    props.mestra240 ||
      props.mestra230 ||
      props.mestra222 ||
      props.mestra212 ||
      props.mestra200 ||
      props.mestra198 ||
      props.mestra180 ||
      props.mestra160
      ? "Sonar/GPS Garmin Striker 4"
      : null,
    props.mestra240 ||
      props.mestra230 ||
      props.mestra222 ||
      props.mestra212 ||
      props.mestra200 ||
      props.mestra198 ||
      props.mestra180 ||
      props.mestra160
      ? "Sistema de som mp3 + 4 Alto falantes"
      : null,
    props.mestra240 ||
      props.mestra230 ||
      props.mestra222 ||
      props.mestra212 ||
      props.mestra200 ||
      props.mestra198 ||
      props.mestra180 ||
      props.mestra160
      ? "Som RGB: Painel digital, Amplificador, Controle remoto, 4 falantes LEDS, Porta copos LEDS, armário/cristaleira LEDS"
      : null,
    props.mestra240 || props.mestra230 || props.mestra222
      ? "Som RGB: Painel digital, Amplificador, Controle remoto, 6 falantesLEDS, Porta copos LEDS, Armário/cristaleira LEDS, Entrada de ar LEDS, Apoio cabeça em LEDS"
      : null,
    props.mestra240 ||
      props.mestra230 ||
      props.mestra222 ||
      props.mestra212 ||
      props.mestra200 ||
      props.mestra198 ||
      props.mestra180 ||
      props.mestra160
      ? "Tapete Beach Deck Hard"
      : null,
    props.mestra240 ||
      props.mestra230 ||
      props.mestra198 ||
      props.mestra180 ||
      props.mestra160
      ? "Tapete E.V.A Soft"
      : null,
    props.mestra240 ||
      props.mestra230 ||
      props.mestra222 ||
      props.mestra212 ||
      props.mestra200 ||
      props.mestra198 ||
      props.mestra180
      ? "Toldo em tecido (Tubo grosso com bracinho)"
      : null,
    props.mestra160 ? "Toldo em tecido" : null,
    props.mestra240 || props.mestra230 || props.mestra222
      ? "Carreta rodo encalhe madeira"
      : null,
    props.mestra240 ||
      props.mestra230 ||
      props.mestra222 ||
      props.mestra212 ||
      props.mestra200 ||
      props.mestra198 ||
      props.mestra180
      ? "Carreta rodoviária eixo duplo com freio"
      : null,
    props.mestra240 ||
      props.mestra230 ||
      props.mestra222 ||
      props.mestra212 ||
      props.mestra200 ||
      props.mestra198 ||
      props.mestra180
      ? "Carreta rodoviária eixo duplo sem freio"
      : null,
    props.mestra240 ||
      props.mestra230 ||
      props.mestra222 ||
      props.mestra212 ||
      props.mestra200 ||
      props.mestra198 ||
      props.mestra180 ||
      props.mestra160
      ? "Kit salvatagem (01 boia circular, 02 Defensas, 04 Suporte-defensa Inox, 01 Extintor)"
      : null,
    props.mestra240 ||
      props.mestra230 ||
      props.mestra222 ||
      props.mestra212 ||
      props.mestra200 ||
      props.mestra198 ||
      props.mestra180 ||
      props.mestra160
      ? "Par Luz subaquática"
      : null,
    props.mestra240 ||
      props.mestra230 ||
      props.mestra222 ||
      props.mestra212 ||
      props.mestra200 ||
      props.mestra198 ||
      props.mestra180 ||
      props.mestra160
      ? "Marcador de combustível"
      : null,
    props.mestra240 ||
      props.mestra230 ||
      props.mestra222 ||
      props.mestra212 ||
      props.mestra200 ||
      props.mestra198 ||
      props.mestra180 ||
      props.mestra160
      ? "Farol de busca"
      : null,
    props.mestra222 || props.mestra212 || props.mestra200
      ? "Tapete TEKA E.V.A Soft"
      : null,
    props.mestra212 ? "Puxador de Wake e pega mão" : null,
  ];
  const Valida = (e) => {
    var array = props.acessorios;
    //Se for true adiciona no array
    if (e.target.checked) {
      array.push(e.target.value);
    }
    else {
      //Se for false, remove do array
      const index = array.indexOf(e.target.value);
      if (index > -1) {
        array.splice(index, 1); // 2nd parameter means remove one item only
      }
    }
    props.setAcessorios(array);
  };
  return (
    <div>
      <div
        style={{
          marginTop: "50px",
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "Column",
          alignItems: "flex-start",
          marginBottom: "1.5em"
        }}
      >
        <h4>DEMAIS ACESSÓRIOS:</h4>
      </div>
      <div style={{ display: 'flex', flexDirection: "column", gap: "0.5em" }}>
        {itens.filter(Boolean).map((valor) => {
          return (
            <div key={valor} >
              <FormControlLabel
                value={valor}
                control={<Checkbox />}
                label={valor}
                labelPlacement="end"
                placeholder={valor}
                onChange={(e) => {
                  Valida(e);
                }}
              />
              {/* <input
              type="checkbox"
              placeholder={valor}
              onChange={(e) => {
                Valida(e);
              }}
            />
            <label s>⠀{valor}</label> */}
              <br />
            </div>
          );
        })}
      </div>
      <div
        style={{
          marginTop: "50px",
          display: "flex",
          justifyContent: "center",
          flexDirection: "Column",
          alignItems: "center",
          textAlign: "center",
        }}
      ></div>
    </div>
  );
};

export default CheckAcc;
