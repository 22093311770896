import React from "react";
import { Grid, Button, Drawer, Box } from "@mui/material";
import { makeStyles } from "@material-ui/styles";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles({
  drawer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "250px",
    height: "100%",
  },
  closeButton: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
});



const SidebarMenu = ({ open, close }) => {
  const classes = useStyles();
  return (
    <Drawer open={open} onClose={close} anchor="right">
      <Box className={classes.closeButton}>
        <Button onClick={close}>
          <CloseIcon />
        </Button>
      </Box>
      <Box className={classes.drawer}>
        <Button
          onClick={() => {
            window.location.href = "/";
          }}
        >
          Home
        </Button>
        <Button
          onClick={() => {
            window.location.href = "/contact";
          }}
        >
          Contato
        </Button>
        <Button
          onClick={() => {
            window.location.href = "/about";
          }}
        >
          Empresa
        </Button>
        <Button
          onClick={() => {
            window.location.href = "/#videos";
          }}
        >
          Vídeos
        </Button>
        <Button
          onClick={() => {
            window.location.href = "/revendedores";
          }}
        >
          Revendedores
        </Button>
        <Button
          onClick={() => {
            window.location.href = "/sejarevendedor";
          }}
        >
          Seja um revendedor
        </Button>
      </Box>
    </Drawer>
  );
};

export default SidebarMenu;
