import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import theme from "../theme/Theme";
import {
    Grid,
    Button,
    Typography,
    Divider,
    TextField,
} from "@mui/material";
import "../App.css";
import NewNavbar from "../components/Navbar/Navbar";
import InputMask from "react-input-mask";
import Swal from "sweetalert2";
import "../components/NewModal/newModal.css";
import Newfooter from "../components/Footer/Footer";
import api from "../utils/axiosapi";
//ICONS
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import FormsTitle from "../components/FormsTitle/index";


const useStyles = makeStyles({
    root: {
        position: 'relative',
        minHeight: '100vh',
    },
    contentWrap: {
        width: "100%",
        minHeight: "100vh",
        paddingBottom: '80px',
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "10px",
        //mobile
        [theme.breakpoints.down("sm")]: {
            margin: "20px 0px 80px 0px",
            paddingBottom: '160px',

        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#EAEDED",
            },
        },
    },
    content: {
        width: "50%",
        display: "flex",
        flexWrap: "wrap",
        //mobile
        [theme.breakpoints.down("md")]: {
            width: "80%",

        },
    },
    box: {
        width: "100%",
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        position: "relative",
    },
    formBoxContainer: {
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        //mobile
        [theme.breakpoints.down("md")]: {
            alignItems: "center",
            justifyContent: "flex-start",
            width: "80%"
        },
    },
    cardContainer: {
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
    },
    informationBox: {
        display: "flex",
        gap: "15px",
        [theme.breakpoints.down("md")]: {
            marginTop: "2em",
        },
    },
    privatLogoMobile: {
        [theme.breakpoints.down("md")]: {
            marginTop: '2em',
            marginBottom: '2em'
        },
    },
    titleDivider: {
        width: "60%",
        color: "#ececec",
        display: "block",
        [theme.breakpoints.down("md")]: {
            display: "none"
        },
    },
    containerForm: {
        paddingTop: "2em",
        width: "100%",
        height: "100%",
        display: "flex",
        gap: "2em",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start"
    },
});

const SejaRevendedor = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [razaoSocial, setRazaoSocial] = useState("");
    const [cnpj, setCnpj] = useState("");
    const [telefone, setTelefone] = useState("");
    const [comentario, setComentario] = useState("");
    const [email, setEmail] = useState("");
    const [error, setError] = useState(false);

    const handleSubmit = async () => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        const phoneRegex = /^\([1-9]{2}\) [2-9][0-9]{3,4}-[0-9]{4}$/;
        const cnpjRegex = /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/;

        var validaEmail = emailRegex.test(email)
        var validaTelefone = phoneRegex.test(telefone)
        var validaCnpj = cnpjRegex.test(cnpj)

        if (validaEmail === false || validaTelefone === false || validaCnpj === false) {
            setError(true)
            return;
        }
        //reseta os erros
        setError(false)

        var url = "orc/Revendedor";
        var data = {
            razaoSocial: razaoSocial,
            cnpj: cnpj,
            telefone: telefone,
            email: email,
            mensagem: comentario,
        };
        await api.post(url, data).then((retorno) => {
            if (retorno.data.error) {
                Swal.fire({
                    icon: "error",
                    title: retorno.data.error,
                    confirmButtonColor: theme.palette.primary.main,
                });
                return;
            }
            if (retorno.data) {
                Swal.fire({
                    title: "Mensagem enviada!",
                    text: "Agradecemos o contato, entraremos em contato em breve!",
                    icon: "success",
                    confirmButtonText: "OK",
                    confirmButtonColor: theme.palette.primary.main,
                });
                return;
            }
            window.location.assign("/sejarevendedor");
            //   e.preventDefault();
        });
    };

    return (
        <div className={classes.root}>
            <NewNavbar position="initial" />
            <div className={classes.contentWrap}>
                <div className={classes.content}>
                    <FormsTitle title="Seja um revendedor" />
                    <Divider style={{ width: "60%", color: "#ececec", marginTop: "1em" }} />
                    <div className={classes.containerForm}>
                        <div style={{ padding: "5px" }}>
                            <Typography variant="h6">Seja um revendedor Mestra</Typography>
                            <Typography variant="body1" style={{ color: "gray", marginTop: "1em" }}>Preencha o formulário para enviar sua solicitação</Typography>
                        </div>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    required
                                    id="outlined-basic"
                                    label="Razão social"
                                    onChange={(e) => setRazaoSocial(e.target.value)}
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <InputMask
                                    mask="99.999.999/9999-99"
                                    // value={this.state.phone}
                                    disabled={false}
                                    maskChar=" "
                                    onChange={(e) => setCnpj(e.target.value)}
                                >
                                    {() => (
                                        <TextField
                                            required
                                            id="outlined-basic"
                                            label="CNPJ"
                                            variant="outlined"
                                            fullWidth
                                        />)}
                                </InputMask>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <InputMask
                                    mask="(99) 99999-9999"
                                    // value={this.state.phone}
                                    disabled={false}
                                    maskChar=" "
                                    onChange={(e) => setTelefone(e.target.value)}
                                >
                                    {() => (
                                        <TextField
                                            fullWidth
                                            required
                                            id="outlined-basic"
                                            label={t("telefone")}
                                            variant="outlined"
                                        />
                                    )}
                                </InputMask>

                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    required
                                    id="outlined-basic"
                                    label="Email"
                                    variant="outlined"
                                    onChange={(e) => setEmail(e.target.value)}
                                    fullWidth
                                />

                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    multiline
                                    rows={4}
                                    id="outlined-basic"
                                    label={t("mensagem")}
                                    onChange={(e) => setComentario(e.target.value)}
                                    variant="outlined"
                                    fullWidth
                                />
                                {error ? <p style={{ marginTop: "1em", color: "red", fontSize: "12px" }}>Por favor, preencha os campos com valores válidos.</p> : null}
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "flex-end",
                                padding: "10px",
                            }}
                        >
                            <Button
                                style={{ width: "200px" }}
                                variant="outlined"
                                type="submit"
                                onClick={() => handleSubmit()}
                            >
                                {t("send")}
                            </Button>
                        </Grid>
                        <Grid container className={classes.informationBox}>
                            <Grid item xs={12} style={{ display: "flex", gap: "10px" }}>
                                <LocationOnIcon />
                                <Typography>
                                    Av. Vírgilio Franceschi - 905 - Dist. Industrial -
                                    Pederneiras - SP - CEP: 17280-000
                                </Typography>
                            </Grid>
                            <Grid item xs={12} style={{ display: "flex", gap: "10px" }}>
                                <WhatsAppIcon />
                                <Typography>+55 (14) 3252-2918</Typography>
                            </Grid>
                            <Grid item xs={12} style={{ display: "flex", gap: "10px" }}>
                                <LocalPhoneIcon />
                                <Typography>+55 (14) 99853-2123</Typography>
                            </Grid>
                            <Grid item xs={12} style={{ display: "flex", gap: "10px" }}>
                                <EmailIcon />
                                <Typography>example@gmail.com</Typography>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
            <Newfooter contact />
        </div>
    );
};

export default SejaRevendedor;
