import styled from "styled-components";
import ReactPlayer from 'react-player'
import { Typography, Button } from "@mui/material";


export const Container = styled.div`
    width: 250px;
    height: fit-content;
    background-color: white;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
    color: black;
    display: flex;
    flex-direction: column;
    gap: 2em;
    justify-content: center;
    align-items: flex-start;
    border-radius: 2px;

    @media (max-width: 900px) {
        width: 250px;
  
      }
`;

export const Content = styled.div`
    padding-inline: 2em;
    padding-bottom: 2em;
    display: flex;
    flex-direction: column;
    gap: 2em;

    @media (max-width: 900px) {
       justify-content: center;
       align-items: center;
  
      }

`;


export default function MediaCard({ title, link }) {
    return (
        <Container>
            <ReactPlayer url={link} width="250px" height="200px" />
            <Content>
                <Typography style={{ fontWeight: "bold" }}>{title}</Typography>
                <Button variant="outlined" onClick={() => {
                    window.open(
                        link
                    );
                }}>Ver video</Button>
            </Content>
        </Container>
    );
}