/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import CustomPage from "../../components/CustomPage/CustomPage";
import { useTranslation } from "react-i18next";

// ESTOFADO/PISO
// visao default
import interior from "../../data/images/customLancha160/interior.png";
import seatBlue from "../../data/images/customLancha160/interiorEstofadoAzul.png";
import seatWhite from "../../data/images/customLancha160/interiorEstofadoBranco.png";
import seatTerracota from "../../data/images/customLancha160/interiorEstofadoTerracotta.png";
import seatGray from "../../data/images/customLancha160/interiorEstofadoCinza.png";
import seatCaramelo from "../../data/images/customLancha160/interiorEstofadoCaramelo.png";
import seatBegeClaro from "../../data/images/customLancha160/interiorEstofadoBege.png";

import pisoBege from "../../data/images/customLancha160/interiorPisoBege.png";
import pisoCinza from "../../data/images/customLancha160/interiorPisoCinza.png";
import pisoCaramelo from "../../data/images/customLancha160/interiorPisoCaramelo.png";
import pisoTerracotta from "../../data/images/customLancha160/interiorPisoTerracotta.png";
import pisoTerracottaHW from "../../data/images/customLancha160/interiorPisoTerracottaHW.png";
import pisoAreiaHW from "../../data/images/customLancha160/interiorPisoAreiaHW.png";
import pisoCinzaHW from "../../data/images/customLancha160/interiorPisoCinzaHW.png";
import pisoCarameloHW from "../../data/images/customLancha160/interiorPisoCarameloHW.png";

//casco da visao do interior
import interiorCascoAzul from "../../data/images/customLancha160/interiorCascoAzul.png";
import interiorCascoPreto from "../../data/images/customLancha160/interiorCascoPreto.png";
import interiorCascoBranco from "../../data/images/customLancha160/interiorCascoBranco.png";
import interiorCascoCinza from "../../data/images/customLancha160/interiorCascoCinza.png";
import interiorCascoVermelho from "../../data/images/customLancha160/interiorCascoVermelho.png";
import interiorCascoVerde from "../../data/images/customLancha160/interiorCascoVerde.png";
import interiorCascoCinzaEscuro from "../../data/images/customLancha160/interiorCascoCinzaEscuro.png";

import interiorCascoAzul3 from "../../data/images/customLancha160/interiorCascoAzul3.png";
import interiorCascoPreto3 from "../../data/images/customLancha160/interiorCascoPreto3.png";
import interiorCascoBranco3 from "../../data/images/customLancha160/interiorCascoBranco3.png";
import interiorCascoCinza3 from "../../data/images/customLancha160/interiorCascoCinza3.png";
import interiorCascoVermelho3 from "../../data/images/customLancha160/interiorCascoVermelho3.png";
import interiorCascoVerde3 from "../../data/images/customLancha160/interiorCascoVerde3.png";
import interiorCascoCinzaEscuro3 from "../../data/images/customLancha160/interiorCascoCinzaEscuro3.png";

// visao 1 interior
import visao1Estofado from "../../data/images/customLancha160/visao1Estofado.png";
import estofadoTerracotta2 from "../../data/images/customLancha160/estofadoTerracotta2.png";
import estofadoBranco2 from "../../data/images/customLancha160/estofadoBranco2.png";
import estofadoAzul2 from "../../data/images/customLancha160/estofadoAzul2.png";
import estofadoCinza2 from "../../data/images/customLancha160/estofadoCinza2.png";
import estofadoCaramelo2 from "../../data/images/customLancha160/estofadoCaramelo2.png";
import estofadoBegeClaro2 from "../../data/images/customLancha160/estofadoBege2.png";

import pisoBege2 from "../../data/images/customLancha160/visao1PisoBege.png";
import pisoCinza2 from "../../data/images/customLancha160/visao1PisoCinza.png";
import pisoCaramelo2 from "../../data/images/customLancha160/visao1PisoCaramelo.png";
import pisoTerracotta2 from "../../data/images/customLancha160/visao1PisoTerracotta.png";

import pisoTerracottaHW2 from "../../data/images/customLancha160/visao1PisoTerracottaHW.png";
import pisoAreiaHW2 from "../../data/images/customLancha160/visao1PisoAreiaHW.png";
import pisoCinzaHW2 from "../../data/images/customLancha160/visao1PisoCinzaHW.png";
import pisoCarameloHW2 from "../../data/images/customLancha160/visao1PisoCarameloHW.png";

// visao 2 interior
import visao2Estofado from "../../data/images/customLancha160/visao2Estofado.png";
import estofadoTerracotta3 from "../../data/images/customLancha160/estofadoTerracotta3.png";
import estofadoBranco3 from "../../data/images/customLancha160/estofadoBranco3.png";
import estofadoAzul3 from "../../data/images/customLancha160/estofadoAzul3.png";
import estofadoCinza3 from "../../data/images/customLancha160/estofadoCinza3.png";
import estofadoCaramelo3 from "../../data/images/customLancha160/estofadoCaramelo3.png";
import estofadoBegeClaro3 from "../../data/images/customLancha160/estofadoBege3.png";

import pisoBege3 from "../../data/images/customLancha160/visao2PisoBege.png";
import pisoCinza3 from "../../data/images/customLancha160/visao2PisoCinza.png";
import pisoCaramelo3 from "../../data/images/customLancha160/visao2PisoCaramelo.png";
import pisoTerracotta3 from "../../data/images/customLancha160/visao2PisoTerracotta.png";
import pisoTerracottaHW3 from "../../data/images/customLancha160/visao2PisoTerracottaHW.png";
import pisoAreiaHW3 from "../../data/images/customLancha160/visao2PisoAreiaHW.png";
import pisoCinzaHW3 from "../../data/images/customLancha160/visao2PisoCinzaHW.png";
import pisoCarameloHW3 from "../../data/images/customLancha160/visao2PisoCarameloHW.png";

//visao 3 interior
import visao3Estofado from "../../data/images/customLancha160/visao3Estofado.png";
import estofadoTerracotta4 from "../../data/images/customLancha160/estofadoTerracotta4.png";
import estofadoBranco4 from "../../data/images/customLancha160/estofadoBranco4.png";
import estofadoAzul4 from "../../data/images/customLancha160/estofadoAzul4.png";
import estofadoCinza4 from "../../data/images/customLancha160/estofadoCinza4.png";
import estofadoCaramelo4 from "../../data/images/customLancha160/estofadoCaramelo4.png";
import estofadoBegeClaro4 from "../../data/images/customLancha160/estofadoBege4.png";

import pisoBege4 from "../../data/images/customLancha160/visao3PisoBege.png";
import pisoCinza4 from "../../data/images/customLancha160/visao3PisoCinza.png";
import pisoCaramelo4 from "../../data/images/customLancha160/visao3PisoCaramelo.png";
import pisoTerracotta4 from "../../data/images/customLancha160/visao3PisoTerracotta.png";
import pisoTerracottaHW4 from "../../data/images/customLancha160/visao3PisoTerracottaHW.png";
import pisoAreiaHW4 from "../../data/images/customLancha160/visao3PisoAreiaHW.png";
import pisoCinzaHW4 from "../../data/images/customLancha160/visao3PisoCinzaHW.png";
import pisoCarameloHW4 from "../../data/images/customLancha160/visao3PisoCarameloHW.png";

// CASCO E CORES

import fotoCasco from "./../../data/images/customLancha160/fotoBasePNG.png";
import visao1 from "./../../data/images/customLancha160/visao1.png";
import visao2 from "./../../data/images/customLancha160/visao2.png";

// cores default
import colorRed from "../../data/images/customLancha160/cascoVermelho.png";
import colorDarkGray from "../../data/images/customLancha160/cascoCinzaEscuro.png";
import colorGray from "../../data/images/customLancha160/cascoCinza.png";
import colorGreen from "../../data/images/customLancha160/cascoVerde.png";
import colorBlack from "../../data/images/customLancha160/cascoPreto.png";
import colorBlue from "../../data/images/customLancha160/cascoAzul.png";
import colorWhite from "../../data/images/customLancha160/cascoBranco.png";

//default cor do estofado e piso detalhes
import visaoDefaultEstofadoTerracotta from "../../data/images/customLancha160/visaoDefaultEstofadoTerracotta.png";
import visaoDefaultEstofadoAzul from "../../data/images/customLancha160/visaoDefaultEstofadoAzul.png";
import visaoDefaultEstofadoCinza from "../../data/images/customLancha160/visaoDefaultEstofadoCinza.png";
import visaoDefaultEstofadoCaramelo from "../../data/images/customLancha160/visaoDefaultEstofadoCaramelo.png";
import visaoDefaultEstofadoBege from "../../data/images/customLancha160/visaoDefaultEstofadoBege.png";
import visaoDefaultEstofadoBranco from "../../data/images/customLancha160/visaoDefaultEstofadoBranco.png";

import visao1EstofadoTerracotta from "../../data/images/customLancha160/visao1EstofadoTerracotta.png";
import visao1EstofadoBranco from "../../data/images/customLancha160/visao1EstofadoBranco.png";
import visao1EstofadoAzul from "../../data/images/customLancha160/visao1EstofadoAzul.png";
import visao1EstofadoCinza from "../../data/images/customLancha160/visao1EstofadoCinza.png";
import visao1EstofadoCaramelo from "../../data/images/customLancha160/visao1EstofadoCaramelo.png";
import visao1EstofadoBege from "../../data/images/customLancha160/visao1EstofadoBege.png";

import visao2EstofadoTerracotta from "../../data/images/customLancha160/visao2EstofadoTerracotta.png";
import visao2EstofadoBranco from "../../data/images/customLancha160/visao2EstofadoBranco.png";
import visao2EstofadoAzul from "../../data/images/customLancha160/visao2EstofadoAzul.png";
import visao2EstofadoCinza from "../../data/images/customLancha160/visao2EstofadoCinza.png";
import visao2EstofadoCaramelo from "../../data/images/customLancha160/visao2EstofadoCaramelo.png";
import visao2EstofadoBege from "../../data/images/customLancha160/visao2EstofadoBege.png";

// cores casco visao 1
import visao1Vermelho from "./../../data/images/customLancha160/visao1CascoVermelho.png";
import visao1Branco from "./../../data/images/customLancha160/visao1CascoBranco.png";
import visao1Azul from "./../../data/images/customLancha160/visao1CascoAzul.png";
import visao1Verde from "./../../data/images/customLancha160/visao1CascoVerde.png";
import visao1CinzaEscuro from "./../../data/images/customLancha160/visao1CascoCinzaEscuro.png";
import visao1Preto from "./../../data/images/customLancha160/visao1CascoPreto.png";
import visao1Cinza from "./../../data/images/customLancha160/visao1CascoCinza.png";

//cores casco visao 2
import visao2Vermelho from "./../../data/images/customLancha160/visao2CascoVermelho.png";
import visao2Azul from "./../../data/images/customLancha160/visao2CascoAzul.png";
import visao2Verde from "./../../data/images/customLancha160/visao2CascoVerde.png";
import visao2CinzaEscuro from "./../../data/images/customLancha160/visao2CascoCinzaEscuro.png";
import visao2Preto from "./../../data/images/customLancha160/visao2CascoPreto.png";
import visao2Cinza from "./../../data/images/customLancha160/visao2CascoCinza.png";
import visao2Branco from "./../../data/images/customLancha160/visao2CascoBranco.png";

export default () => {
  const { t, i18n } = useTranslation();

  const itensSerie = [
    t("lixeiraAcrilico"),
    t("armarioAcrilico"),
    t("cristaleiraAcrilico"),
    t("bocalCombustivel"),
    t("bocalAguaDoce"),
    t("portaCoposInox"),
    t("escadaDePopaInox"),
    t("kitTorneiraPia"),
    t("mesaFibraSuporteInox"),
    t("luzesNavegacao"),
    t("luzProaBicolor"),
    t("luzPopaMastro"),
    t("luzesGabinete"),
    t("sistemaEletrico"),
    t("duchaPopa"),
    t("estofamentoCourvim"),
    t("paiosProa"),
    t("paiosCockPit"),
    t("compartimentoBoiaCircular"),
    t("portaPopaInox"),
    t("sistemaHidraulico"),
    t("vasoSanitario"),
    t("rollerAncora")
  ];

  const fichaTecnica = [
    t("combustivelTanque") + '⠀43 L',
    t("tanqueAguaDoce") + "⠀28 L",
    t("pesoSemMotor") + "⠀485 KG",
    t("pessoas") + "⠀1+3",
    t("motorizacaoCentroRabeta") + `⠀${t("motorizacao40a60")}`
  ]

  return (
    <>
      <CustomPage
        // ESTOFADO CORES
        mestra160
        mercury60hp
        pisoOptions
        mercruiser60hp
        estofadoTerracota={seatTerracota}
        estofadoAzul={seatBlue}
        estofadoBranco={seatWhite}
        estofadoBegeClaro={seatBegeClaro}
        estofadoCaramelo={seatCaramelo}
        estofadoCinza={seatGray}
        interiorCascoAzul={interiorCascoAzul}
        interiorCascoPreto={interiorCascoPreto}
        interiorCascoCinzaEscuro={interiorCascoCinzaEscuro}
        interiorCascoBranco={interiorCascoBranco}
        interiorCascoVermelho={interiorCascoVermelho}
        interiorCascoVerde={interiorCascoVerde}
        interiorCascoCinza={interiorCascoCinza}
        interiorCascoAzul3={interiorCascoAzul3}
        interiorCascoPreto3={interiorCascoPreto3}
        interiorCascoCinzaEscuro3={interiorCascoCinzaEscuro3}
        interiorCascoBranco3={interiorCascoBranco3}
        interiorCascoVermelho3={interiorCascoVermelho3}
        interiorCascoVerde3={interiorCascoVerde3}
        interiorCascoCinza3={interiorCascoCinza3}
        // detalhes primeira visao exterior pisos e estofado
        visaoDefaultEstofadoTerracotta={visaoDefaultEstofadoTerracotta}
        visaoDefaultEstofadoAzul={visaoDefaultEstofadoAzul}
        visaoDefaultEstofadoCinza={visaoDefaultEstofadoCinza}
        visaoDefaultEstofadoCaramelo={visaoDefaultEstofadoCaramelo}
        visaoDefaultEstofadoBege={visaoDefaultEstofadoBege}
        visaoDefaultEstofadoBranco={visaoDefaultEstofadoBranco}
        visao1EstofadoTerracotta={visao1EstofadoTerracotta}
        visao1EstofadoBranco={visao1EstofadoBranco}
        visao1EstofadoAzul={visao1EstofadoAzul}
        visao1EstofadoCinza={visao1EstofadoCinza}
        visao1EstofadoCaramelo={visao1EstofadoCaramelo}
        visao1EstofadoBege={visao1EstofadoBege}
        visao2EstofadoTerracotta={visao2EstofadoTerracotta}
        visao2EstofadoBranco={visao2EstofadoBranco}
        visao2EstofadoAzul={visao2EstofadoAzul}
        visao2EstofadoCinza={visao2EstofadoCinza}
        visao2EstofadoCaramelo={visao2EstofadoCaramelo}
        visao2EstofadoBege={visao2EstofadoBege}
        // visoes
        visao1={visao1}
        visao2={visao2}
        // VISOES DO ESTOFADO
        coresEstofadoDefault={interior}
        visao1Estofado={visao1Estofado}
        visao2Estofado={visao2Estofado}
        visao3Estofado={visao3Estofado}
        // VISOES DO PISO
        //visao default
        pisoTerracotta={pisoTerracotta}
        pisoCinza={pisoCinza}
        pisoCaramelo={pisoCaramelo}
        pisoBege={pisoBege}
        pisoTerracottaHW={pisoTerracottaHW}
        pisoAreiaHW={pisoAreiaHW}
        pisoCinzaHW={pisoCinzaHW}
        pisoCarameloHW={pisoCarameloHW}
        // visao 1
        pisoTerracotta2={pisoTerracotta2}
        pisoCinza2={pisoCinza2}
        pisoCaramelo2={pisoCaramelo2}
        pisoBege2={pisoBege2}
        pisoTerracottaHW2={pisoTerracottaHW2}
        pisoAreiaHW2={pisoAreiaHW2}
        pisoCinzaHW2={pisoCinzaHW2}
        pisoCarameloHW2={pisoCarameloHW2}
        //visao 2
        pisoTerracotta3={pisoTerracotta3}
        pisoCinza3={pisoCinza3}
        pisoCaramelo3={pisoCaramelo3}
        pisoBege3={pisoBege3}
        pisoTerracottaHW3={pisoTerracottaHW3}
        pisoAreiaHW3={pisoAreiaHW3}
        pisoCinzaHW3={pisoCinzaHW3}
        pisoCarameloHW3={pisoCarameloHW3}
        //visao 3
        pisoTerracotta4={pisoTerracotta4}
        pisoCinza4={pisoCinza4}
        pisoCaramelo4={pisoCaramelo4}
        pisoBege4={pisoBege4}
        pisoTerracottaHW4={pisoTerracottaHW4}
        pisoAreiaHW4={pisoAreiaHW4}
        pisoCinzaHW4={pisoCinzaHW4}
        pisoCarameloHW4={pisoCarameloHW4}
        // CORES DO CASCO DEFAULT
        colorBlue={colorBlue}
        colorRed={colorRed}
        colorDarkGray={colorDarkGray}
        colorWhite={colorWhite}
        colorGray={colorGray}
        colorGreen={colorGreen}
        colorBlack={colorBlack}
        // VISOES DA COR VERMELHA
        visaoCoresRed2={visao1Vermelho}
        visaoCoresRed3={visao2Vermelho}
        // VISOES DA COR BRANCA
        visaoCoresWhite2={visao1Branco}
        visaoCoresWhite3={visao2Branco}
        //VISOES DA COR AZUL
        visaoCoresBlue2={visao1Azul}
        visaoCoresBlue3={visao2Azul}
        //VISOES DA COR VERDE
        visaoCoresGreen2={visao1Verde}
        visaoCoresGreen3={visao2Verde}
        //VISOES DA COR CINZA
        visaoCoresGray2={visao1Cinza}
        visaoCoresGray3={visao2Cinza}
        //VISOES DA COR CINZA ESCURO
        visaoCoresDarkGray2={visao1CinzaEscuro}
        visaoCoresDarkGray3={visao2CinzaEscuro}
        //VISOES DA COR PRETO
        visaoCoresBlack2={visao1Preto}
        visaoCoresBlack3={visao2Preto}
        // VISOES DO ESTOFADO TERRACOTA
        estofadoTerracotta2={estofadoTerracotta2}
        estofadoTerracotta3={estofadoTerracotta3}
        estofadoTerracotta4={estofadoTerracotta4}
        // VISOES DO ESTOFADO BRANCO
        estofadoBranco2={estofadoBranco2}
        estofadoBranco3={estofadoBranco3}
        estofadoBranco4={estofadoBranco4}
        // VISOES DO ESTOFADO AZUL
        estofadoAzul2={estofadoAzul2}
        estofadoAzul3={estofadoAzul3}
        estofadoAzul4={estofadoAzul4}
        // VISOES DO ESTOFADO CINZA
        estofadoCinza2={estofadoCinza2}
        estofadoCinza3={estofadoCinza3}
        estofadoCinza4={estofadoCinza4}
        // VISOES DO ESTOFADO CARAMELO
        estofadoCaramelo2={estofadoCaramelo2}
        estofadoCaramelo3={estofadoCaramelo3}
        estofadoCaramelo4={estofadoCaramelo4}
        // VISOES DO ESTOFADO BEGE CLARO
        estofadoBegeClaro2={estofadoBegeClaro2}
        estofadoBegeClaro3={estofadoBegeClaro3}
        estofadoBegeClaro4={estofadoBegeClaro4}
        // imgVisaoCasco={imgVisaoCasco}
        title="Mestra 160"
        // subtitle={t("mestra160Subtitle")}
        // coresEstofado={coresEstofado}
        coresCascoDefault={fotoCasco}
        comprimento="4,74 M"
        boca="1,70 M"
        calado="0,37 M"
        // li1={t("anguloVCopa")}
        li2={t("combustivelTanque")}
        li3={t("tanqueAguaDoce")}
        li4={t("pesoSemMotor")}
        li5={t("pessoas")}
        li8={t("motorizacaoCentroRabeta")}
        fichaTecnica={fichaTecnica}
        // li6={t("camarotes")}
        // li7={t("peDireitoCabine")}

        mercruiser60="MerCruiser 60 HP 4 tempos"
        // mercruiser50="MerCruiser 50 HP Carburado"
        // angulo="19°"
        combustivel="43 L"
        tanqueAguaDoce="28 L"
        peso="485 KG"
        pessoas="1+3"
        // camarotes="1"
        // cabine="1,45 M"
        motorizacao={t("motorizacao40a60")}
        itensSerie={itensSerie}
        itemFabrica1={t("motorizacaoFabricaSubtitle")}
        itemFabrica2={t("sistemaDirecao")}
        itemFabrica3={t("bateria")}
        itemFabrica4={t("bombaPorao")}
        itemFabrica5={t("chaveGeral")}
        itemFabrica6={t("automaticoBombaPorao")}
        itemFabrica7={t("bombaPressurizacao")}
        itemFabrica8={t("volante")}
        itemFabrica9={t("filtros")}
        itemFabrica10={t("relogioMontagem")}
      />
    </>
  );
};
