
import image1 from "./images/mercury40hp.png"



export const Mercury40hp= [
    {
      image:
        image1,
        description: "DESCRIÇÃO AQUI SOBRE O PRODUTO"
    },
  ];
  