import { Cookies } from "react-cookie";
import axios from "axios";

//Cookie
const acess_token = new Cookies();


if (acess_token.get("MestraAdmin")) {
  var token = acess_token.get("MestraAdmin");
  // console.log("pegou o token")
}

var bearer = `Bearer ${token}`;

//"http://127.0.0.1:3004",
//http://23.92.65.171:3000
// https://mestra-backend.herokuapp.com/
// http://104.251.209.171:3000/
const api = axios.create({
  baseURL: "http://104.251.209.171:3000/"
  // headers: { "Access-Control-Allow-Origin": "*" },
});
//  console.log("bearer:", bearer);
//  console.log("token", token);
api.defaults.headers.common["Authorization"] = bearer;
api.defaults.headers.common["Content-Type"] = "application/json";
api.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
api.defaults.headers.common["Accept"] = "application/json, text/plain, **";

export default api;

/*
Como usar...

você deverá importar este componente para seu arquivo

ao inves de usar 
await axios.post
ou
await axios.put
ou 
await axios.get
ou o axios de qualquer forma, vc deverá usar

await api.post, await api.put, await api.get

ou seja, trocar axios por api
*/
