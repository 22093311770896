import React from "react";
import ModelPage from "../../components/ModelPage/ModelPage";
import mestra200Modelo from "../../data/images/desktop/model200_desktop.jpg";
import mestra200ModeloMobile from "../../data/images/mobile/model200_mobile_v01.jpg";
import interior from "../../data/images/customLancha200/visao1Estofado.png";
import imgCarousel1 from "../../data/images/mestra200/carouselImg1.jpg";
import imgCarousel2 from "../../data/images/mestra200/carouselImg2.jpg";
import imgCarousel3 from "../../data/images/mestra200/carouselImg3.jpg";
import imgCarousel4 from "../../data/images/mestra200/carouselImg4.jpg";
import imgCarousel5 from "../../data/images/mestra200/carouselImg5.jpg";
import imgCarousel6 from "../../data/images/mestra200/carouselImg6.jpg";
import imgCarousel7 from "../../data/images/mestra200/carouselImg7.jpeg";
import imgCarousel8 from "../../data/images/mestra200/carouselImg8.jpeg";
import imgCarousel9 from "../../data/images/mestra200/carouselImg9.jpeg";
import imgCarousel10 from "../../data/images/mestra200/carouselImg10.jpeg";
import imgCarousel11 from "../../data/images/mestra200/carouselImg11.jpeg";
import imgCarousel12 from "../../data/images/mestra200/carouselImg12.jpeg";
import imgCarousel13 from "../../data/images/mestra200/carouselImg13.jpeg";
import imgCarousel14 from "../../data/images/mestra200/carouselImg14.jpeg";
import imgCarousel15 from "../../data/images/mestra200/carouselImg15.jpeg";
import imgCarousel16 from "../../data/images/mestra200/carouselImg16.jpeg";
import imgCarousel17 from "../../data/images/mestra200/carouselImg17.jpeg";
import imgCarousel18 from "../../data/images/mestra200/carouselImg18.jpeg";
import imgCarousel19 from "../../data/images/mestra200/carouselImg19.jpeg";
import imgCarousel20 from "../../data/images/mestra200/carouselImg20.jpeg";
import imgCarousel21 from "../../data/images/mestra200/carouselImg21.jpeg";
import imgCarousel22 from "../../data/images/mestra200/carouselImg22.jpeg";
import imgCarousel23 from "../../data/images/mestra200/carouselImg23.jpeg";
import imgCarousel24 from "../../data/images/mestra200/carouselImg24.jpeg";
import imgCarousel25 from "../../data/images/mestra200/carouselImg25.jpeg";

import imgDetails1 from "../../data/images/details230_240_2.jfif";
import imgDetails2 from "../../data/images/details230_240.jfif";
import model from "../../data/images/customLancha200/fotoBasePNG.png";

const ModelMestra200 = () => {

  const fichaProps = {
    mestra292: false,
    mestra322: false,
    mestra240: false,
    mestra230: false,
    mestra222: false,
    mestra212: true,
    mestra200: true,
    mestra198: false,
    mestra180: false,
    mestra160: false,
  };

  const imagens = [
    imgCarousel1,
    imgCarousel2,
    imgCarousel3,
    imgCarousel4,
    imgCarousel5,
    imgCarousel6,
    imgCarousel7,
    imgCarousel8,
    imgCarousel9,
    imgCarousel10,
    imgCarousel11,
    imgCarousel12,
    imgCarousel13,
    imgCarousel14,
    imgCarousel15,
    imgCarousel16,
    imgCarousel17,
    imgCarousel18,
    imgCarousel19,
    imgCarousel20,
    imgCarousel21,
    imgCarousel22,
    imgCarousel23,
    imgCarousel24,
    imgCarousel25,

  ]

  return (
    <ModelPage
      lastSectionWhite={true}
      mestra200
      mestra200Text
      mestra240
      fichaProps={fichaProps}
      length="5,80 M"
      width="2,35 M"
      draught="0,30 M"
      img={mestra200Modelo}
      mobileImage={mestra200ModeloMobile}
      imgInterior={interior}
      carouselImages={imagens}
      imgDetails1={imgDetails1}
      imgDetails2={imgDetails2}
      // imgDetails3={imgDetails3}
      // imgDetails4={imgDetails4}
      // imgDetails5={imgDetails5}
      // imgDetails6={imgDetails6}
      // imgDetails7={imgDetails7}
      // imgDetails8={imgDetails8}
      // imgDetails9={imgDetails9}
      // imgDetails10={imgDetails10}
      modelName="Mestra 200"
      modelURL="mestra200"
      model={model}
    />
  );
};

export default ModelMestra200;
