/* eslint-disable import/no-anonymous-default-export */
import React from 'react';
import CustomPage from '../../components/CustomPage/CustomPage';
import { defaultModel } from '../../data/models/mestra240/defaultModel';
import { useTranslation } from 'react-i18next';

// ESTOFADO/PISO
// visao default
import interior from '../../data/images/customLancha240/interior.png';
import seatBlue from '../../data/images/customLancha240/interiorEstofadoAzul.png';
import seatWhite from '../../data/images/customLancha240/interior.png';
import seatTerracota from '../../data/images/customLancha240/interiorEstofadoTerracotta.png';
import seatGray from '../../data/images/customLancha240/interiorEstofadoCinza.png';
import seatCaramelo from '../../data/images/customLancha240/interiorEstofadoCaramelo.png';
import seatBegeClaro from '../../data/images/customLancha240/interiorEstofadoBegeClaro.png';

import pisoBege from '../../data/images/customLancha240/interiorPisoBege.png';
import pisoCinza from '../../data/images/customLancha240/interiorPisoCinza.png';
import pisoCaramelo from '../../data/images/customLancha240/interiorPisoCaramelo.png';
import pisoTerracotta from '../../data/images/customLancha240/interiorPisoTerracotta.png';
import pisoTerracottaHW from '../../data/images/customLancha240/interiorPisoTerracottaHW.png';
import pisoAreiaHW from '../../data/images/customLancha240/interiorPisoAreiaHW.png';
import pisoCinzaHW from '../../data/images/customLancha240/interiorPisoCinzaHW.png';
import pisoCarameloHW from '../../data/images/customLancha240/interiorPisoCarameloHW.png';

// visao 1 interior
import visao1Estofado from '../../data/images/customLancha240/visao1Estofado.png';
import estofadoTerracotta2 from '../../data/images/customLancha240/visao1EstofadoTerracotta.png';
import estofadoBranco2 from '../../data/images/customLancha240/visao1EstofadoBranco.png';
import estofadoAzul2 from '../../data/images/customLancha240/visao1EstofadoAzul.png';
import estofadoCinza2 from '../../data/images/customLancha240/visao1EstofadoCinza.png';
import estofadoCaramelo2 from '../../data/images/customLancha240/visao1EstofadoCaramelo.png';
import estofadoBegeClaro2 from '../../data/images/customLancha240/visao1EstofadoBege.png';

import pisoBege2 from '../../data/images/customLancha240/visao1PisoBege.png';
import pisoCinza2 from '../../data/images/customLancha240/visao1PisoCinza.png';
import pisoCaramelo2 from '../../data/images/customLancha240/visao1PisoCaramelo.png';
import pisoTerracotta2 from '../../data/images/customLancha240/visao1PisoTerracotta.png';

import pisoTerracottaHW2 from '../../data/images/customLancha240/visao1PisoTerracottaHW.png';
import pisoAreiaHW2 from '../../data/images/customLancha240/visao1PisoAreiaHW.png';
import pisoCinzaHW2 from '../../data/images/customLancha240/visao1PisoCinzaHW.png';
import pisoCarameloHW2 from '../../data/images/customLancha240/visao1PisoCarameloHW.png';

// visao 2 interior
import visao2Estofado from '../../data/images/customLancha240/visao2Estofado.png';
import estofadoTerracotta3 from '../../data/images/customLancha240/visao2EstofadoTerracotta.png';
import estofadoBranco3 from '../../data/images/customLancha240/visao2EstofadoBranco.png';
import estofadoAzul3 from '../../data/images/customLancha240/visao2EstofadoAzul.png';
import estofadoCinza3 from '../../data/images/customLancha240/visao2EstofadoCinza.png';
import estofadoCaramelo3 from '../../data/images/customLancha240/visao2EstofadoCaramelo.png';
import estofadoBegeClaro3 from '../../data/images/customLancha240/visao2EstofadoBegeClaro.png';

import pisoBege3 from '../../data/images/customLancha240/visao2PisoBege.png';
import pisoCinza3 from '../../data/images/customLancha240/visao2PisoCinza.png';
import pisoCaramelo3 from '../../data/images/customLancha240/visao2PisoCaramelo.png';
import pisoTerracotta3 from '../../data/images/customLancha240/visao2PisoTerracotta.png';
import pisoTerracottaHW3 from '../../data/images/customLancha240/visao2PisoTerracottaHW.png';
import pisoAreiaHW3 from '../../data/images/customLancha240/visao2PisoAreiaHW.png';
import pisoCinzaHW3 from '../../data/images/customLancha240/visao2PisoCinzaHW.png';
import pisoCarameloHW3 from '../../data/images/customLancha240/visao2PisoCarameloHW.png';

//visao 3 interior
import visao3Estofado from '../../data/images/customLancha240/visao3Estofado.png';
import estofadoTerracotta4 from '../../data/images/customLancha240/visao3EstofadoTerracotta.png';
import estofadoBranco4 from '../../data/images/customLancha240/visao3EstofadoBranco.png';
import estofadoAzul4 from '../../data/images/customLancha240/visao3EstofadoAzul.png';
import estofadoCinza4 from '../../data/images/customLancha240/visao3EstofadoCinza.png';
import estofadoCaramelo4 from '../../data/images/customLancha240/visao3EstofadoCaramelo.png';
import estofadoBegeClaro4 from '../../data/images/customLancha240/visao3EstofadoBegeClaro.png';

import pisoBege4 from '../../data/images/customLancha240/visao3PisoBege.png';
import pisoCinza4 from '../../data/images/customLancha240/visao3PisoCinza.png';
import pisoCaramelo4 from '../../data/images/customLancha240/visao3PisoCaramelo.png';
import pisoTerracotta4 from '../../data/images/customLancha240/visao3PisoTerracotta.png';
import pisoTerracottaHW4 from '../../data/images/customLancha240/visao3PisoTerracottaHW.png';
import pisoAreiaHW4 from '../../data/images/customLancha240/visao3PisoAreiaHW.png';
import pisoCinzaHW4 from '../../data/images/customLancha240/visao3PisoCinzaHW.png';
import pisoCarameloHW4 from '../../data/images/customLancha240/visao3PisoCarameloHW.png';

// visao 4 interior

import visao4Estofado from '../../data/images/customLancha240/visao4Estofado.png';
import estofadoTerracotta5 from '../../data/images/customLancha240/visao4EstofadoTerracotta.png';
import estofadoBranco5 from '../../data/images/customLancha240/visao4EstofadoBranco.png';
import estofadoAzul5 from '../../data/images/customLancha240/visao4EstofadoAzul.png';
import estofadoCinza5 from '../../data/images/customLancha240/visao4EstofadoCinza.png';
import estofadoCaramelo5 from '../../data/images/customLancha240/visao4EstofadoCaramelo.png';
import estofadoBegeClaro5 from '../../data/images/customLancha240/visao4EstofadoBege.png';

import pisoBege5 from '../../data/images/customLancha240/visao4PisoBege.png';
import pisoCinza5 from '../../data/images/customLancha240/visao4PisoCinza.png';
import pisoCaramelo5 from '../../data/images/customLancha240/visao4PisoCaramelo.png';
import pisoTerracotta5 from '../../data/images/customLancha240/visao4PisoTerracotta.png';
import pisoTerracottaHW5 from '../../data/images/customLancha240/visao4PisoTerracottaHW.png';
import pisoAreiaHW5 from '../../data/images/customLancha240/visao4PisoAreiaHW.png';
import pisoCinzaHW5 from '../../data/images/customLancha240/visao4PisoCinzaHW.png';
import pisoCarameloHW5 from '../../data/images/customLancha240/visao4PisoCarameloHW.png';

// CASCO E CORES

import fotoCasco from './../../data/images/customLancha240/fotoBasePNG.png';

import visao1 from './../../data/images/customLancha240/visao1.png';
import visao2 from './../../data/images/customLancha240/visao2.png';

// cores default
import colorRed from '../../data/images/customLancha240/cascoVermelho.png';
import colorDarkGray from '../../data/images/customLancha240/cascoCinzaEscuro.png';
import colorGray from '../../data/images/customLancha240/cascoCinza.png';
import colorGreen from '../../data/images/customLancha240/cascoVerde.png';
import colorBlack from '../../data/images/customLancha240/cascoPreto.png';
import colorBlue from '../../data/images/customLancha240/cascoAzul.png';
import colorWhite from '../../data/images/customLancha240/cascoBranco.png';

//default cor do estofado e piso detalhes
import visaoDefaultEstofadoTerracotta from '../../data/images/customLancha240/visaoDefaultEstofadoTerracotta.png';
import visaoDefaultEstofadoAzul from '../../data/images/customLancha240/visaoDefaultEstofadoAzul.png';
import visaoDefaultEstofadoCinza from '../../data/images/customLancha240/visaoDefaultEstofadoCinza.png';
import visaoDefaultEstofadoCaramelo from '../../data/images/customLancha240/visaoDefaultEstofadoCaramelo.png';
import visaoDefaultEstofadoBege from '../../data/images/customLancha240/visaoDefaultEstofadoBege.png';

import visaoDefaultPisoTerracotta from '../../data/images/customLancha240/visaoDefaultPisoTerracotta.png';
import visaoDefaultPisoCinza from '../../data/images/customLancha240/visaoDefaultPisoCinza.png';
import visaoDefaultPisoCaramelo from '../../data/images/customLancha240/visaoDefaultPisoCaramelo.png';
import visaoDefaultPisoBege from '../../data/images/customLancha240/visaoDefaultPisoBege.png';

import visaoDefaultPisoTerracottaHW from '../../data/images/customLancha240/visaoDefaultPisoTerracottaHW.png';
import visaoDefaultPisoCinzaHW from '../../data/images/customLancha240/visaoDefaultPisoCinzaHW.png';
import visaoDefaultPisoCarameloHW from '../../data/images/customLancha240/visaoDefaultPisoCarameloHW.png';
import visaoDefaultPisoAreiaHW from '../../data/images/customLancha240/visaoDefaultPisoAreiaHW.png';

import interiorCascoAzul from '../../data/images/customLancha240/interiorCascoAzul.png';
import interiorCascoPreto from '../../data/images/customLancha240/interiorCascoPreto.png';
import interiorCascoBranco from '../../data/images/customLancha240/interiorCascoBranco.png';
import interiorCascoCinza from '../../data/images/customLancha240/interiorCascoCinza.png';
import interiorCascoVermelho from '../../data/images/customLancha240/interiorCascoVermelho.png';
import interiorCascoVerde from '../../data/images/customLancha240/interiorCascoVerde.png';
import interiorCascoCinzaEscuro from '../../data/images/customLancha240/interiorCascoCinzaEscuro.png';

// cores casco visao 1
import visao1Vermelho from './../../data/images/customLancha240/visao1CascoVermelho.png';
import visao1Azul from './../../data/images/customLancha240/visao1CascoAzul.png';
import visao1Verde from './../../data/images/customLancha240/visao1CascoVerde.png';
import visao1CinzaEscuro from './../../data/images/customLancha240/visao1CascoCinzaEscuro.png';
import visao1Preto from './../../data/images/customLancha240/visao1CascoPreto.png';
import visao1Cinza from './../../data/images/customLancha240/visao1CascoCinza.png';

//cores casco visao 2
import visao2Vermelho from './../../data/images/customLancha240/visao2CascoVermelho.png';
import visao2Azul from './../../data/images/customLancha240/visao2CascoAzul.png';
import visao2Verde from './../../data/images/customLancha240/visao2CascoVerde.png';
import visao2CinzaEscuro from './../../data/images/customLancha240/visao2CascoCinzaEscuro.png';
import visao2Preto from './../../data/images/customLancha240/visao2CascoPreto.png';
import visao2Cinza from './../../data/images/customLancha240/visao2CascoCinza.png';

export default () => {
  const { t, i18n } = useTranslation();
  const itensSerie = [
    t('lixeiraAcrilico'),
    t('armarioAcrilico'),
    t('cristaleiraAcrilico'),
    t('bocalCombustivel'),
    t('bocalAguaDoce'),
    t('portaCoposInox'),
    t('escadaDePopaInox'),
    t('kitTorneiraPia'),
    t('mesaFibraSuporteInox'),
    t('luzesNavegacao'),
    t('luzProaBicolor'),
    t('luzPopaMastro'),
    t('luzesGabinete'),
    t('sistemaEletrico'),
    t('duchaPopa'),
    t('estofamentoCourvim'),
    t('paiosProa'),
    t('paiosCockPit'),
    t('compartimentoBoiaCircular'),
    t('portaPopaInox'),
    t('sistemaHidraulico'),
    t('vasoSanitario'),
    t('rollerAncora'),
  ]
  const fichaTecnica = [
    t('combustivelTanque') + "⠀192 L",
    t('tanqueAguaDoce') + "⠀60 L",
    t('pesoSemMotor') + "⠀1150 KG",
    t('pessoas') + "⠀1+9",
    t("camarotes") + "⠀1",
    t("peDireitoCabine") + "⠀1,45 M",
    t("anguloVCopa") + "⠀19°",
    t('motorizacaoCentroRabeta') + `⠀${t('motorizacao200a280')}`,
  ]
  return (
    <>
      <CustomPage
        // ESTOFADO CORES
        mestra240
        pisoOptions
        estofadoTerracota={seatTerracota}
        estofadoAzul={seatBlue}
        estofadoBranco={seatWhite}
        estofadoBegeClaro={seatBegeClaro}
        estofadoCaramelo={seatCaramelo}
        estofadoCinza={seatGray}
        estofadoBlue={seatBlue}
        interiorCascoAzul={interiorCascoAzul}
        interiorCascoPreto={interiorCascoPreto}
        interiorCascoCinzaEscuro={interiorCascoCinzaEscuro}
        interiorCascoBranco={interiorCascoBranco}
        interiorCascoVermelho={interiorCascoVermelho}
        interiorCascoVerde={interiorCascoVerde}
        interiorCascoCinza={interiorCascoCinza}
        // detalhes primeira visao exterior pisos e estofado
        visaoDefaultEstofadoTerracotta={visaoDefaultEstofadoTerracotta}
        visaoDefaultEstofadoAzul={visaoDefaultEstofadoAzul}
        visaoDefaultEstofadoCinza={visaoDefaultEstofadoCinza}
        visaoDefaultEstofadoCaramelo={visaoDefaultEstofadoCaramelo}
        visaoDefaultEstofadoBege={visaoDefaultEstofadoBege}
        visaoDefaultPisoTerracotta={visaoDefaultPisoTerracotta}
        visaoDefaultPisoCinza={visaoDefaultPisoCinza}
        visaoDefaultPisoCaramelo={visaoDefaultPisoCaramelo}
        visaoDefaultPisoBege={visaoDefaultPisoBege}
        visaoDefaultPisoTerracottaHW={visaoDefaultPisoTerracottaHW}
        visaoDefaultPisoCinzaHW={visaoDefaultPisoCinzaHW}
        visaoDefaultPisoCarameloHW={visaoDefaultPisoCarameloHW}
        visaoDefaultPisoAreiaHW={visaoDefaultPisoAreiaHW}
        // visoes
        visao1={visao1}
        visao2={visao2}
        // VISOES DO ESTOFADO
        coresEstofadoDefault={interior}
        visao1Estofado={visao1Estofado}
        visao2Estofado={visao2Estofado}
        visao3Estofado={visao3Estofado}
        visao4Estofado={visao4Estofado}
        // VISOES DO PISO
        //visao default
        pisoTerracotta={pisoTerracotta}
        pisoCinza={pisoCinza}
        pisoCaramelo={pisoCaramelo}
        pisoBege={pisoBege}
        pisoTerracottaHW={pisoTerracottaHW}
        pisoAreiaHW={pisoAreiaHW}
        pisoCinzaHW={pisoCinzaHW}
        pisoCarameloHW={pisoCarameloHW}
        // visao 1
        pisoTerracotta2={pisoTerracotta2}
        pisoCinza2={pisoCinza2}
        pisoCaramelo2={pisoCaramelo2}
        pisoBege2={pisoBege2}
        pisoTerracottaHW2={pisoTerracottaHW2}
        pisoAreiaHW2={pisoAreiaHW2}
        pisoCinzaHW2={pisoCinzaHW2}
        pisoCarameloHW2={pisoCarameloHW2}
        //visao 2
        pisoTerracotta3={pisoTerracotta3}
        pisoCinza3={pisoCinza3}
        pisoCaramelo3={pisoCaramelo3}
        pisoBege3={pisoBege3}
        pisoTerracottaHW3={pisoTerracottaHW3}
        pisoAreiaHW3={pisoAreiaHW3}
        pisoCinzaHW3={pisoCinzaHW3}
        pisoCarameloHW3={pisoCarameloHW3}
        //visao 3
        pisoTerracotta4={pisoTerracotta4}
        pisoCinza4={pisoCinza4}
        pisoCaramelo4={pisoCaramelo4}
        pisoBege4={pisoBege4}
        pisoTerracottaHW4={pisoTerracottaHW4}
        pisoAreiaHW4={pisoAreiaHW4}
        pisoCinzaHW4={pisoCinzaHW4}
        pisoCarameloHW4={pisoCarameloHW4}
        //visao 4
        pisoTerracotta5={pisoTerracotta5}
        pisoCinza5={pisoCinza5}
        pisoCaramelo5={pisoCaramelo5}
        pisoBege5={pisoBege5}
        pisoTerracottaHW5={pisoTerracottaHW5}
        pisoAreiaHW5={pisoAreiaHW5}
        pisoCinzaHW5={pisoCinzaHW5}
        pisoCarameloHW5={pisoCarameloHW5}
        // CORES DO CASCO DEFAULT
        colorBlue={colorBlue}
        colorRed={colorRed}
        colorDarkGray={colorDarkGray}
        colorWhite={colorWhite}
        colorGray={colorGray}
        colorGreen={colorGreen}
        colorBlack={colorBlack}
        // VISOES DA COR VERMELHA
        visaoCoresRed2={visao1Vermelho}
        visaoCoresRed3={visao2Vermelho}
        //VISOES DA COR AZUL
        visaoCoresBlue2={visao1Azul}
        visaoCoresBlue3={visao2Azul}
        //VISOES DA COR VERDE
        visaoCoresGreen2={visao1Verde}
        visaoCoresGreen3={visao2Verde}
        //VISOES DA COR CINZA
        visaoCoresGray2={visao1Cinza}
        visaoCoresGray3={visao2Cinza}
        //VISOES DA COR CINZA ESCURO
        visaoCoresDarkGray2={visao1CinzaEscuro}
        visaoCoresDarkGray3={visao2CinzaEscuro}
        //VISOES DA COR PRETO
        visaoCoresBlack2={visao1Preto}
        visaoCoresBlack3={visao2Preto}
        // VISOES DO ESTOFADO TERRACOTA
        estofadoTerracotta2={estofadoTerracotta2}
        estofadoTerracotta3={estofadoTerracotta3}
        estofadoTerracotta4={estofadoTerracotta4}
        estofadoTerracotta5={estofadoTerracotta5}
        // VISOES DO ESTOFADO BRANCO
        estofadoBranco2={estofadoBranco2}
        estofadoBranco3={estofadoBranco3}
        estofadoBranco4={estofadoBranco4}
        estofadoBranco5={estofadoBranco5}
        // VISOES DO ESTOFADO AZUL
        estofadoAzul2={estofadoAzul2}
        estofadoAzul3={estofadoAzul3}
        estofadoAzul4={estofadoAzul4}
        estofadoAzul5={estofadoAzul5}
        // VISOES DO ESTOFADO CINZA
        estofadoCinza2={estofadoCinza2}
        estofadoCinza3={estofadoCinza3}
        estofadoCinza4={estofadoCinza4}
        estofadoCinza5={estofadoCinza5}
        // VISOES DO ESTOFADO CARAMELO
        estofadoCaramelo2={estofadoCaramelo2}
        estofadoCaramelo3={estofadoCaramelo3}
        estofadoCaramelo4={estofadoCaramelo4}
        estofadoCaramelo5={estofadoCaramelo5}
        // VISOES DO ESTOFADO BEGE CLARO
        estofadoBegeClaro2={estofadoBegeClaro2}
        estofadoBegeClaro3={estofadoBegeClaro3}
        estofadoBegeClaro4={estofadoBegeClaro4}
        estofadoBegeClaro5={estofadoBegeClaro5}
        // imgVisaoCasco={imgVisaoCasco}
        title="Mestra 240"
        subtitle={t('mestra240Subtitle')}
        // coresEstofado={coresEstofado}
        coresCascoDefault={fotoCasco}
        comprimento="7,00 M"
        boca="2,45 M"
        calado="0,38 M"
        li1={t('anguloVCopa')}
        li2={t('combustivelTanque')}
        li3={t('tanqueAguaDoce')}
        li4={t('pesoSemMotor')}
        li5={t('pessoas')}
        li6={t('camarotes')}
        li7={t('peDireitoCabine')}
        li8={t('motorizacaoCentroRabeta')}
        angulo="19°"
        combustivel="192 L"
        tanqueAguaDoce="60 L"
        peso="1150 KG"
        pessoas="1+9"
        camarotes="1"
        cabine="1,45 M"
        motorizacao={t('motorizacao200a280')}
        fichaTecnica={fichaTecnica}
        mercruiser250="MerCruiser 4.5L 250 HP Rabeta Alpha One"
        itensSerie={itensSerie}
        itemFabrica1={t('motorizacaoFabricaSubtitle')}
        itemFabrica2={t('sistemaDirecao')}
        itemFabrica3={t('bateria')}
        itemFabrica4={t('bombaPorao')}
        itemFabrica5={t('chaveGeral')}
        itemFabrica6={t('automaticoBombaPorao')}
        itemFabrica7={t('bombaPressurizacao')}
        itemFabrica8={t('volante')}
        itemFabrica9={t('filtros')}
        itemFabrica10={t('relogioMontagem')}
      />
    </>
  );
};
