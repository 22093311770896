
import image1 from './images/mercury_pro_xs_115.png'
import image2 from './images/mercury_pro_xs_115_2.jpg'
import image3 from "./images/mercury115.jpg"
export const Mercury115hp = [
    {
      image:
        image1,
        description: "Mercury 115 HP 4T CT"
    },
    {
      image:
        image2,
        description: "Mercury 115 HP 4T CT"
    },
    {
      image:
        image3,
        description: "Mercury 115 HP 4T CT"
    },
  ];
  