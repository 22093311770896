/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import CustomPage from "../../components/CustomPage/CustomPage";
import { useTranslation } from "react-i18next";

// ESTOFADO/PISO
// visao default
import interior from "../../data/images/customLancha230/interior.png";
import seatBlue from "../../data/images/customLancha230/interiorEstofadoAzul.png";
import seatWhite from "../../data/images/customLancha230/interior.png";
import seatTerracota from "../../data/images/customLancha230/interiorEstofadoTerracotta.png";
import seatGray from "../../data/images/customLancha230/interiorEstofadoCinza.png";
import seatCaramelo from "../../data/images/customLancha230/interiorEstofadoCaramelo.png";
import seatBegeClaro from "../../data/images/customLancha230/interiorEstofadoBege.png";

import pisoBege from "../../data/images/customLancha230/interiorPisoBege.png";
import pisoCinza from "../../data/images/customLancha230/interiorPisoCinza.png";
import pisoCaramelo from "../../data/images/customLancha230/interiorPisoCaramelo.png";
import pisoTerracotta from "../../data/images/customLancha230/interiorPisoTerracotta.png";
import pisoTerracottaHW from "../../data/images/customLancha230/interiorPisoTerracottaHW.png";
import pisoAreiaHW from "../../data/images/customLancha230/interiorPisoAreiaHW.png";
import pisoCinzaHW from "../../data/images/customLancha230/interiorPisoCinzaHW.png";
import pisoCarameloHW from "../../data/images/customLancha230/interiorPisoCarameloHW.png";

// visao 1 interior
import visao1Estofado from "../../data/images/customLancha230/visao1Estofado.png";
import estofadoTerracotta2 from "../../data/images/customLancha230/visao1EstofadoTerracotta.png";
import estofadoBranco2 from "../../data/images/customLancha230/visao1EstofadoBranco.png";
import estofadoAzul2 from "../../data/images/customLancha230/visao1EstofadoAzul.png";
import estofadoCinza2 from "../../data/images/customLancha230/visao1EstofadoCinza.png";
import estofadoCaramelo2 from "../../data/images/customLancha230/visao1EstofadoCaramelo.png";
import estofadoBegeClaro2 from "../../data/images/customLancha230/visao1EstofadoBege.png";

import pisoBege2 from "../../data/images/customLancha230/visao1PisoBege.png";
import pisoCinza2 from "../../data/images/customLancha230/visao1PisoCinza.png";
import pisoCaramelo2 from "../../data/images/customLancha230/visao1PisoCaramelo.png";
import pisoTerracotta2 from "../../data/images/customLancha230/visao1PisoTerracotta.png";

import pisoTerracottaHW2 from "../../data/images/customLancha230/visao1PisoTerracottaHW.png";
import pisoAreiaHW2 from "../../data/images/customLancha230/visao1PisoAreiaHW.png";
import pisoCinzaHW2 from "../../data/images/customLancha230/visao1PisoCinzaHW.png";
import pisoCarameloHW2 from "../../data/images/customLancha230/visao1PisoCarameloHW.png";

//visao 2 interior
import visao2Estofado from "../../data/images/customLancha230/visao2Estofado.png";
import pisoBege3 from "../../data/images/customLancha230/pisoBege3.png";
import pisoCinza3 from "../../data/images/customLancha230/pisoCinza3.png";
import pisoCaramelo3 from "../../data/images/customLancha230/pisoCaramelo3.png";
import pisoTerracotta3 from "../../data/images/customLancha230/pisoTerracotta3.png";
import pisoTerracottaHW3 from "../../data/images/customLancha230/pisoTerracottaHW3.png";
import pisoAreiaHW3 from "../../data/images/customLancha230/pisoAreiaHW3.png";
import pisoCinzaHW3 from "../../data/images/customLancha230/pisoCinzaHW3.png";
import pisoCarameloHW3 from "../../data/images/customLancha230/pisoCaramelo3.png";

import estofadoTerracotta3 from "../../data/images/customLancha230/estofadoTerracotta3.png";
import estofadoBranco3 from "../../data/images/customLancha230/estofadoBranco3.png";
import estofadoAzul3 from "../../data/images/customLancha230/estofadoAzul3.png";
import estofadoCinza3 from "../../data/images/customLancha230/estofadoCinza3.png";
import estofadoCaramelo3 from "../../data/images/customLancha230/estofadoCaramelo3.png";
import estofadoBege3 from "../../data/images/customLancha230/estofadoBege3.png";

// visao 4 interior
import visao4Estofado from "../../data/images/customLancha230/visao4Estofado.png";
import pisoBege5 from "../../data/images/customLancha230/pisoBege5.png";
import pisoCinza5 from "../../data/images/customLancha230/pisoCinza5.png";
import pisoCaramelo5 from "../../data/images/customLancha230/pisoCarameloHW5.png";
import pisoTerracotta5 from "../../data/images/customLancha230/pisoTerracotta5.png";
import pisoTerracottaHW5 from "../../data/images/customLancha230/pisoTerracottaHW5.png";
import pisoAreiaHW5 from "../../data/images/customLancha230/pisoAreiaHW5.png";
import pisoCinzaHW5 from "../../data/images/customLancha230/pisoCinzaHW5.png";
import pisoCarameloHW5 from "../../data/images/customLancha230/pisoCarameloHW5.png";

//visao 3 interior
import visao3Estofado from "../../data/images/customLancha230/visao3Estofado.png";
import estofadoTerracotta4 from "../../data/images/customLancha230/estofadoTerracotta4.png";
import estofadoBranco4 from "../../data/images/customLancha230/estofadoBranco4.png";
import estofadoAzul4 from "../../data/images/customLancha230/estofadoAzul4.png";
import estofadoCinza4 from "../../data/images/customLancha230/estofadoCinza4.png";
import estofadoCaramelo4 from "../../data/images/customLancha230/estofadoCaramelo4.png";
import estofadoBege4 from "../../data/images/customLancha230/estofadoBege4.png";

import pisoBege4 from "../../data/images/customLancha230/pisoBege4.png";
import pisoCinza4 from "../../data/images/customLancha230/pisoCinzaHW4.png";
import pisoCaramelo4 from "../../data/images/customLancha230/pisoCaramelo4.png";
import pisoTerracotta4 from "../../data/images/customLancha230/pisoTerracotta4.png";
import pisoTerracottaHW4 from "../../data/images/customLancha230/pisoTerracottaHW4.png";
import pisoAreiaHW4 from "../../data/images/customLancha230/pisoAreiaHW4.png";
import pisoCinzaHW4 from "../../data/images/customLancha230/pisoCinzaHW4.png";
import pisoCarameloHW4 from "../../data/images/customLancha230/pisoCarameloHW4.png";

// CASCO E CORES

import fotoCasco from "./../../data/images/customLancha230/fotoBasePNG.png";
import visao1 from "./../../data/images/customLancha230/visao1.png";
import visao2 from "./../../data/images/customLancha230/visao2.png";

// cores default
import colorRed from "../../data/images/customLancha230/cascoVermelho.png";
import colorDarkGray from "../../data/images/customLancha230/cascoCinzaEscuro.png";
import colorGray from "../../data/images/customLancha230/cascoCinza.png";
import colorGreen from "../../data/images/customLancha230/cascoVerde.png";
import colorBlack from "../../data/images/customLancha230/cascoPreto.png";
import colorBlue from "../../data/images/customLancha230/cascoAzul.png";
import colorWhite from "../../data/images/customLancha230/cascoBranco.png";

//default cor do estofado e piso detalhes
import visaoDefaultEstofadoTerracotta from "../../data/images/customLancha230/visaoDefaultEstofadoTerracotta.png";
import visaoDefaultEstofadoAzul from "../../data/images/customLancha230/visaoDefaultEstofadoAzul.png";
import visaoDefaultEstofadoBranco from "../../data/images/customLancha230/visaoDefaultEstofadoBranco.png";
import visaoDefaultEstofadoCinza from "../../data/images/customLancha230/visaoDefaultEstofadoCinza.png";
import visaoDefaultEstofadoCaramelo from "../../data/images/customLancha230/visaoDefaultEstofadoCaramelo.png";
import visaoDefaultEstofadoBege from "../../data/images/customLancha230/visaoDefaultEstofadoBege.png";

import visaoDefaultPisoTerracotta from "../../data/images/customLancha230/visaoDefaultPisoTerracotta.png";
import visaoDefaultPisoCinza from "../../data/images/customLancha230/visaoDefaultPisoCinza.png";
import visaoDefaultPisoCaramelo from "../../data/images/customLancha230/visaoDefaultPisoCaramelo.png";
import visaoDefaultPisoBege from "../../data/images/customLancha230/visaoDefaultPisoBege.png";

import visaoDefaultPisoTerracottaHW from "../../data/images/customLancha230/visaoDefaultPisoTerracottaHW.png";
import visaoDefaultPisoCinzaHW from "../../data/images/customLancha230/visaoDefaultPisoCinzaHW.png";
import visaoDefaultPisoCarameloHW from "../../data/images/customLancha230/visaoDefaultPisoCarameloHW.png";
import visaoDefaultPisoAreiaHW from "../../data/images/customLancha230/visaoDefaultPisoAreiaHW.png";

import visao1EstofadoTerracotta from "../../data/images/customLancha230/visao1ExteriorEstofadoTerracotta.png";
import visao1EstofadoBranco from "../../data/images/customLancha230/visao1ExteriorEstofadoBranco.png";
import visao1EstofadoCinza from "../../data/images/customLancha230/visao1ExteriorEstofadoCinza.png";
import visao1EstofadoCaramelo from "../../data/images/customLancha230/visao1ExteriorEstofadoCaramelo.png";
import visao1EstofadoBege from "../../data/images/customLancha230/visao1ExteriorEstofadoBege.png";
import visao1EstofadoAzul from "../../data/images/customLancha230/visao1ExteriorEstofadoAzul.png";

import visao2EstofadoTerracotta from "../../data/images/customLancha230/visao2ExteriorEstofadoTerracotta.png";
import visao2EstofadoBranco from "../../data/images/customLancha230/visao2ExteriorEstofadoBranco.png";
import visao2EstofadoCinza from "../../data/images/customLancha230/visao2ExteriorEstofadoCinza.png";
import visao2EstofadoCaramelo from "../../data/images/customLancha230/visao2ExteriorEstofadoCaramelo.png";
import visao2EstofadoBege from "../../data/images/customLancha230/visao2ExteriorEstofadoBege.png";
import visao2EstofadoAzul from "../../data/images/customLancha230/visao2ExteriorEstofadoAzul.png";

import interiorCascoAzul2 from "../../data/images/customLancha230/interiorCascoAzul2.png";
import interiorCascoPreto2 from "../../data/images/customLancha230/interiorCascoPreto2.png";
import interiorCascoBranco2 from "../../data/images/customLancha230/interiorCascoBranco2.png";
import interiorCascoCinza2 from "../../data/images/customLancha230/interiorCascoCinza2.png";
import interiorCascoVermelho2 from "../../data/images/customLancha230/interiorCascoVermelho2.png";
import interiorCascoVerde2 from "../../data/images/customLancha230/interiorCascoVerde2.png";
import interiorCascoCinzaEscuro2 from "../../data/images/customLancha230/interiorCascoCinzaEscuro2.png";

// cores casco visao 1
import visao1Vermelho from "./../../data/images/customLancha230/visao1CascoVermelho.png";
import visao1Azul from "./../../data/images/customLancha230/visao1CascoAzul.png";
import visao1Verde from "./../../data/images/customLancha230/visao1CascoVerde.png";
import visao1CinzaEscuro from "./../../data/images/customLancha230/visao1CascoCinzaEscuro.png";
import visao1Preto from "./../../data/images/customLancha230/visao1CascoPreto.png";
import visao1Cinza from "./../../data/images/customLancha230/visao1CascoCinza.png";

//cores casco visao 2
import visao2Vermelho from "./../../data/images/customLancha230/visao2CascoVermelho.png";
import visao2Azul from "./../../data/images/customLancha230/visao2CascoAzul.png";
import visao2Verde from "./../../data/images/customLancha230/visao2CascoVerde.png";
import visao2CinzaEscuro from "./../../data/images/customLancha230/visao2CascoCinzaEscuro.png";
import visao2Preto from "./../../data/images/customLancha230/visao2CascoPreto.png";
import visao2Cinza from "./../../data/images/customLancha230/visao2CascoCinza.png";

export default () => {
  const { t, i18n } = useTranslation();
  const itensSerie = [
    t("lixeiraAcrilico"),
    t("armarioAcrilico"),
    t("cristaleiraAcrilico"),
    t("bocalCombustivel"),
    t("bocalAguaDoce"),
    t("bancoGiratorio"),
    t("portaCoposInox"),
    t("escadaDePopaInox"),
    t("kitTorneiraPia"),
    t("mesaFibraSuporteInox"),
    t("luzesNavegacao"),
    t("luzProaBicolor"),
    t("luzPopaMastro"),
    t("luzesGabinete"),
    t("sistemaEletrico"),
    t("duchaPopa"),
    t("estofamentoCourvim"),
    t("paiosProa"),
    t("paiosCockPit"),
    t("compartimentoBoiaCircular"),
    t("portaPopaInox"),
    t("sistemaHidraulico"),
    t("vasoSanitario"),
    t("rollerAncora"),
  ]
  const fichaTecnica = [
    t('combustivelTanque') + "⠀192 L",
    t('tanqueAguaDoce') + "⠀60 L",
    t('pesoSemMotor') + "⠀1150 KG",
    t('pessoas') + "⠀8 dia / 2 noite",
    t("camarotes") + "⠀1",
    t("peDireitoCabine") + "⠀1,45 M",
    t("anguloVCopa") + "⠀19°",
    t('motorizacaoCentroRabeta') + `⠀${t('motorizacao200a280')}`,
  ]
  return (
    <>
      <CustomPage
        mestra230
        pisoOptions
        coresCascoDefault={fotoCasco}
        estofadoTerracota={seatTerracota}
        estofadoAzul={seatBlue}
        estofadoBranco={seatWhite}
        estofadoBegeClaro={seatBegeClaro}
        estofadoCaramelo={seatCaramelo}
        estofadoCinza={seatGray}
        estofadoBlue={seatBlue}
        // interiorCascoAzul={interiorCascoAzul}
        // interiorCascoPreto={interiorCascoPreto}
        // interiorCascoCinzaEscuro={interiorCascoCinzaEscuro}
        // interiorCascoBranco={interiorCascoBranco}
        // interiorCascoVermelho={interiorCascoVermelho}
        // interiorCascoVerde={interiorCascoVerde}
        // interiorCascoCinza={interiorCascoCinza}
        // detalhes primeira visao exterior pisos e estofado

        interiorCascoAzul2={interiorCascoAzul2}
        interiorCascoPreto2={interiorCascoPreto2}
        interiorCascoBranco2={interiorCascoBranco2}
        interiorCascoCinza2={interiorCascoCinza2}
        interiorCascoCinzaEscuro2={interiorCascoCinzaEscuro2}
        interiorCascoVermelho2={interiorCascoVermelho2}
        interiorCascoVerde2={interiorCascoVerde2}
        visaoDefaultEstofadoTerracotta={visaoDefaultEstofadoTerracotta}
        visaoDefaultEstofadoAzul={visaoDefaultEstofadoAzul}
        visaoDefaultEstofadoBranco={visaoDefaultEstofadoBranco}
        visaoDefaultEstofadoCinza={visaoDefaultEstofadoCinza}
        visaoDefaultEstofadoCaramelo={visaoDefaultEstofadoCaramelo}
        visaoDefaultEstofadoBege={visaoDefaultEstofadoBege}
        visaoDefaultPisoTerracotta={visaoDefaultPisoTerracotta}
        visaoDefaultPisoCinza={visaoDefaultPisoCinza}
        visaoDefaultPisoCaramelo={visaoDefaultPisoCaramelo}
        visaoDefaultPisoBege={visaoDefaultPisoBege}
        visaoDefaultPisoTerracottaHW={visaoDefaultPisoTerracottaHW}
        visaoDefaultPisoCinzaHW={visaoDefaultPisoCinzaHW}
        visaoDefaultPisoCarameloHW={visaoDefaultPisoCarameloHW}
        visaoDefaultPisoAreiaHW={visaoDefaultPisoAreiaHW}
        visao1EstofadoTerracotta={visao1EstofadoTerracotta}
        visao1EstofadoCinza={visao1EstofadoCinza}
        visao1EstofadoBranco={visao1EstofadoBranco}
        visao1EstofadoAzul={visao1EstofadoAzul}
        visao1EstofadoBege={visao1EstofadoBege}
        visao1EstofadoCaramelo={visao1EstofadoCaramelo}
        visao2EstofadoTerracotta={visao2EstofadoTerracotta}
        visao2EstofadoBranco={visao2EstofadoBranco}
        visao2EstofadoAzul={visao2EstofadoAzul}
        visao2EstofadoCinza={visao2EstofadoCinza}
        visao2EstofadoBege={visao2EstofadoBege}
        visao2EstofadoCaramelo={visao2EstofadoCaramelo}
        // visoes
        visao1={visao1}
        visao2={visao2}
        // VISOES DO ESTOFADO
        coresEstofadoDefault={interior}
        visao1Estofado={visao1Estofado}
        visao2Estofado={visao2Estofado}
        visao4Estofado={visao4Estofado}
        visao3Estofado={visao3Estofado}
        // visao4Estofado={visao4Estofado}
        // VISOES DO PISO
        //visao default
        pisoTerracotta={pisoTerracotta}
        pisoCinza={pisoCinza}
        pisoCaramelo={pisoCaramelo}
        pisoBege={pisoBege}
        pisoTerracottaHW={pisoTerracottaHW}
        pisoAreiaHW={pisoAreiaHW}
        pisoCinzaHW={pisoCinzaHW}
        pisoCarameloHW={pisoCarameloHW}
        // visao 1
        pisoTerracotta2={pisoTerracotta2}
        pisoCinza2={pisoCinza2}
        pisoCaramelo2={pisoCaramelo2}
        pisoBege2={pisoBege2}
        pisoTerracottaHW2={pisoTerracottaHW2}
        pisoAreiaHW2={pisoAreiaHW2}
        pisoCinzaHW2={pisoCinzaHW2}
        pisoCarameloHW2={pisoCarameloHW2}
        //visao 2
        pisoTerracotta3={pisoTerracotta3}
        pisoCinza3={pisoCinza3}
        pisoCaramelo3={pisoCaramelo3}
        pisoBege3={pisoBege3}
        pisoTerracottaHW3={pisoTerracottaHW3}
        pisoAreiaHW3={pisoAreiaHW3}
        pisoCinzaHW3={pisoCinzaHW3}
        pisoCarameloHW3={pisoCarameloHW3}
        estofadoTerracotta3={estofadoTerracotta3}
        estofadoCaramelo3={estofadoCaramelo3}
        estofadoBege3={estofadoBege3}
        estofadoCinza3={estofadoCinza3}
        estofadoBranco3={estofadoBranco3}
        estofadoAzul3={estofadoAzul3}
        //visao 3
        pisoTerracotta4={pisoTerracotta4}
        pisoCinza4={pisoCinza4}
        pisoCaramelo4={pisoCaramelo4}
        pisoBege4={pisoBege4}
        pisoTerracottaHW4={pisoTerracottaHW4}
        pisoAreiaHW4={pisoAreiaHW4}
        pisoCinzaHW4={pisoCinzaHW4}
        pisoCarameloHW4={pisoCarameloHW4}
        //visao 4
        pisoTerracotta5={pisoTerracotta5}
        pisoCinza5={pisoCinza5}
        pisoCaramelo5={pisoCaramelo5}
        pisoBege5={pisoBege5}
        pisoTerracottaHW5={pisoTerracottaHW5}
        pisoAreiaHW5={pisoAreiaHW5}
        pisoCinzaHW5={pisoCinzaHW5}
        pisoCarameloHW5={pisoCarameloHW5}
        estofadoTerracotta4={estofadoTerracotta4}
        estofadoCaramelo4={estofadoCaramelo4}
        estofadoBege4={estofadoBege4}
        estofadoBranco4={estofadoBranco4}
        estofadoAzul4={estofadoAzul4}
        estofadoCinza4={estofadoCinza4}
        //  CORES DO CASCO DEFAULT
        colorBlue={colorBlue}
        colorRed={colorRed}
        colorDarkGray={colorDarkGray}
        colorWhite={colorWhite}
        colorGray={colorGray}
        colorGreen={colorGreen}
        colorBlack={colorBlack}
        // VISOES DA COR VERMELHA
        visaoCoresRed2={visao1Vermelho}
        visaoCoresRed3={visao2Vermelho}
        //VISOES DA COR AZUL
        visaoCoresBlue2={visao1Azul}
        visaoCoresBlue3={visao2Azul}
        //VISOES DA COR VERDE
        visaoCoresGreen2={visao1Verde}
        visaoCoresGreen3={visao2Verde}
        //VISOES DA COR CINZA
        visaoCoresGray2={visao1Cinza}
        visaoCoresGray3={visao2Cinza}
        //VISOES DA COR CINZA ESCURO
        visaoCoresDarkGray2={visao1CinzaEscuro}
        visaoCoresDarkGray3={visao2CinzaEscuro}
        //VISOES DA COR PRETO
        visaoCoresBlack2={visao1Preto}
        visaoCoresBlack3={visao2Preto}
        // VISOES DO ESTOFADO TERRACOTA
        estofadoTerracotta2={estofadoTerracotta2}
        // estofadoTerracotta3={estofadoTerracotta3}
        // estofadoTerracotta4={estofadoTerracotta4}
        // estofadoTerracotta5={estofadoTerracotta5}
        // VISOES DO ESTOFADO BRANCO
        // estofadoBranco2={estofadoBranco2}
        // estofadoBranco3={estofadoBranco3}
        // estofadoBranco4={estofadoBranco4}
        // estofadoBranco5={estofadoBranco5}
        // VISOES DO ESTOFADO AZUL
        estofadoAzul2={estofadoAzul2}
        // estofadoAzul3={estofadoAzul3}
        // estofadoAzul4={estofadoAzul4}
        // estofadoAzul5={estofadoAzul5}
        // VISOES DO ESTOFADO CINZA
        estofadoCinza2={estofadoCinza2}
        // estofadoCinza3={estofadoCinza3}
        // estofadoCinza4={estofadoCinza4}
        // estofadoCinza5={estofadoCinza5}
        // VISOES DO ESTOFADO CARAMELO
        estofadoCaramelo2={estofadoCaramelo2}
        // estofadoCaramelo3={estofadoCaramelo3}
        // estofadoCaramelo4={estofadoCaramelo4}
        // estofadoCaramelo5={estofadoCaramelo5}
        // VISOES DO ESTOFADO BEGE CLARO
        estofadoBegeClaro2={estofadoBegeClaro2}
        // estofadoBegeClaro3={estofadoBegeClaro3}
        // estofadoBegeClaro4={estofadoBegeClaro4}
        estofadoBegeClaro4={estofadoBege4}
        // imgVisaoCasco={imgVisaoCasco}

        title="Mestra 230"
        comprimento="7,00 M"
        boca="2,45 M"
        calado="0,36 M"
        li1={t("anguloVCopa")}
        li2={t("combustivelTanque")}
        li3={t("tanqueAguaDoce")}
        li4={t("pesoSemMotor")}
        li5={t("pessoas")}
        li6={t("camarotes")}
        li7={t("peDireitoCabine")}
        li8={t("motorizacaoCentroRabeta")}
        angulo="19°"
        combustivel="192 L"
        tanqueAguaDoce="60 L"
        peso="1150 KG"
        pessoas="8 dia / 2 noite"
        camarotes="1"
        cabine="1,45 M"
        motorizacao={t("motorizacao200a280")}
        fichaTecnica={fichaTecnica}
        mercruiser250="MerCruiser 4.5L 250 HP Rabeta Alpha One"
        itensSerie={itensSerie}
        itemFabrica1={t("motorizacaoFabricaSubtitle")}
        itemFabrica2={t("sistemaDirecao")}
        itemFabrica3={t("bateria")}
        itemFabrica4={t("bombaPorao")}
        itemFabrica5={t("chaveGeral")}
        itemFabrica6={t("automaticoBombaPorao")}
        itemFabrica7={t("bombaPressurizacao")}
        itemFabrica8={t("volante")}
        itemFabrica9={t("filtros")}
        itemFabrica10={t("relogioMontagem")}
      />
    </>
  );
};
