import React from "react";
import brFlag from "../../data/images/flags/br.svg";
import usFlag from "../../data/images/flags/us.svg";
import IconButton from '@mui/material/IconButton';

//BOTÃO DE ALTERAR LINGUAGEM
const PtButton = ({ language }) => {
  return (
    <IconButton aria-label="delete" onClick={language}>
      <img  style={{ width: "20px", height: "20px" }} src={brFlag} />
  </IconButton>
   
  );
};
const EnButton = ({ language }) => {
  return (
    <IconButton aria-label="delete" onClick={language}>
      <img  style={{ width: "20px", height: "20px" }} src={usFlag} />
  </IconButton>
  );
};

export { PtButton, EnButton };

{/* <button
onClick={language}
style={{ border: "none", background: "transparent" }}
>
<img style={{ width: "20px", height: "20px" }} src={brFlag} />
</button> */}