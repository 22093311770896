/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import CustomPage from "../../components/CustomPage/CustomPage";
// ESTOFADO/PISO
// visao default
import interior from "../../data/images/customLancha222/interior.png";
import seatBlue from "../../data/images/customLancha222/seatBlue.png";
import seatWhite from "../../data/images/customLancha222/seatBranco.png";
import seatTerracota from "../../data/images/customLancha222/seatTerracotta.png";
import seatGray from "../../data/images/customLancha222/seatCinza.png";
import seatCaramelo from "../../data/images/customLancha222/seatCaramelo.png";
import seatBegeClaro from "../../data/images/customLancha222/seatBege.png";

import pisoBege from "../../data/images/customLancha222/pisoBege.png";
import pisoCinza from "../../data/images/customLancha222/pisoCinza.png";
import pisoCaramelo from "../../data/images/customLancha222/pisoCaramelo.png";
import pisoTerracotta from "../../data/images/customLancha222/pisoTerracotta.png";
import pisoTerracottaHW from "../../data/images/customLancha222/pisoTerracottaHW.png";
import pisoAreiaHW from "../../data/images/customLancha222/pisoAreiaHW.png";
import pisoCinzaHW from "../../data/images/customLancha222/pisoCinzaHW.png";
import pisoCarameloHW from "../../data/images/customLancha222/pisoCarameloHW.png";

// visao 1 interior
import visao1Estofado from "../../data/images/customLancha222/visao1Estofado.png";
import estofadoTerracotta2 from "../../data/images/customLancha222/visao1EstofadoTerracotta.png";
import estofadoBranco2 from "../../data/images/customLancha222/visao1EstofadoBranco.png";
import estofadoAzul2 from "../../data/images/customLancha222/visao1EstofadoAzul.png";
import estofadoCinza2 from "../../data/images/customLancha222/visao1EstofadoCinza.png";
import estofadoCaramelo2 from "../../data/images/customLancha222/visao1EstofadoCaramelo.png";
import estofadoBegeClaro2 from "../../data/images/customLancha222/visao1EstofadoBege.png";

import pisoBege2 from "../../data/images/customLancha222/visao1PisoBege.png";
import pisoCinza2 from "../../data/images/customLancha222/visao1PisoCinza.png";
import pisoCaramelo2 from "../../data/images/customLancha222/visao1PisoCaramelo.png";
import pisoTerracotta2 from "../../data/images/customLancha222/visao1PisoTerracotta.png";

import pisoTerracottaHW2 from "../../data/images/customLancha222/visao1PisoTerracottaHW.png";
import pisoAreiaHW2 from "../../data/images/customLancha222/visao1PisoAreiaHW.png";
import pisoCinzaHW2 from "../../data/images/customLancha222/visao1PisoCinzaHW.png";
import pisoCarameloHW2 from "../../data/images/customLancha222/visao1PisoCarameloHW.png";

//visao 2 interior
import visao2Estofado from "../../data/images/customLancha222/visao2Estofado.png";
import pisoBege3 from "../../data/images/customLancha222/pisoBege3.png";
import pisoCinza3 from "../../data/images/customLancha222/pisoCinza3.png";
import pisoCaramelo3 from "../../data/images/customLancha222/pisoCaramelo3.png";
import pisoTerracotta3 from "../../data/images/customLancha222/pisoTerracotta3.png";
import pisoTerracottaHW3 from "../../data/images/customLancha222/pisoTerracottaHW3.png";
import pisoAreiaHW3 from "../../data/images/customLancha222/pisoAreiaHW3.png";
import pisoCinzaHW3 from "../../data/images/customLancha222/pisoCinzaHW3.png";
import pisoCarameloHW3 from "../../data/images/customLancha222/pisoCaramelo3.png";

import estofadoTerracotta3 from "../../data/images/customLancha222/estofadoTerracotta3.png";
import estofadoBranco3 from "../../data/images/customLancha222/estofadoBranco3.png";
import estofadoAzul3 from "../../data/images/customLancha222/estofadoAzul3.png";
import estofadoCinza3 from "../../data/images/customLancha222/estofadoCinza3.png";
import estofadoCaramelo3 from "../../data/images/customLancha222/estofadoCaramelo3.png";
import estofadoBege3 from "../../data/images/customLancha222/estofadoBege3.png";

import interiorCascoAzul3 from "../../data/images/customLancha222/interiorCascoAzul3.png";
import interiorCascoPreto3 from "../../data/images/customLancha222/interiorCascoPreto3.png";
import interiorCascoBranco3 from "../../data/images/customLancha222/interiorCascoBranco3.png";
import interiorCascoCinza3 from "../../data/images/customLancha222/interiorCascoCinza3.png";
import interiorCascoVermelho3 from "../../data/images/customLancha222/interiorCascoVermelho3.png";
import interiorCascoVerde3 from "../../data/images/customLancha222/interiorCascoVerde3.png";
import interiorCascoCinzaEscuro3 from "../../data/images/customLancha222/interiorCascoCinzaEscuro3.png";

//visao 3 interior
import visao3Estofado from "../../data/images/customLancha222/visao3Estofado.png";
import estofadoTerracotta4 from "../../data/images/customLancha222/estofadoTerracotta4.png";
import estofadoBranco4 from "../../data/images/customLancha222/estofadoBranco4.png";
import estofadoAzul4 from "../../data/images/customLancha222/estofadoAzul4.png";
import estofadoCinza4 from "../../data/images/customLancha222/estofadoCinza4.png";
import estofadoCaramelo4 from "../../data/images/customLancha222/estofadoCaramelo4.png";
import estofadoBege4 from "../../data/images/customLancha222/estofadoBege4.png";

import pisoBege4 from "../../data/images/customLancha222/pisoBege4.png";
import pisoCinza4 from "../../data/images/customLancha222/pisoCinzaHW4.png";
import pisoCaramelo4 from "../../data/images/customLancha222/pisoCaramelo4.png";
import pisoTerracotta4 from "../../data/images/customLancha222/pisoTerracotta4.png";
import pisoTerracottaHW4 from "../../data/images/customLancha222/pisoTerracottaHW4.png";
import pisoAreiaHW4 from "../../data/images/customLancha222/pisoAreiaHW4.png";
import pisoCinzaHW4 from "../../data/images/customLancha222/pisoCinzaHW4.png";
import pisoCarameloHW4 from "../../data/images/customLancha222/pisoCarameloHW4.png";

// CASCO E CORES

import fotoCasco from "./../../data/images/customLancha222/fotoBasePNG.png";
import visao1 from "./../../data/images/customLancha222/visao1.png";
import visao2 from "./../../data/images/customLancha222/visao2.png";

// cores default
import colorRed from "../../data/images/customLancha222/cascoVermelho.png";
import colorDarkGray from "../../data/images/customLancha222/cascoCinzaEscuro.png";
import colorGray from "../../data/images/customLancha222/cascoCinza.png";
import colorGreen from "../../data/images/customLancha222/cascoVerde.png";
import colorBlack from "../../data/images/customLancha222/cascoPreto.png";
import colorBlue from "../../data/images/customLancha222/cascoAzul.png";
import colorWhite from "../../data/images/customLancha222/cascoBranco.png";

//default cor do estofado e piso detalhes
import visaoDefaultEstofadoTerracotta from "../../data/images/customLancha222/visaoDefaultEstofadoTerracotta.png";
import visaoDefaultEstofadoAzul from "../../data/images/customLancha222/visaoDefaultEstofadoAzul.png";
import visaoDefaultEstofadoBranco from "../../data/images/customLancha222/visaoDefaultEstofadoBranco.png";
import visaoDefaultEstofadoCinza from "../../data/images/customLancha222/visaoDefaultEstofadoCinza.png";
import visaoDefaultEstofadoCaramelo from "../../data/images/customLancha222/visaoDefaultEstofadoCaramelo.png";
import visaoDefaultEstofadoBege from "../../data/images/customLancha222/visaoDefaultEstofadoBege.png";

import visaoDefaultPisoTerracotta from "../../data/images/customLancha222/visaoDefaultPisoTerracotta.png";
import visaoDefaultPisoCinza from "../../data/images/customLancha222/visaoDefaultPisoCinza.png";
import visaoDefaultPisoCaramelo from "../../data/images/customLancha222/visaoDefaultPisoCaramelo.png";
import visaoDefaultPisoBege from "../../data/images/customLancha222/visaoDefaultPisoBege.png";

import visaoDefaultPisoTerracottaHW from "../../data/images/customLancha222/visaoDefaultPisoTerracottaHW.png";
import visaoDefaultPisoCinzaHW from "../../data/images/customLancha222/visaoDefaultPisoCinzaHW.png";
import visaoDefaultPisoCarameloHW from "../../data/images/customLancha222/visaoDefaultPisoCarameloHW.png";
import visaoDefaultPisoAreiaHW from "../../data/images/customLancha222/visaoDefaultPisoAreiaHW.png";

import visao1EstofadoTerracotta from "../../data/images/customLancha222/visao1ExteriorEstofadoTerracotta.png";
import visao1EstofadoBranco from "../../data/images/customLancha222/visao1ExteriorEstofadoBranco.png";
import visao1EstofadoCinza from "../../data/images/customLancha222/visao1ExteriorEstofadoCinza.png";
import visao1EstofadoCaramelo from "../../data/images/customLancha222/visao1ExteriorEstofadoCaramelo.png";
import visao1EstofadoBege from "../../data/images/customLancha222/visao1ExteriorEstofadoBege.png";
import visao1EstofadoAzul from "../../data/images/customLancha222/visao1ExteriorEstofadoAzul.png";

// cores casco visao 1
import visao1Vermelho from "./../../data/images/customLancha222/visao1CascoVermelho.png";
import visao1Azul from "./../../data/images/customLancha222/visao1CascoAzul.png";
import visao1Verde from "./../../data/images/customLancha222/visao1CascoVerde.png";
import visao1CinzaEscuro from "./../../data/images/customLancha222/visao1CascoCinzaEscuro.png";
import visao1Preto from "./../../data/images/customLancha222/visao1CascoPreto.png";
import visao1Cinza from "./../../data/images/customLancha222/visao1CascoCinza.png";

//cores casco visao 2
import visao2Vermelho from "./../../data/images/customLancha222/visao2CascoVermelho.png";
import visao2Azul from "./../../data/images/customLancha222/visao2CascoAzul.png";
import visao2Verde from "./../../data/images/customLancha222/visao2CascoVerde.png";
import visao2CinzaEscuro from "./../../data/images/customLancha222/visao2CascoCinzaEscuro.png";
import visao2Preto from "./../../data/images/customLancha222/visao2CascoPreto.png";
import visao2Cinza from "./../../data/images/customLancha222/visao2CascoCinza.png";

import { useTranslation } from "react-i18next";

export default () => {
  const { t, i18n } = useTranslation();

  const itensSerie = [
    t("lixeiraAcrilico"),
    t("armarioAcrilico"),
    t("cristaleiraAcrilico"),
    t("bocalCombustivel"),
    t("bocalAguaDoce"),
    t("portaCoposInox"),
    t("escadaDePopaInox"),
    t("kitTorneiraPia"),
    t("mesaFibraSuporteInox"),
    t("luzesNavegacao"),
    t("luzProaBicolor"),
    t("luzPopaMastro"),
    t("luzesGabinete"),
    t("sistemaEletrico"),
    t("duchaPopa"),
    t("estofamentoCourvim"),
    t("paiosProa"),
    t("paiosCockPit"),
    t("compartimentoBoiaCircular"),
  ]
  const fichaTecnica = [
    t('combustivelTanque') + "⠀100 L",
    t('tanqueAguaDoce') + "⠀60 L",
    t('pesoSemMotor') + "⠀900 KG",
    t('passageirosDiurnos') + "⠀1 + 9",
    t('motorizacaoCentroRabeta') + `⠀${t('motorizacao200a220')}`,
  ]
  return (
    <>
      <CustomPage
        mestra222
        pisoOptions
        coresCascoDefault={fotoCasco}
        estofadoTerracota={seatTerracota}
        estofadoAzul={seatBlue}
        estofadoBranco={seatWhite}
        estofadoBegeClaro={seatBegeClaro}
        estofadoCaramelo={seatCaramelo}
        estofadoCinza={seatGray}
        estofadoBlue={seatBlue}
        // interiorCascoAzul={interiorCascoAzul}
        // interiorCascoPreto={interiorCascoPreto}
        // interiorCascoCinzaEscuro={interiorCascoCinzaEscuro}
        // interiorCascoBranco={interiorCascoBranco}
        // interiorCascoVermelho={interiorCascoVermelho}
        // interiorCascoVerde={interiorCascoVerde}
        // interiorCascoCinza={interiorCascoCinza}
        // detalhes primeira visao exterior pisos e estofado

        visaoDefaultEstofadoTerracotta={visaoDefaultEstofadoTerracotta}
        visaoDefaultEstofadoAzul={visaoDefaultEstofadoAzul}
        visaoDefaultEstofadoBranco={visaoDefaultEstofadoBranco}
        visaoDefaultEstofadoCinza={visaoDefaultEstofadoCinza}
        visaoDefaultEstofadoCaramelo={visaoDefaultEstofadoCaramelo}
        visaoDefaultEstofadoBege={visaoDefaultEstofadoBege}
        visaoDefaultPisoTerracotta={visaoDefaultPisoTerracotta}
        visaoDefaultPisoCinza={visaoDefaultPisoCinza}
        visaoDefaultPisoCaramelo={visaoDefaultPisoCaramelo}
        visaoDefaultPisoBege={visaoDefaultPisoBege}
        visaoDefaultPisoTerracottaHW={visaoDefaultPisoTerracottaHW}
        visaoDefaultPisoCinzaHW={visaoDefaultPisoCinzaHW}
        visaoDefaultPisoCarameloHW={visaoDefaultPisoCarameloHW}
        visaoDefaultPisoAreiaHW={visaoDefaultPisoAreiaHW}
        visao1EstofadoTerracotta={visao1EstofadoTerracotta}
        visao1EstofadoCinza={visao1EstofadoCinza}
        visao1EstofadoBranco={visao1EstofadoBranco}
        visao1EstofadoAzul={visao1EstofadoAzul}
        visao1EstofadoBege={visao1EstofadoBege}
        visao1EstofadoCaramelo={visao1EstofadoCaramelo}
        // visao2EstofadoTerracotta={visao2EstofadoTerracotta}
        // visao2EstofadoBranco={visao2EstofadoBranco}
        // visao2EstofadoAzul={visao2EstofadoAzul}
        // visao2EstofadoCinza={visao2EstofadoCinza}
        // visao2EstofadoBranco={visao2EstofadoBranco}
        // visao2EstofadoAzul={visao2EstofadoAzul}
        // visao2EstofadoCinza={visao2EstofadoCinza}
        // visao2EstofadoBege={visao2EstofadoBege}
        // visao2EstofadoCaramelo={visao2EstofadoCaramelo}
        // visao2EstofadoBege={visao2EstofadoBege}
        // visao2EstofadoCaramelo={visao2EstofadoCaramelo}
        // visoes
        visao1={visao1}
        visao2={visao2}
        // VISOES DO ESTOFADO
        coresEstofadoDefault={interior}
        visao1Estofado={visao1Estofado}
        visao2Estofado={visao2Estofado}
        visao3Estofado={visao3Estofado}
        //visao default
        pisoTerracotta={pisoTerracotta}
        pisoCinza={pisoCinza}
        pisoCaramelo={pisoCaramelo}
        pisoBege={pisoBege}
        pisoTerracottaHW={pisoTerracottaHW}
        pisoAreiaHW={pisoAreiaHW}
        pisoCinzaHW={pisoCinzaHW}
        pisoCarameloHW={pisoCarameloHW}
        // visao 1
        pisoTerracotta2={pisoTerracotta2}
        pisoCinza2={pisoCinza2}
        pisoCaramelo2={pisoCaramelo2}
        pisoBege2={pisoBege2}
        pisoTerracottaHW2={pisoTerracottaHW2}
        pisoAreiaHW2={pisoAreiaHW2}
        pisoCinzaHW2={pisoCinzaHW2}
        pisoCarameloHW2={pisoCarameloHW2}
        //visao 2
        pisoTerracotta3={pisoTerracotta3}
        pisoCinza3={pisoCinza3}
        pisoCaramelo3={pisoCaramelo3}
        pisoBege3={pisoBege3}
        pisoTerracottaHW3={pisoTerracottaHW3}
        pisoAreiaHW3={pisoAreiaHW3}
        pisoCinzaHW3={pisoCinzaHW3}
        pisoCarameloHW3={pisoCarameloHW3}
        estofadoTerracotta3={estofadoTerracotta3}
        estofadoCaramelo3={estofadoCaramelo3}
        estofadoBegeClaro3={estofadoBege3}
        estofadoCinza3={estofadoCinza3}
        estofadoBranco3={estofadoBranco3}
        estofadoAzul3={estofadoAzul3}
        interiorCascoAzul3={interiorCascoAzul3}
        interiorCascoVerde3={interiorCascoVerde3}
        interiorCascoCinza3={interiorCascoCinza3}
        interiorCascoCinzaEscuro3={interiorCascoCinzaEscuro3}
        interiorCascoBranco3={interiorCascoBranco3}
        interiorCascoVermelho3={interiorCascoVermelho3}
        interiorCascoPreto3={interiorCascoPreto3}
        //visao 3
        pisoTerracotta4={pisoTerracotta4}
        pisoCinza4={pisoCinza4}
        pisoCaramelo4={pisoCaramelo4}
        pisoBege4={pisoBege4}
        pisoTerracottaHW4={pisoTerracottaHW4}
        pisoAreiaHW4={pisoAreiaHW4}
        pisoCinzaHW4={pisoCinzaHW4}
        pisoCarameloHW4={pisoCarameloHW4}
        estofadoTerracotta4={estofadoTerracotta4}
        estofadoCaramelo4={estofadoCaramelo4}
        estofadoBegeClaro4={estofadoBege4}
        estofadoBranco4={estofadoBranco4}
        estofadoAzul4={estofadoAzul4}
        estofadoCinza4={estofadoCinza4}
        //  CORES DO CASCO DEFAULT
        colorBlue={colorBlue}
        colorRed={colorRed}
        colorDarkGray={colorDarkGray}
        colorWhite={colorWhite}
        colorGray={colorGray}
        colorGreen={colorGreen}
        colorBlack={colorBlack}
        // VISOES DA COR VERMELHA
        visaoCoresRed2={visao1Vermelho}
        visaoCoresRed3={visao2Vermelho}
        //VISOES DA COR AZUL
        visaoCoresBlue2={visao1Azul}
        visaoCoresBlue3={visao2Azul}
        //VISOES DA COR VERDE
        visaoCoresGreen2={visao1Verde}
        visaoCoresGreen3={visao2Verde}
        //VISOES DA COR CINZA
        visaoCoresGray2={visao1Cinza}
        visaoCoresGray3={visao2Cinza}
        //VISOES DA COR CINZA ESCURO
        visaoCoresDarkGray2={visao1CinzaEscuro}
        visaoCoresDarkGray3={visao2CinzaEscuro}
        //VISOES DA COR PRETO
        visaoCoresBlack2={visao1Preto}
        visaoCoresBlack3={visao2Preto}
        // VISOES DO ESTOFADO TERRACOTA
        estofadoTerracotta2={estofadoTerracotta2}
        // estofadoTerracotta3={estofadoTerracotta3}
        // estofadoTerracotta4={estofadoTerracotta4}

        // VISOES DO ESTOFADO BRANCO
        estofadoBranco2={estofadoBranco2}
        // estofadoBranco3={estofadoBranco3}
        // estofadoBranco4={estofadoBranco4}

        // VISOES DO ESTOFADO AZUL
        estofadoAzul2={estofadoAzul2}
        // estofadoAzul3={estofadoAzul3}
        // estofadoAzul4={estofadoAzul4}

        // VISOES DO ESTOFADO CINZA
        estofadoCinza2={estofadoCinza2}
        // estofadoCinza3={estofadoCinza3}
        // estofadoCinza4={estofadoCinza4}

        // VISOES DO ESTOFADO CARAMELO
        estofadoCaramelo2={estofadoCaramelo2}
        // estofadoCaramelo3={estofadoCaramelo3}
        // estofadoCaramelo4={estofadoCaramelo4}

        // VISOES DO ESTOFADO BEGE CLARO
        estofadoBegeClaro2={estofadoBegeClaro2}
        // estofadoBegeClaro3={estofadoBegeClaro3}
        // estofadoBegeClaro4={estofadoBegeClaro4}
        // imgVisaoCasco={imgVisaoCasco}

        title="Mestra 222"
        // subtitle={t('mestra240Subtitle')}
        comprimento="6,80 M"
        boca="2,45 M"
        calado="0,34 M"
        // li1={t("anguloVCopa")}
        li2={t("combustivelTanque")}
        li3={t("tanqueAguaDoce")}
        li4={t("pesoSemMotor")}
        li5={t("pessoas")}
        li8={t("motorizacaoCentroRabeta")}
        combustivel="100 L"
        tanqueAguaDoce="60 L"
        peso="900 KG"
        pessoas="1+9"
        motorizacao={t("motorizacao200a280")}
        // li6={t("camarotes")}
        // li7={t("peDireitoCabine")}
        mercruiser250="MerCruiser 4.5L 250 HP Rabeta Alpha One"
        // angulo="19°"
        fichaTecnica={fichaTecnica}
        itensSerie={itensSerie}
        itemFabrica1={t("motorizacaoFabricaSubtitle")}
        itemFabrica2={t("sistemaDirecao")}
        itemFabrica3={t("bateria")}
        itemFabrica4={t("bombaPorao")}
        itemFabrica5={t("chaveGeral")}
        itemFabrica6={t("automaticoBombaPorao")}
        itemFabrica7={t("bombaPressurizacao")}
        itemFabrica8={t("volante")}
        itemFabrica9={t("filtros")}
        itemFabrica10={t("relogioMontagem")}
      />
    </>
  );
};
