import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import theme from "../theme/Theme";
import {
  Grid,
  Button,
  Typography,
  Divider,
  TextField,
} from "@mui/material";
import "../App.css";
import NewNavbar from "../components/Navbar/Navbar";
import InputMask from "react-input-mask";
import Swal from "sweetalert2";
import "../components/NewModal/newModal.css";
import Newfooter from "../components/Footer/Footer";
import api from "../utils/axiosapi";
//ICONS
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import PrivatLogo from "../data/privatLogo.png";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import FormsTitle from "../components/FormsTitle";


const useStyles = makeStyles({
  root: {
    position: 'relative',
    minHeight: '100vh',
  },
  contentWrap: {
    width: "100%",
    minHeight: "100vh",
    // height: "100vh",
    paddingBottom: '80px',
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "10px",
    //mobile
    [theme.breakpoints.down("sm")]: {
      margin: "20px 0px 80px 0px",
      paddingBottom: '160px',

    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#EAEDED",
      },
    },
  },
  content: {
    width: "50%",
    display: "flex",
    flexWrap: "wrap",
    //mobile
    [theme.breakpoints.down("md")]: {
      width: "80%",

    },
  },
  cardContainer: {
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  containerForm: {
    paddingTop: "2em",
    width: "100%",
    height: "100%",
    display: "flex",
    gap: "2em",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start"
  },
  formControl: {
    width: "50%",
    //mobile
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  informationBox: {
    display: "flex",
    gap: "15px",
    [theme.breakpoints.down("md")]: {
      marginTop: "2em",
    },
  },
  privatLogo: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.down("md")]: {
      justifyContent: "flex-start"
    },
  },
  logoImg: {
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      width: '100px',
      height: "30px"
    },
  }
});


const Contact = () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [nome, setNome] = useState("");
  const [sobrenome, setSobrenome] = useState("");
  const [telefone, setTelefone] = useState("");
  const [comentario, setComentario] = useState("");
  const [email, setEmail] = useState("");
  const handleSubmit = async () => {
    var url = "orc/contato";
    var data = {
      nome: nome,
      sobrenome: sobrenome,
      email: email,
      telefone: telefone,
      comentario: comentario,
    };
    await api.post(url, data).then((retorno) => {
      if (retorno.data.error) {
        Swal.fire({
          icon: "error",
          title: retorno.data.error,
          confirmButtonColor: theme.palette.primary.main,
        });
        return;
      }
      if (retorno.data) {
        Swal.fire({
          title: "Mensagem enviada!",
          text: "Agradecemos o contato, entraremos em contato em breve!",
          icon: "success",
          confirmButtonText: "OK",
          confirmButtonColor: theme.palette.primary.main,
        });
        return;
      }
      window.location.assign("/contact");
      // e.preventDefault();
    });
  };
  return (
    <div className={classes.root}>
      <NewNavbar position="initial" />
      <div className={classes.contentWrap}>
        <div className={classes.content}>
          <FormsTitle title={t("contactPageTitle")} />
          <Divider style={{ width: "60%", color: "#ececec", marginTop: "1em" }} />
          <div className={classes.containerForm}>
            <div style={{ padding: "5px" }}>
              <Typography variant="h6">{t("contactUs")}</Typography>
              <Typography variant="body1" style={{ color: "gray", marginTop: "1em" }}>{t("preencherForm")}</Typography>
            </div>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  required
                  id="outlined-basic"
                  label={t("nome")}
                  onChange={(e) => setNome(e.target.value)}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  required
                  id="outlined-basic"
                  label={t("sobrenome")}
                  onChange={(e) => setSobrenome(e.target.value)}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <InputMask
                  mask="(99) 9999-99999"
                  // value={this.state.phone}
                  disabled={false}
                  maskChar=" "
                  onChange={(e) => setTelefone(e.target.value)}
                >
                  {() => (
                    <TextField
                      fullWidth
                      required
                      id="outlined-basic"
                      label={t("telefone")}
                      variant="outlined"

                    // type="number"
                    />
                  )}
                </InputMask>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  required
                  id="outlined-basic"
                  label="Email"
                  variant="outlined"
                  onChange={(e) => setEmail(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  multiline
                  rows={4}
                  id="outlined-basic"
                  label={t("mensagem")}
                  onChange={(e) => setComentario(e.target.value)}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" style={{ color: "gray" }}>
                {t("detailRequest")}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                padding: "10px",
              }}
            >
              <Button
                style={{ width: "200px" }}
                variant="outlined"
                type="submit"
                onClick={() => handleSubmit()}
              >
                {t("send")}
              </Button>
            </Grid>
            <Grid container className={classes.informationBox}>
              <Grid item xs={12} style={{ display: "flex", gap: "10px" }}>
                <LocationOnIcon />
                <Typography>
                  Av. Vírgilio Franceschi - 905 - Dist. Industrial -
                  Pederneiras - SP - CEP: 17280-000
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ display: "flex", gap: "10px" }}>
                <WhatsAppIcon />
                <Typography>+55 (14) 3252-2918</Typography>
              </Grid>
              <Grid item xs={12} style={{ display: "flex", gap: "10px" }}>
                <LocalPhoneIcon />
                <Typography>+55 (14) 99853-2123</Typography>
              </Grid>
              <Grid item xs={12} style={{ display: "flex", gap: "10px" }}>
                <EmailIcon />
                <Typography>example@gmail.com</Typography>
              </Grid>
            </Grid>
            <div className={classes.privatLogo}>
              <img src={PrivatLogo} alt="Privat Logo" className={classes.logoImg} />
            </div>
          </div>
        </div>
      </div>
      <Newfooter contact />
    </div>
  );
};

export default Contact;
