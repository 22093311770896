/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import "../components/Styles/about.css";
import NavBar from "../components/OldNavbar";
import empresa from "../data/images/empresa.jpg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import NewNavbar from "../components/Navbar/Navbar";
import Newfooter from "../components/Footer/Footer";

export default () => {
  const { t, i18n } = useTranslation();
  const showInMapClicked = () => {
    window.open(
      "https://www.google.com/maps/place/Mestra+Boats/@-22.3323056,-48.7627983,1069m/data=!3m1!1e3!4m14!1m8!3m7!1s0x0:0x0!2zMjLCsDE5JzU2LjMiUyA0OMKwNDUnNDEuMSJX!3b1!7e2!8m2!3d-22.3323184!4d-48.7614203!3m4!1s0x94bf5578da2609ab:0xcd23939e02babd7a!8m2!3d-22.3326358!4d-48.7615203"
    );
  };
  return (
    <div className="AppScrollbar">
      <NewNavbar backgroundColor="white" />
      <div className="aboutContainer">
        <div className="contentContainer">
          <div className="headerContainer">
            <h1>{t("about")}</h1>
            <ul style={{ cursor: "pointer" }}>
              <Link
                style={{ color: "inherit" }}
                to="/contact"
                onClick={() => {
                  window.location.href = "/contact";
                }}
              >
                <li>{t("contact")}</li>{" "}
              </Link>
              <li
                style={{ cursor: "pointer" }}
                onClick={() => {
                  showInMapClicked();
                }}
              >
                Local
              </li>
            </ul>
          </div>
          <div className="innerContent">
            <h2>{t("aboutPageTitle")}</h2>
            <img
              style={{ paddingTop: "20px", height: "auto", width: "100%" }}
              src={empresa}
            />
            <p style={{ paddingTop: "50px" }}>{t("aboutPageDescriptionOne")}</p>
            <p>{t("aboutPageDescriptionTwo")}</p>{" "}
            <p>{t("aboutPageDescriptionThree")}</p>
            <p>{t("aboutPageDescriptionFour")}</p>
            <p>{t("aboutPageDescriptionFive")}</p>
            <p>{t("aboutPageDescriptionSix")}</p>
            <p>{t("aboutPageDescriptionSeven")}</p>
            <p>{t("aboutPageDescriptionEight")}</p>
            <p>{t("aboutPageDescriptionNine")}</p>
            <p>{t("aboutPageDescriptionTen")}</p>
            <p>{t("aboutPageDescriptionEleven")}</p>
            <h2>{t("AboutPageGoalsLabel")}</h2>
            <p>{t("aboutPageDescriptionThirteen")}</p>
            <h2>{t("AboutPageMissionLabel")}</h2>
            <p>{t("aboutPageMission1")}</p>
            <p>{t("aboutPageMission2")}</p>
            <h2>{t("AboutPageVisionLabel")}</h2>
            <p>{t("aboutPageDescriptionFourteen")}</p>
            <h2>{t("AboutPageValueLabel")}</h2>
            <ul>
              <li>{t("aboutPageValueOne")}</li>
              <li>{t("aboutPageValueTwo")}</li>
              <li>{t("aboutPageValueThree")}</li>
              <li>{t("aboutPageValueFour")}</li>
            </ul>
          </div>
        </div>
      </div>
      <Newfooter />
    </div>
  );
};
