import React, { useState, useEffect } from "react";
import { Button, Typography } from "@mui/material";
import { useTransform } from 'framer-motion';
import styled from 'styled-components';
import MediaCard from "./Card";
import YouTubeIcon from '@mui/icons-material/YouTube';

export const Container = styled.div`
  position: relative;
  scroll-snap-align: start;
  margin-top: 16.5vh;
  width: 100%;
  height: 100vh;
  background: rgb(33,36,42);
  background: radial-gradient(circle, rgba(33,36,42,1) 0%, rgba(12,12,12,1) 83%);
  paddingBottom: 2em;
  @media (max-width: 900px) {
    width: 100%;
    height: 100%;
  }
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  color: white;
  align-items: center; 

  @media (max-width: 900px) {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
  
  }
`;


export const Grid = styled.div`
  width: 60%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 3em;
  justify-content: center; /* Adicionado para centralizar o conteúdo horizontalmente */
  align-items: center;

  &:last-child {
    width: 40%;
    height: 100%;
    padding-top: 16.5vh;
    padding-bottom: 5em;
  }

  @media (max-width: 900px) {
    gap: 0;
    width: 100%;
     &:first-child {
       padding-top: 16.5vh;
     }
    &:last-child {
      width: 100%;
      padding-inline: 5em;
      padding-top: 10vh;
      padding-bottom: 0;
    }
  }
}
`
export const Title = styled.div`
  display: flex; 
  flex-direction: column; 
  gap: 3em;
  align-items: center;
  justify-content: center;
  
  @media (max-width: 900px) {
    gap: 0;
    text-align: center;
  }
`;
export const Btn = styled(Button)`
  background: transparent;
  border: 2px solid white;
  color: white;
  height: 50px;
  width: 150px;
  border-radius: 35px;
  cursor: pointer;
 
`;
export const CardsContainer = styled.div`
    overflow: auto; 
    width: 100%;
    height: 100%; 
    display: flex; 
    flex-wrap: wrap; 
    gap: 2em;
    padding-bottom: 10em;
    @media (max-width: 900px) {
      display: flex; 
      flex-wrap: wrap; 
      justify-content: center;
      align-items: center;
    }
`;

const videosUrl = [
  'https://www.youtube.com/watch?v=34yUbolh6wM',
  'https://www.youtube.com/watch?v=axvYk7Oz8I0',

];

const titles = [
  'Teste Mestra 240: o tamanho certo do primeiro barco | NÁUTICA',
  'Teste Mestra 322: ótima lancha cabinada para navegar em família | NÁUTICA'
]

const VideoContainer = () => {

  const [isMobile, setIsMobile] = useState(false);

  const checkIsMobile = () => {
    setIsMobile(window.innerWidth <= 900); // Adjust the breakpoint according to your needs
  };

  useEffect(() => {
    checkIsMobile();
    window.addEventListener('resize', checkIsMobile);

    return () => {
      window.removeEventListener('resize', checkIsMobile);
    };
  }, []);

  return (
    <Container >
      <Content>
        <Grid>
          <Title>
            <YouTubeIcon
              sx={{
                fontSize: "200px",
                "@media (max-width: 900px)": {
                  fontSize: "100px",
                },
              }}
            />
            <Typography variant={isMobile ? "h5" : "h4"} style={{ fontWeight: "bold" }}>
              Conheça nosso canal no Youtube
            </Typography>
            <Btn variant="outlined" sx={{
              color: "white", border: "1px solid white", "@media (max-width: 900px)": {
                marginTop: '1em'
              },
            }}
              onClick={() => {
                window.open("https://www.youtube.com/@mestraboats3896");
              }}
            >
              Visitar canal
            </Btn>
          </Title>
        </Grid>
        <Grid sx={{
          backgroundColor: "pink",
          "@media (max-width: 900px)": {
            marginTop: "5em",
            backgroundColor: "pink"
          },
        }}>
          <CardsContainer>
            {videosUrl.map((item, index) => {
              return (
                <MediaCard link={item} title={titles[index]} />
              )
            })}
          </CardsContainer>
        </Grid>

      </Content>
    </Container>
  );
};

export default VideoContainer;
