import React from "react";
import { makeStyles } from "@material-ui/styles";
import {
    Typography,
    Divider,
} from "@mui/material";
import theme from "../../theme/Theme";

const useStyles = makeStyles({
    rightDiv: {
        display: "flex",
        gap: "10px",
        //mobile
        [theme.breakpoints.down("md")]: {
            display: "none",
        },
    },

    title: {
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        marginTop: "2em",
        //mobile
        [theme.breakpoints.down("md")]: {
            marginTop: "0",
        },
    },
});

const FormsTitle = ({ title }) => {
    const classes = useStyles();

    const showInMapClicked = () => {
        window.open(
            "https://www.google.com/maps/place/Mestra+Boats/@-22.3323056,-48.7627983,1069m/data=!3m1!1e3!4m14!1m8!3m7!1s0x0:0x0!2zMjLCsDE5JzU2LjMiUyA0OMKwNDUnNDEuMSJX!3b1!7e2!8m2!3d-22.3323184!4d-48.7614203!3m4!1s0x94bf5578da2609ab:0xcd23939e02babd7a!8m2!3d-22.3326358!4d-48.7615203"
        );
    };

    return (
        <>
            <div className={classes.title}>
                <div>
                    <Typography variant="h5">{title}</Typography>
                </div>
                <div className={classes.rightDiv}>
                    <div>
                        <Typography
                            style={{ cursor: "pointer" }}
                            variant="body1"
                            onClick={() => {
                                window.location.href = "/";
                            }}
                        >
                            Home
                        </Typography>{" "}
                    </div>
                    <div>
                        <Typography
                            variant="body1"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                showInMapClicked();
                            }}
                        >
                            Local
                        </Typography>{" "}
                    </div>
                </div>
            </div>
        </>)
}

export default FormsTitle;