import React from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/styles";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import theme from "../../theme/Theme";

const useStyles = makeStyles({
  footer: {
    width: "100%",
    height: "fit-content",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    bottom: 0,
    // backgroundColor: (props) => (props.home ? "transparent" : "white"),
    backgroundColor: 'transparent',
    position: (props) => (props.home ? "absolute" : "initial"),
    //mobile
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      paddingTop: (props) => (props.contact ? "80px" : props.modelPage ? "10px" : "10px"),
    },
  },
});

const Footer = (props) => {
  const showInMapClicked = () => {
    window.open(
      "https://www.google.com/maps/place/Mestra+Boats/@-22.3323056,-48.7627983,1069m/data=!3m1!1e3!4m14!1m8!3m7!1s0x0:0x0!2zMjLCsDE5JzU2LjMiUyA0OMKwNDUnNDEuMSJX!3b1!7e2!8m2!3d-22.3323184!4d-48.7614203!3m4!1s0x94bf5578da2609ab:0xcd23939e02babd7a!8m2!3d-22.3326358!4d-48.7615203"
    );
  };
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  return (
    <Box className={classes.footer}>
      <Button
        onClick={() => {
          window.location.href = "/";
        }}
      >
        Mestra © 2022
      </Button>
      <Button
        onClick={() => {
          window.location.href = "/about";
        }}
      >
        {t("about")}
      </Button>
      <Button
        onClick={() => {
          window.location.href = "/contact";
        }}
      >
        {t("contact")}
      </Button>
      <Button
        onClick={(e) => {
          showInMapClicked();
        }}
      >
        {t("local")}
      </Button>
    </Box>
  );
};

export default Footer;
