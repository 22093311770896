
import image1 from "./images/mercury50hp.png"



export const Mercury50hp= [
    {
      image:
        image1,
        description: "DESCRIÇÃO AQUI SOBRE O PRODUTO"
    },
  ];
  