import styled from "styled-components";

export const ButtonColor = styled.button`
width:40px;
height:40px;
border: ${({ border }) =>
border || "none"};
border-radius:50px;
margin:10px;
box-shadow: inset 0 0 0 2px #e1e1e1, 0 0 1px rgba(0, 0, 0, 0);
  background-color: ${({ backgroundColor }) =>
    backgroundColor || "transparent"};
  
`;

export const ButtonPiso = styled.button`

width:40px;
height:40px;
border: ${({ border }) =>
border || "none"};
border-radius: 10px;
box-shadow: inset 0 0 0 2px #e1e1e1, 0 0 1px rgba(0, 0, 0, 0);
margin:10px;
background-image: url(${props => props.img});
background-size: contain;

background-position: center;
`;

export const MotorButton = styled.button`
margin-top: 10px;
background-color: transparent;
border-radius: 28px;
border: ${({ border }) =>
border || "none"};
display: inline-block;
cursor: pointer;
font-weight: bold;
width: 400px;
padding: 14px 76px;
text-decoration: none;
@media screen and (max-width: 994px){
  width: 300px;
  border-radius: 50px;
}

`;