import image1 from './images/VolvoPenta280.jpg'
import image2 from './images/VolvoPenta280_2.jpg'


export const VolvoPenta280 = [
  {
    image:
       image1,
      description: "DESCRIÇÃO AQUI SOBRE O PRODUTO"
  },
  {
    image:
     image2,
      description: "DESCRIÇÃO AQUI SOBRE O PRODUTO"
  },
];
