import React from "react";
import ModelPage from "../../components/ModelPage/ModelPage";
import mestra198Modelo from "../../data/images/desktop/model198_desktop.jpg";
import mestra198ModeloMobile from "../../data/images/mobile/model198_mobile_v01.jpg";
import interior from "../../data/images/customLancha230/visao1Estofado.png";
import imgCarousel1 from "../../data/images/mestra198/carouselImg1.jpg";
import imgCarousel2 from "../../data/images/mestra198/carouselImg2.jpg";
import imgCarousel3 from "../../data/images/mestra198/carouselImg3.jpg";
import imgCarousel4 from "../../data/images/mestra198/carouselImg4.jpg";
import imgCarousel5 from "../../data/images/mestra198/carouselImg5.jpg";
import imgCarousel6 from "../../data/images/mestra198/carouselImg6.jpg";
import imgDetails1 from "../../data/images/details230_240_2.jfif";
import imgDetails2 from "../../data/images/details230_240.jfif";
import model from "../../data/images/customLancha212/fotoBasePNG.png";
const ModelMestra198 = () => {

  const fichaProps = {
    mestra292: false,
    mestra322: false,
    mestra240: false,
    mestra230: false,
    mestra222: false,
    mestra212: false,
    mestra200: false,
    mestra198: true,
    mestra180: false,
    mestra160: false,
  };


  const imagens = [
    imgCarousel1,
    imgCarousel2,
    imgCarousel3,
    imgCarousel4,
    imgCarousel5,
    imgCarousel6
  ]
  return (
    <ModelPage
      lastSectionWhite={true}
      mestra198
      mestra198Text
      fichaProps={fichaProps}
      length="5,80 M"
      width="2,25 M"
      draught="0,30 M"
      mobileImage={mestra198ModeloMobile}
      img={mestra198Modelo}
      imgInterior={interior}
      carouselImages={imagens}
      imgDetails1={imgDetails1}
      imgDetails2={imgDetails2}
      modelName="Mestra 198"
      modelURL="mestra198"
      model={model}
    />
  );
};

export default ModelMestra198;
