import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { Container, Wrapper, Content, Heading, Buttons, ButtonArrow, Spacer, Root } from '../components/Home/Styles'
import NewNavbar from "../components/Navbar/Navbar";
import FooterHome from "../components/Home/FooterHome"
import VideoContainer from "../components/Home/VideoSection/VideoContainer"

const Home = () => {
    const [isMobile, setIsMobile] = useState(false)
    const { t, } = useTranslation();

    const handleResize = () => {
        setIsMobile(window.innerWidth <= 1024);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const [modelURL, setModelURL] = useState([
        "mestra322",
        "mestra292",
        "mestra240",
        "mestra230",
        "mestra200",
        "mestra212",
        "mestra180",
        "mestra160",
        "videoContainer"
    ]);

    const [titles, setTitles] = useState([
        "Mestra 322",
        "Mestra 292",
        "Mestra 240",
        "Mestra 230",
        "Mestra 200",
        "Mestra 212",
        "Mestra 180",
        "Mestra 160",

    ]);

    const { ref, inView, entry } = useInView();



    const conteudo = (item, index) => {
        return (
            item === "videoContainer" ? <div ref={ref} id="videos" ><VideoContainer /> </div> :
                <Content>
                    <Heading style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <h1
                            style={{ fontWeight: 'bold', cursor: 'pointer', width: "fit-content" }}
                            onClick={() => (window.location.href = `/models/${modelURL[index]}`)}>
                            {titles[index]}
                        </h1>
                    </Heading>
                    <ButtonArrow>
                        <Buttons>
                            {item === 'mestra322' || item === 'mestra292' ? (
                                <button
                                    onClick={() => (window.location.href = `/models/${modelURL[index]}`)}
                                >
                                    {t('modelPage')}
                                </button>
                            ) : (
                                <button
                                    onClick={() => (window.location.href = `/lanchas/${modelURL[index]}`)}
                                >
                                    {' '}
                                    {t('customizar')}
                                </button>
                            )}
                            <button
                                className="white"
                                onClick={() => (window.location.href = `/contact`)}
                            >
                                {t('solicitarOrcamento')}
                            </button>
                        </Buttons>
                    </ButtonArrow>
                    <Heading style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <h1
                            style={{ fontWeight: 'bold', cursor: 'pointer', width: "fit-content" }}
                            onClick={() => (window.location.href = `/models/${modelURL[index]}`)}
                        >
                            {titles[index]}
                        </h1>
                    </Heading>
                    <ButtonArrow>
                        <Buttons>
                            {item === 'mestra322' || item === 'mestra292' ? (
                                <button
                                    onClick={() => (window.location.href = `/models/${modelURL[index]}`)}
                                >
                                    {t('modelPage')}
                                </button>
                            ) : (
                                <button
                                    onClick={() => (window.location.href = `/lanchas/${modelURL[index]}`)}
                                >
                                    {' '}
                                    {t('customizar')}
                                </button>
                            )}
                            <button
                                className="white"
                                onClick={() => (window.location.href = `/contact`)}
                            >
                                {t('solicitarOrcamento')}
                            </button>
                        </Buttons>
                    </ButtonArrow>
                </Content>
        )
    }

    return (
        <Root>
            <NewNavbar position="absolute" backgroundColor={inView ? "white" : "transparent"} style={{ position: "absolute" }} />
            <Container>
                <Wrapper>
                    {modelURL.map((item, index) => {
                        return (
                            conteudo(item, index)
                        )
                    })}
                </Wrapper>
            </Container>
            {inView ? <FooterHome /> : null}
        </Root>
    )
}

export default Home;