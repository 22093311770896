import React from "react";
import { useTranslation } from "react-i18next";
import { Checkbox, FormControlLabel } from '@mui/material';
// --------------------------240------------------
// Casco com aplicação de P.U
// Targa em Inox
// Targa em Fibra
// Luz de cortesia LED
// Acabamento Inox (entrada de ar)
//Vaso sanitário manual com conexões e instalação
// Vaso sanitário Elétrico com conexões e instalação
// ------------------------------230-----------------
// Casco com aplicação de P.U
// Targa em Inox
// Targa em Fibra
// Vaso sanitário manual com conexões e instalação
// Vaso sanitário Elétrico com conexões e instalação
// Almofada de Proa
// Luz de cortesia LED
// Acabamento Inox (entrada de ar)
// ------------------------------222-----------------
// Casco com aplicação de P.U
// Targa em Inox
// Targa em Fibra
// Luz de cortesia LED
// Acabamento Inox (entrada de ar)
// ------------------------------212-----------------
// Casco com aplicação de P.U
// Targa em Inox
// Targa em Fibra
// Cristaleira em acrílico preto
// Conjunto de mesa de centro
// Plataforma de Popa
// Acabamento Inox (entrada de ar)
// ------------------------------200-----------------
// Casco com aplicação de P.U
// Targa em Inox
// Cristaleira em acrílico preto
// Conjunto de mesa de centro
// Luz de cortesia LED
// Fechamento estofamento Proa (encosto e assento)
// Acabamento Inox (entrada de ar)
// ------------------------------198-----------------
// Casco com aplicação de P.U
// Armário em acrílico preto
// Cristaleira em acrílico preto
// Mastro de SKI
// Compartimento de boia
// Conjunto de mesa de centro
// Escada 3 degraus
// Portinhola de popa em inox
// Lixeira em acrílico preto
// Luz de cortesia LED
// ------------------------------180-----------------
// Casco com aplicação de P.U
// Armário em acrílico preto
// Cristaleira em acrílico preto
// Compartimento de boia
// Mastro de SKI
// Conjunto de mesa de centro
// Lixeira em acrílico preto
// Luz de cortesia LED
// ------------------------------160-----------------
// Casco com aplicação de P.U
// Mastro de SKI
// Lixeira em acrílico preto
// Luz de cortesia LED
// Escada de Proa

// || props.mestra230 || props.mestra222 || props.mestra212 || props.mestra200 ?

const CheckAcessoriosFabrica = (props) => {
  const { t, i18n } = useTranslation();

  const itens = [
    "Casco com aplicação de P.U",
    props.mestra240 || props.mestra230 || props.mestra222 || props.mestra212 || props.mestra200 ? "Targa em Inox" : null,
    props.mestra240 || props.mestra230 || props.mestra222 || props.mestra212 ? "Targa em Fibra" : null,
    props.mestra240 || props.mestra230 || props.mestra222 || props.mestra200 || props.mestra198 || props.mestra180 || props.mestra160 ? "Luz de cortesia LED" : null,
    props.mestra240 || props.mestra230 || props.mestra222 || props.mestra200 ? "Acabamento Inox (entrada de ar)" : null,
    props.mestra230 || props.mestra240 ? "Vaso sanitário manual com conexões e instalação" : null,
    props.mestra230 || props.mestra240 ? "Vaso sanitário elétrico com conexões e instalação" : null,
    props.mestra230 ? "Almofada de Proa" : null,
    props.mestra212 || props.mestra200 || props.mestra198 || props.mestra180 ? "Cristaleira em acrílico preto" : null,
    props.mestra212 || props.mestra200 || props.mestra198 || props.mestra180 ? "Conjunto de mesa de centro" : null,
    props.mestra212 ? "Plataforma de Popa" : null,
    props.mestra200 ? "Fechamento estofamento Proa (encosto e assento)" : null,
    props.mestra198 || props.mestra180 ? "Armário em acrílico preto" : null,
    props.mestra198 || props.mestra180 || props.mestra160 ? "Mastro de SKI" : null,
    props.mestra198 || props.mestra180 || props.mestra160 ? " Lixeira em acrílico preto" : null,
    props.mestra198 || props.mestra180 ? "Compartimento de boia" : null,
  ];

  const Valida = (e) => {
    var array = props.opicionais;
    //Se for true adiciona no array
    if (e.target.checked) array.push(e.target.value);
    else {
      //Se for false, remove do array
      const index = array.indexOf(e.target.value);
      if (index > -1) {
        array.splice(index, 1); // 2nd parameter means remove one item only
      }
    }
    props.setOpicionais(array);
  };
  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          marginTop: "50px",
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "Column",
          alignItems: "flex-start",
          marginBottom: "1.5em"
        }}
      >
        <h4>{t("acessorios")}</h4>
      </div>
      {itens.filter(Boolean).map((valor) => {
        return (
          <div key={valor}>
            <FormControlLabel
              value={valor}
              control={<Checkbox />}
              label={valor}
              labelPlacement="end"
              placeholder={valor}
              onChange={(e) => {
                Valida(e);
              }}
            />
            {/* <input
              type="checkbox"
              placeholder={valor}
              onChange={(e) => {
                Valida(e);
              }}
            />
            <label>⠀{valor}</label> */}
            <br />
          </div>
        );
      })}
    </div>
  );
};

export default CheckAcessoriosFabrica;
