import { FaBars } from "react-icons/fa";
import { NavLink as Link } from "react-router-dom";
import styled from "styled-components";

export const Nav = styled.nav`
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
  position: ${({position}) =>
  position || "fixed"};
  width: 100%;
  padding: 30px;
  background-color: ${({ backgroundColor }) =>
    backgroundColor || "transparent"};
    
`;

export const NavLink = styled(Link)`
  color: black;
  display: flex;
  justify-content: center;
  font-weight: bold;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 45%;
  cursor: pointer;
  font-size: 14px;
  &.active {
    color: black;
    font-weight: bold;
  }
  &:hover {
    color: black;
    height: 20px;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 14px;
    -webkit-transition: background-color 1000ms linear;
    -ms-transition: background-color 1000ms linear;
    transition: background-color 1000ms left;
    transition-timing-function: ease-out;
    background-position: 0 -100%;
    @media screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
      padding: 0;
      height: 20px;
    }
`;
export const Bars = styled(FaBars)`
  display: none;
  color: black;
 
  @media screen and (max-width: 1024px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 75%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`;

export const NavMenuMobile = styled.div`
  display: none;
  color: black;
  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-100%, 75%);
    font-size: 1.8rem;
    height: 100vh;
    width: 500px;
    background-color: white;
  }
`;

export const NavMenu = styled.div`
   display: flex;
    justify-content: center;
    align-items: center;
  /* Third Nav */
  /* width: 100vw;
  white-space: nowrap; */
  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

export const NavRight = styled.nav`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /* Third Nav */
  /* justify-content: flex-end;
  width: 100vw; */
  @media screen and (max-width: 1024px) {
    display: none;
  }
`;
export const NavLogo = styled.nav`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  @media screen and (max-width: 994px) {
    padding: 0rem;
  }
  padding: 0.5rem;
`;
