import React from "react";
import ModelPage from "../../components/ModelPage/ModelPage";
import mestra180ModeloMobile from "../../data/images/mobile/model180_mobile_v01.jpg";
import mestra180Modelo from "../../data/images/desktop/model180_desktop.jpg";
import interior from "../../data/images/customLancha180/visao1Estofado.png";
import imgCarousel1 from "../../data/images/mestra180/carouselImg1.jpg";
import imgCarousel2 from "../../data/images/mestra180/carouselImg2.jpg";
import imgCarousel3 from "../../data/images/mestra180/carouselImg3.jpg";
import imgCarousel4 from "../../data/images/mestra180/carouselImg4.jpg";
import imgCarousel5 from "../../data/images/mestra180/carouselImg5.jpg";
import imgCarousel6 from "../../data/images/mestra180/carouselImg6.jpg";
import imgDetails1 from "../../data/images/details230_240_2.jfif";
import imgDetails2 from "../../data/images/details230_240.jfif";
import model from "../../data/images/customLancha180/fotoBasePNG.png";

const ModelMestra180 = () => {
  const fichaProps = {
    mestra292: false,
    mestra322: false,
    mestra240: false,
    mestra230: false,
    mestra222: false,
    mestra212: false,
    mestra200: false,
    mestra198: false,
    mestra180: true,
    mestra160: false,
  };

  const imagens = [
    imgCarousel1,
    imgCarousel2,
    imgCarousel3,
    imgCarousel4,
    imgCarousel5,
    imgCarousel6
  ]
  return (
    <ModelPage
      lastSectionWhite={true}
      mestra180
      mestra180Text
      mestra240
      fichaProps={fichaProps}
      length="5,15 M"
      width="2,20 M"
      draught="0,30 M"
      img={mestra180Modelo}
      mobileImage={mestra180ModeloMobile}
      imgInterior={interior}
      carouselImages={imagens}
      imgDetails1={imgDetails1}
      imgDetails2={imgDetails2}
      modelName="Mestra 180"
      modelURL="mestra180"
      model={model}
    />
  );
};

export default ModelMestra180;
