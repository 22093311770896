import React, { useState } from "react";

import { makeStyles } from "@material-ui/styles";
import {
  Grid,
  Button,
  Typography,
  Divider,
  Container,
  Card,
  Box,
  CardContent,
  TextField,
  Modal,
  typographyClasses,
} from "@mui/material";
import InputMask from "react-input-mask";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import theme from "../../theme/Theme";
import api from "../../utils/axiosapi";

const useStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: "10px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "fit-content",
    maxHeight: "fit-content",
    backgroundColor: "white",
    border: "2px solid white",
    boxShadow: "10px",
    padding: "50px",
    borderRadius: "15px",
    //mobile
    [theme.breakpoints.down("md")]: {
      padding: "10px 50px 10px 50px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        // borderColor: "black",
        border: "1px solid gray",
      },
    },
  },
  btnClose: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-start",
  },
});

const ModalButton = (props) => {

  const isMobile = /iPhone|iPad|iPod|Android|webOS|BlackBerry|Windows Phone/i.test(navigator.userAgent);


  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const [nome, setNome] = useState("");
  const [sobrenome, setSobrenome] = useState("");
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const [comentario, setComentario] = useState("");
  const [errorLabel, setErrorLabel] = useState(false);
  const [labelText, setTextLabel] = useState('');
  const emailRegex = new RegExp(/^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/, "gm");
  const telefoneRegex = new RegExp(/^\(\d{2}\)\d{4,5}-\d{4}$/, "gm");


  const handleSubmit = async () => {

    const isValidEmail = emailRegex.test(email);
    const isValidTelefone = telefoneRegex.test(telefone);

    if (nome === "" || sobrenome === "" || telefone === "" || email === "") {
      setErrorLabel(true)
      setTextLabel("Por favor preencha todos os campos obrigatórios.")
    } else if (isValidEmail === false) {
      setErrorLabel(true)
      setTextLabel("Por favor use um e-mail válido.")
    } else if (isValidTelefone === false) {
      setErrorLabel(true)
      setTextLabel("Por favor use um número de telefone válido.")
    }
    else {
      setErrorLabel(false);
      var url = "orc/contato";
      var data = {
        nome: nome,
        sobrenome: sobrenome,
        email: email,
        telefone: telefone,
        comentario: comentario,
      };
      await api.post(url, data).then((retorno) => {
        console.log('retorno:', retorno)
        if (retorno.data.error) {
          Swal.fire({
            icon: "error",
            title: retorno.data.error,
            confirmButtonColor: theme.palette.primary.main,
          });
          return;
        }
        if (retorno.data) {
          Swal.fire({
            title: "Mensagem enviada!",
            text: "Agradecemos o contato, entraremos em contato em breve!",
            icon: "success",
            confirmButtonText: "OK",
            confirmButtonColor: theme.palette.primary.main,
          });
          return;
        }
      });
      props.handleClose()
    }

  };


  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        disableAutoFocus={true}
      >
        <div className={classes.root}>
          <div className={classes.btnClose}>
            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={props.handleClose}
            />
          </div>

          <div
            style={{
              display: "flex",
              width: "100%",
              justifyCpntent: "flex-start",
              flexDirection: "column",
              gap: "5px",
            }}
          >
            {" "}
            {props.contact ? (
              <Typography variant={isMobile ? "h5" : "h4"}> {t("contactUs")} </Typography>
            ) : null}
            {props.pedido ? (
              <div
                style={{ display: "flex", flexDirection: "column", gap: "5px" }}
              >
                <Typography variant="h4"> {t("pedidoTitle")} </Typography>{" "}
                <Typography variant="body1"> {t("pedidoSubtitle")} </Typography>{" "}
              </div>
            ) : null}
            <Divider style={{ width: "100%", color: "#ececec" }} />
            <hr style={{ visibility: "hidden" }} />
          </div>

          <Box style={{ width: "100%" }}>
            {/* <form onSubmit={solicitarOrcamento}> */}
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  className={classes.textField}
                  fullWidth
                  // sx={{ WebkitBoxShadow: "0 0 0 100px white inset" }}
                  id="outlined-basic"
                  label={t("nome")}
                  variant="outlined"
                  placeholder="Enter your first name"
                  onChange={(e) => setNome(e.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  // label="Last Name"
                  id="outlined-basic"
                  label={t("sobrenome")}
                  variant="outlined"
                  onChange={(e) => setSobrenome(e.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <InputMask
                  mask="(99)99999-9999"
                  onChange={(e) => setTelefone(e.target.value)}
                  // value={this.state.phone}
                  disabled={false}
                  maskChar=" "
                >
                  {() => (
                    <TextField
                      required
                      fullWidth
                      id="outlined-basic"
                      label={t("telefone")}
                      variant="outlined"
                      InputProps={{
                        className: "input",
                      }}
                    // type="number"
                    />
                  )}
                </InputMask>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Email"
                  variant="outlined"
                  type="email"
                  required
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  multiline
                  rows={4}
                  fullWidth
                  id="outlined-basic"
                  label={t("mensagem")}
                  variant="outlined"
                  onChange={(e) => setComentario(e.target.value)}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {errorLabel ? <p style={{ color: 'red' }}>{labelText}</p> : null}
                <Button
                  style={{ width: "200px" }}
                  variant="outlined"
                  type="submit"
                  onClick={() => { handleSubmit() }}
                >
                  {t("send")}
                </Button>
              </Grid>
            </Grid>
            {/* </form> */}
          </Box>
        </div>
      </Modal>
    </div>
  );
};

export default ModalButton;
