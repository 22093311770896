import React from "react";
import ModelPage from "../../components/ModelPage/ModelPage";
import mestra322Modelo from "../../data/images/MESTRA322/mestra322-modelo.png";
import mestra322ModeloMobile from "../../data/images/mobile/Mobile-Lancha-322-Modelo.png";
import interior from "../../data/images/customLancha200/visao1Estofado.png";

import imgCarousel1 from "../../data/images/MESTRA322/mestra322_1.jpg";
import imgCarousel2 from "../../data/images/MESTRA322/mestra322_2.jpg";
import imgCarousel3 from "../../data/images/MESTRA322/mestra322_3.jpg";
import imgCarousel4 from "../../data/images/MESTRA322/mestra322_4.jpg";
import imgCarousel5 from "../../data/images/MESTRA322/mestra322_5.jpg";
import imgCarousel6 from "../../data/images/MESTRA322/mestra322_6.jpg";
import imgCarousel7 from "../../data/images/MESTRA322/mestra322_7.jpg";
import imgCarousel8 from "../../data/images/MESTRA322/mestra322_8.jpg";
import imgCarousel9 from "../../data/images/MESTRA322/mestra322_9.jpg";
import imgCarousel10 from "../../data/images/MESTRA322/mestra322_10.jpg";
import imgCarousel11 from "../../data/images/MESTRA322/mestra322_11.jpg";
import imgCarousel12 from "../../data/images/MESTRA322/mestra322_12.jpg";
import imgCarousel13 from "../../data/images/MESTRA322/mestra322_13.jpg";
import imgCarousel14 from "../../data/images/MESTRA322/mestra322_14.jpg";

import imgDetails1 from "../../data/images/details230_240_2.jfif";
import imgDetails2 from "../../data/images/details230_240.jfif";

import model from "../../data/images/MESTRA322/mestra322_3.jpg";

const fichaProps = {
  mestra292: false,
  mestra322: true,
  mestra240: false,
  mestra230: false,
  mestra222: false,
  mestra212: false,
  mestra200: false,
  mestra198: false,
  mestra180: false,
  mestra160: false,
};


const ModelMestra200 = () => {
  const imagens = [
    imgCarousel1,
    imgCarousel2,
    imgCarousel3,
    imgCarousel4,
    imgCarousel5,
    imgCarousel6,
    imgCarousel7,
    imgCarousel8,
    imgCarousel9,
    imgCarousel10,
    imgCarousel11,
    // imgCarousel12,
    imgCarousel13,
    imgCarousel14,

  ]

  return (
    <ModelPage
      mestra322
      mestra200Text
      fichaProps={fichaProps}
      length="9,90 M"
      width="3,00 M"
      draught="0,52 M"
      img={mestra322Modelo}
      mobileImage={mestra322ModeloMobile}
      imgInterior={interior}
      carouselImages={imagens}
      imgDetails1={imgDetails1}
      imgDetails2={imgDetails2}
      modelName="Mestra 322"
      modelURL="mestra322"
      model={model}
    />
  );
};

export default ModelMestra200;
