import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import theme from "../theme/Theme";
import {
    Button,
    Typography,
    Divider,
    InputLabel,
    Select,
    MenuItem,
    FormControl,
    CircularProgress
} from "@mui/material";
import "../App.css";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";
import axios from "axios"
import FormsTitle from "../components/FormsTitle";

const useStyles = makeStyles({
    root: {
        position: 'relative',
        minHeight: '100vh',
    },
    contentWrap: {
        width: "100%",
        minHeight: "100vh",
        // height: "100vh",
        paddingBottom: '80px',
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "10px",
        //mobile
        [theme.breakpoints.down("sm")]: {
            margin: "20px 0px 80px 0px",
            paddingBottom: '160px',

        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#EAEDED",
            },
        },
    },
    content: {
        width: "50%",
        display: "flex",
        flexWrap: "wrap",
        //mobile
        [theme.breakpoints.down("md")]: {
            width: "80%",

        },
    },
    cardContainer: {
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
    },
    containerForm: {
        paddingTop: "2em",
        width: "100%",
        height: "100%",
        display: "flex",
        gap: "2em",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start"
    },
    formControl: {
        width: "50%",
        //mobile
        [theme.breakpoints.down("md")]: {
            width: "100%",
        },
    },
    boxEndereco: {
        display: "flex",
        gap: "0.5em",
        marginTop: "0.5em",
        //mobile
        [theme.breakpoints.down("md")]: {
            flexDirection: "column"
        },
    }
});

const Revendedores = () => {
    const classes = useStyles();
    const [estado, setEstado] = useState("")
    const [listaEstados, setListaEstados] = useState([])
    const [showCitiesInput, setShowCitiesInput] = useState(false)
    const [listaCidades, setListaCidades] = useState([])
    const [estadoSigla, setEstadoSigla] = useState("")
    const [cidade, setCidade] = useState("")
    const [error, setError] = useState(false)
    const [errorLabel, setErrorLabel] = useState('')
    const [empresas, setEmpresas] = useState([])
    const [loading, setLoading] = useState(false)


    const getEstados = () => {
        const url = "http://www.geonames.org/childrenJSON?geonameId=3469034"
        axios.get(url).then((res) => {
            res.data.geonames.forEach((estado) => {
                setListaEstados((prev) => [...prev, { name: estado.name, sigla: estado.adminCodes1.ISO3166_2 }])
            })
        })

    }

    useEffect(() => {
        getEstados()
    }, [])

    const handleChange = async (e) => {
        const selectedEstado = e.target.value;
        setEstado(selectedEstado);
        setEstadoSigla(selectedEstado.sigla)
        getCities(selectedEstado.sigla);
    }

    const getCities = (estadoSigla) => {
        const url = `http://104.251.209.171:3000/site/cidades/${estadoSigla}`
        axios.get(url).then((res) => {
            const values = res.data.map(entry => entry.split(":")[1]);
            setListaCidades(values);
            setShowCitiesInput(true);
        });
    }

    const getRevendedores = async () => {
        setLoading(true)
        setEmpresas([])
        const matchingStateEmpresas = [];
        const matchingCityEmpresas = [];
        const otherEmpresas = [];

        function removeAcentos(texto) {
            return texto
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase();
        }

        await axios.get('http://104.251.209.171:3000/orc/listarrevendedores').then((res) => {
            res.data.forEach((item) => {
                var itemCidade = item.dados.enderecoInfo.cidade.trim().toLowerCase();
                var itemEstado = item.dados.enderecoInfo.estado.trim().toLowerCase();
                if (itemCidade === cidade.trim().toLowerCase()) {
                    if (removeAcentos(item.dados.enderecoInfo.cidade.toLowerCase()) === removeAcentos(cidade.toLowerCase())) {
                        if (item.ativo === true) { // Verifica se o item está ativo
                            matchingCityEmpresas.push(item);
                        }
                    }
                } else if (itemEstado === estado.name.trim().toLowerCase() || itemEstado === estado.sigla.trim().toLowerCase()) {
                    if (item.ativo === true) {
                        matchingStateEmpresas.push(item);
                    }
                }
                else {
                    otherEmpresas.push(item);

                }
            })
        })
        if (matchingCityEmpresas.length > 0) {
            setEmpresas(matchingCityEmpresas);
            setError(false);
        } else if (matchingStateEmpresas.length > 0) {
            setEmpresas(matchingStateEmpresas);
            setErrorLabel("Não foram encontrados revendedores na sua cidade. Lista de Revendedores mais próximos:");
            setError(true);
        } else {
            setErrorLabel("Não foram encontrados revendedores para o estado selecionado.");
            setError(true);
        }
        setLoading(false)
    }

    return (
        <div className={classes.root}>
            <Navbar position="initial" />
            <div className={classes.contentWrap}>
                <div className={classes.content}>
                    <FormsTitle title="Revendedores" />
                    <Divider style={{ width: "60%", color: "#ececec", marginTop: "1em" }} />
                    <div className={classes.containerForm}>
                        <div style={{ padding: "5px" }}>
                            <Typography variant="h6">Encontre um revendedor Mestra perto de você</Typography>
                            <Typography variant="body1" style={{ color: "gray", marginTop: "1em" }}>Preencha os campos com o estado e a cidade mais próximos de você</Typography>
                        </div>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="demo-simple-select-label">Estado</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={estado}
                                label="Estado"
                                onChange={(e) => {
                                    handleChange(e)
                                }}
                            >
                                {listaEstados.map((estado, index) => {
                                    return (
                                        <MenuItem key={index} value={estado}>{estado.name}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                        {showCitiesInput ?
                            <FormControl className={classes.formControl}>
                                <InputLabel id="demo-simple-select-label">Cidade:</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={cidade}
                                    label="Cidade"
                                    onChange={(e) => {
                                        setCidade(e.target.value)
                                    }}
                                >
                                    {listaCidades.map((cidade, index) => {
                                        return (
                                            <MenuItem key={index} value={cidade}>{cidade}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                            : null}
                        <Button
                            style={{ width: "200px" }}
                            variant="outlined"
                            type="submit"
                            disabled={!cidade}
                            onClick={() => getRevendedores()}
                        >
                            Buscar
                        </Button>
                        {loading ? <CircularProgress /> : null}
                        {error ? <Typography variant="body1">{errorLabel}</Typography> : null}
                        {empresas.length > 0 ?
                            <>
                                <Divider style={{ width: '100%' }} />
                                <div style={{ display: "flex", flexDirection: "column", gap: '2em', }}>

                                    {empresas.map((item, index) => {
                                        return (
                                            <div key={index}>
                                                <div>
                                                    <Typography variant="h5">{item.razaoSocial}</Typography>
                                                </div>
                                                <div className={classes.boxEndereco}>
                                                    <Typography style={{ fontWeight: "bold" }}>Endereço:</Typography>
                                                    <Typography>{item.dados.enderecoInfo.endereco},</Typography>
                                                    <Typography>{item.dados.enderecoInfo.cidade} - </Typography>
                                                    <Typography>{item.dados.enderecoInfo.estado} - </Typography>
                                                    <Typography>CEP {item.dados.enderecoInfo.cep}</Typography>
                                                </div>
                                                <div className={classes.boxEndereco}>
                                                    <Typography style={{ fontWeight: "bold" }}>Telefones:</Typography>
                                                    {item.dados.telefone.map((telefone, index) => (
                                                        <Typography key={index}>{telefone}</Typography>
                                                    ))}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </>
                            : null}
                    </div>
                </div>
            </div>
            <Footer contact />
        </div>
    );
};

export default Revendedores;
