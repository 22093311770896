import { React, useState, useRef } from "react";
import { Grid, Button, Drawer, Box } from "@mui/material";
import { makeStyles } from "@material-ui/styles";
import CloseIcon from "@mui/icons-material/Close";
import theme from "../../theme/Theme";
import { PtButton, EnButton } from "./LanguageButton";
import { useTranslation } from "react-i18next";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Link } from "react-scroll";


// home, contato, sobre nós, modelos, bandeira
const useStyles = makeStyles({
  drawer: {
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "250px",
    height: "100%",
  },
  closeButton: {
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
});

const SidebarMobile = ({ open, close }) => {
  const classes = useStyles();
  //Botao linguagem
  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const [ptButton, setPtButton] = useState(true);
  const ButtonLanguage = ptButton ? (
    <PtButton language={() => changeLanguage("en")}></PtButton>
  ) : (
    <EnButton language={() => changeLanguage("pt")}></EnButton>
  );

  // menu models
  const [anchorEl, setAnchorEl] = useState(null);
  const openModels = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Drawer open={open} onClose={close} anchor="right">
      <Box className={classes.closeButton}>
        <Button color="secondary" onClick={close}>
          <CloseIcon />
        </Button>
      </Box>
      <Box className={classes.drawer}>
        <Button
          color="secondary"
          onClick={() => {
            window.location.href = "/";
          }}
        >
          HOME
        </Button>
        <Button
          color="secondary"
          onClick={() => {
            window.location.href = "/contact";
          }}
        >
          {t("contact")}
        </Button>
        <Button
          color="secondary"
          onClick={() => {
            window.location.href = "/about";
          }}
        >
          {t("about")}
        </Button>
        <Button color="secondary" onClick={handleClick}>
          {t("models")}
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={openModels}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem
            onClick={() => {
              window.location.href = "/models/mestra322";
            }}
          >
            M322
          </MenuItem>
          <MenuItem
            onClick={() => {
              window.location.href = "/models/mestra292";
            }}
          >
            M292
          </MenuItem>
          <MenuItem
            onClick={() => {
              window.location.href = "/models/mestra240";
            }}
          >
            M240
          </MenuItem>
          <MenuItem
            onClick={() => {
              window.location.href = "/models/mestra230";
            }}
          >
            M230
          </MenuItem>
          <MenuItem
            onClick={() => {
              window.location.href = "/models/mestra222";
            }}
          >
            M222
          </MenuItem>
          <MenuItem
            onClick={() => {
              window.location.href = "/models/mestra212";
            }}
          >
            M212
          </MenuItem>
          <MenuItem
            onClick={() => {
              window.location.href = "/models/mestra200";
            }}
          >
            M200
          </MenuItem>
          <MenuItem
            onClick={() => {
              window.location.href = "/models/mestra198";
            }}
          >
            M198
          </MenuItem>
          <MenuItem
            onClick={() => {
              window.location.href = "/models/mestra180";
            }}
          >
            M180
          </MenuItem>
          <MenuItem
            onClick={() => {
              window.location.href = "/models/mestra160";
            }}
          >
            M160
          </MenuItem>
        </Menu>
        <Button color="secondary" onClick={() => {
          window.location.href = "/#videos";
        }}>
          {t("videos")}
        </Button>
        <Button color="secondary" onClick={() => {
          window.location.href = "/revendedores";
        }}>
          Revendedores
        </Button>
        <Button color="secondary" onClick={() => {
          window.location.href = "/sejarevendedor";
        }}>
          Seja um revendedor
        </Button>
        <Button
          onClick={() => {
            setPtButton(!ptButton);
          }}
        >
          {ButtonLanguage}
        </Button>
      </Box>
    </Drawer>
  );
};

export default SidebarMobile;
