
import image1 from "./images/mercury60hp.png"
import image2 from "./images/mercury60hp_2.png"
import image3 from "./images/mercury60hp.jpg"


export const Mercury60hp= [
    {
      image:
        image1,
        description: "DESCRIÇÃO AQUI SOBRE O PRODUTO"
    },
    {
      image:
        image2,
        description: "DESCRIÇÃO AQUI SOBRE O PRODUTO"
    },
    {
      image:
        image3,
        description: "DESCRIÇÃO AQUI SOBRE O PRODUTO"
    },
  ];
  