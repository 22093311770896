/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import { HideOn, Hide } from "react-hide-on-scroll";
import { BiLoader } from "react-icons/bi";
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";
import { motion } from "framer-motion";

const LeftColumn = (props) => {
  return (
    <>
      <HideOn atHeight height={1000}>
        {/* CORES DO CASCO */}
        <motion.div
          animate={{ opacity: 1, y: 0 }}
          initial={{ opacity: 0, y: 20 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.15 }}
        >
          <div className="arrowsCustomPage">
            <RiArrowLeftSLine
              className="left-arrowCustomPage"
              onClick={() => {
                props.visaoCascoIndices(-1);
                // setCascoVisao("block");
                // setCasco("none");
                // console.log(props.visao);
                // console.log(props.visaoEstofadoArray);
              }}
            />
            <RiArrowRightSLine
              className="right-arrowCustomPage"
              onClick={() => {
                // setCascoVisao("block");
                // setCasco("none");
                props.visaoCascoIndices(+1);
                // console.log(visao);
                // console.log(visaoEstofadoArray);
                // console.log(props.colunaEstofado);
                // console.log(props.linhaEstofado);
              }}
            />
          </div>
          <div
            style={{
              height: "100%",
              width: "100%",
              display: props.cascoVisao,
            }}
          >
            {" "}
            {props.loaded ? null : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  width: "100%",
                }}
              >
                <BiLoader
                  className="loadIcon"
                  style={{ width: "50px", height: "50px" }}
                />
              </div>
            )}
            <div className="containerVisao">
              <img
                style={props.loaded ? {} : { display: "none" }}
                onLoad={() => props.setLoaded(true)}
                className="imgCascoDefault"
                src={props.arrayVisao[props.visao]}
              />
              <img
                style={props.loaded ? {} : { display: "none" }}
                onLoad={() => props.setLoaded(true)}
                className="imgCasco"
                src={props.matrizCores[props.linha][props.coluna]}
              />
              <img
                style={props.loaded ? {} : { display: "none" }}
                onLoad={() => props.setLoaded(true)}
                className="imgCasco"
                src={
                  props.matrizExteriorEstofado[props.linhaExteriorEstofado][
                  props.colunaExteriorEstofado
                  ]
                }
              />
              <img
                style={props.loaded ? {} : { display: "none" }}
                onLoad={() => props.setLoaded(true)}
                className="imgCasco"
                src={
                  props.matrizExteriorPiso[props.linhaExteriorPiso][
                  props.colunaExteriorPiso
                  ]
                }
              />
            </div>
          </div>

          {/* CORES DO ESTOFADO*/}
          <div
            style={{
              zIndex: "1",
              // position: "relative",
              height: "100%",
              width: "100%",
              display: props.visaoEstofado,
            }}
          >
            {" "}
            {props.loaded ? null : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  width: "100%",
                }}
              >
                <BiLoader
                  className="loadIcon"
                  style={{ width: "50px", height: "50px" }}
                />
              </div>
            )}
            <div className="containerVisao">
              {/* INTERIOR */}
              <img
                style={props.loaded ? {} : { display: "none" }}
                onLoad={() => props.setLoaded(true)}
                className="imgCascoDefault"
                src={props.arrayEstofadoVisao[props.visaoEstofadoArray]}
              />{" "}
              {/* ESTOFADO */}
              <img
                // className="imgEstofadoCores"
                className="imgCasco"
                style={props.loaded ? {} : { display: "none" }}
                onLoad={() => props.setLoaded(true)}
                src={props.matrizCoresEstofado[props.linhaEstofado][props.colunaEstofado]}
              />
              {/* CASCO */}
              <img
                style={props.loaded ? {} : { display: "none" }}
                onLoad={() => props.setLoaded(true)}
                className="imgCascoCor"
                src={
                  props.matrizInteriorCasco[props.linhaInteriorCasco][
                  props.colunaInteriorCasco
                  ]
                }
              />
            </div>{" "}
          </div>

          {/* CORES DO PISO */}
          <div
            style={{
              zIndex: "2",
              position: "absolute",
              height: "100%",
              width: "100%",
              top: "0",
              left: "0",
              // backgroundColor:"red",
              display: props.visaoEstofado,
            }}
          >
            {" "}
            {props.loaded ? null : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  width: "100%",
                }}
              >
                <BiLoader
                  className="loadIcon"
                  style={{ width: "50px", height: "50px" }}
                />
              </div>
            )}
            <div className="containerVisao">
              <img
                style={props.loaded ? {} : { display: "none" }}
                onLoad={() => props.setLoaded(true)}
                className="imgCasco"
                src={props.matrizCoresPiso[props.linhaPiso][props.colunaPiso]}
              />{" "}
            </div>
          </div>
        </motion.div>
      </HideOn>
      <HideOn inverse atHeight height={1000}>
        <motion.div
          animate={{ opacity: 1, y: 0 }}
          initial={{ opacity: 0, y: 20 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.5 }}
        >
          <div
            style={{
              width: "100%",
              height: "100vh",
              display: "flex",
              justifyContent: "Center",
              alignItems: "center",
            }}
          >
            {props.currentCarousel}
          </div>{" "}
        </motion.div>
      </HideOn>
    </>
  )
}
export default LeftColumn;